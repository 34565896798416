export const ActionTypes = {
  ADD_COMMENT: "add_comment",
  EDIT_LEAD: "edit_lead",
  FEEDBACK_HISTORY: "feedback_history",
  DELETE_LEAD: "delete_lead",

  // FOR SORTING
  SORT_MODIFICATION_DATE: "sort_modification_date",
  SORT_CREATION_DATE: "sort_creation_date",
};
