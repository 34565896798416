import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {
  Home_mail_img,
  Home_call_img,
  Address_Vector,
  Mail_Vector,
  Phone_Vector,
} from "../../assets/images";
import "./ContactUsPage.css";
import { Link } from "react-router-dom";
import validation from "../../components/Validate/validator";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "../../config/axios";
import message from "../../utils/messages";
import { RecaptchaApiKey } from "../../utils/SecretKey";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      subject: "",
      phoneNumber: "",
      message: "",
      captchaValue: null,
      allCategory: [],
      email: "",
      error: {},
      isThankyouPage: false,
    };
  }
  componentDidMount = () => {
    this.getContactUsCategoey();
  };

  getContactUsCategoey = () => {
    axios
      .get("/contactus-category")
      .then((res) => {
        if (res.data.status === "1") {
          let resuserData = res.data.data;
          this.setState({
            allCategory: resuserData,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  isValidateForm = (errors) => {
    let isValid = true;
    for (const [, value] of Object.entries(errors)) {
      if (value.length > 0) {
        isValid = false;
      }
    }
    return isValid;
  };

  onChange = (value) => {
    this.setState({ captchaValue: value });
  };

  handleChange = (e) => {
    e.persist();

    let { error } = this.state;
    let name = e.target.name;
    let value = e.target.value;
    if (name === "phoneNumber") {
      if (value.length >= 10 && value.length <= 15) {
        error[name] = "";
      } else {
        error[name] = "Enter valid phone number";
      }
      this.setState({ error, [name]: value.replace(/\D/g, "") });
      return;
    }

    this.setState({
      [name]: value,
    });

    var valid_obj = {
      value: e.target.value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((err) => {
      error[name] = err;
      this.setState({ error });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isValidateForm(this.state.error)) {
      const formData = {
        title: this.state.title,
        subject: this.state.subject,
        phone: this.state.phoneNumber,
        message: this.state.message,
        email: this.state.email,
      };
      this.setState({ captchaValue: null });
      const hideMessage = message.loading("Please wait ...", 0);
      axios
        .post("/contact-us", formData)
        .then((res) => {
          hideMessage();
          if (res.data.status === "1") {
            this.setState({
              subject: "",
              phoneNumber: "",
              message: "",
              email: "",
              title: "",
              isThankyouPage: true,
            });
            message.success(res.data.message);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          hideMessage();
          message.error("Something went wrong!!");
          console.error(err);
        });
    }
  };

  render() {
    let { error } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.contact_us;
    } else {
      language = {};
    }

    if (this.state.isThankyouPage) return <Redirect to="/thankyou" />;

    return (
      <Container className={this.props.title ? "" : "without-title"}>
        <div
          className={
            this.props.title
              ? "home_cont_inner"
              : "home_cont_inner_without_title"
          }
        >
          {this.props.title ? (
            <div className="conthome_heading">
              <h2>{this.props.title}</h2>
            </div>
          ) : (
            <div className="conthome_without_heading">
              {/* <h2>CONTACT</h2> */}
            </div>
          )}

          <div className="cont_bottom_part">
            <Row>
              <Col lg="6" md="6" sm="12">
                <div className="home_cont_left">
                  <p>{language.contact_us_form_desc}</p>
                  {/* <ContactsItem
                      title="Expert Advice"
                      color="cont_1"
                      icon={Home_cont_img}
                      textColor=""
                      mobile="9065340638"
                    />

                    <ContactsItem
                      title="Service"
                      color="cont_2"
                      icon={Home_cont1_img}
                      textColor="service"
                      mobile="9065340638"
                    />

                    <ContactsItem
                      title="Parts"
                      color="cont_3"
                      icon={Home_cont2_img}
                      textColor="parts"
                      mobile="9065340638"
                    />

                    <ContactsItem
                      title="Buy Sell Rent"
                      color="cont_4"
                      icon={Home_cont3_img}
                      textColor="buy"
                      mobile="8910162952"
                    />
                    <ContactsItem
                      title="Job and Project"
                      color="cont_5"
                      icon={Home_cont4_img}
                      textColor="jp"
                      mobile="9065340638"
                    />

                    <div className="home_cont_inner_inner">
                      <div className="home_cot_heading_part">
                        <div className="cont_left_box cont_2">
                          <img src={contact_whatsapp} />
                        </div>
                        <div className="cont_right_text service">
                          <h3>WhatsApp</h3>
                        </div>
                      </div>
                      <div className="home_cont_mail_part">
                        <p className="home_mail">
                          <a href="https://wa.me/918910162952" target="_blank">
                            (+91) 8910162952
                          </a>
                        </p>
                        <p className="home_mail home_c">
                          <a href="https://wa.me/917439195289" target="_blank">
                            (+91) 7439195289
                          </a>
                        </p>
                      </div>
                    </div> */}
                  <div className="address_mail_field">
                    <div className="address_inner_mail_field">
                      <div className="address_mail_icon color_1212">
                        <img src={Address_Vector} />
                      </div>
                      <div className="address_mail_text">
                        <h3>Address</h3>
                        <p>
                          5th Floor, PS Aviator Building, 507A, Major Arterial
                          Rd (North Extension), Chinar Park, Newtown, Kolkata,
                          West Bengal 700136
                        </p>
                      </div>
                    </div>

                    <div className="address_inner_mail_field">
                      <div className="address_mail_icon color_1213">
                        <img src={Mail_Vector} />
                      </div>
                      <div className="address_mail_text color___2">
                        <h3>Mail</h3>
                        <p>
                          <Link to="mailto:support@heavymachinerycare.com">
                            support@heavymachinerycare.com
                          </Link>
                        </p>
                        <p>
                          <Link to="mailto:sales@heavymachinerycare.com">
                            sales@heavymachinerycare.com
                          </Link>
                        </p>
                      </div>
                    </div>

                    <div className="address_inner_mail_field">
                      <div className="address_mail_icon color_1214">
                        <img src={Phone_Vector} />
                      </div>
                      <div className="address_mail_text color___3">
                        <h3>Phone</h3>
                        <p>
                          <Link to="tel:(+91) 9065340638">
                            (+91) 9065340638
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="6" md="6" sm="12">
                <div className="home_cont_right">
                  <Form className="home_form" onSubmit={this.handleSubmit}>
                    <Form.Group className="mb-4">
                      <Form.Control
                        as="select"
                        className="home_select"
                        name="title"
                        value={this.state.title}
                        onChange={this.handleChange}
                        validaterule={["required"]}
                        validatemsg={["Please select an item in this list."]}
                        required
                      >
                        <option value="">
                          {language.what_are_you_looking_for}
                        </option>
                        {this.state.allCategory.map((items, index) => {
                          return (
                            <option value={items.title} key={index}>
                              {items.title}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <p className="ml-2" style={{ color: "red" }}>
                        {error.title &&
                          language.please_select_an_item_in_this_list}
                      </p>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4">
                      <Col lg="6">
                        <Form.Control
                          placeholder={language.phone_number}
                          type="text"
                          name="phoneNumber"
                          className="home_text_field"
                          value={this.state.phoneNumber}
                          onChange={this.handleChange}
                          required
                          min={0}
                        />
                        <p className="ml-2" style={{ color: "red" }}>
                          {error.phoneNumber &&
                            language.enter_valid_phone_number}
                        </p>
                      </Col>
                      <Col lg="6">
                        <Form.Control
                          placeholder={language.email}
                          value={this.state.email}
                          onChange={this.handleChange}
                          name="email"
                          type="email"
                          className="home_text_field"
                          validaterule={["required", "isEmail"]}
                          validatemsg={[
                            "Email address is required",
                            "Enter a valid email address",
                          ]}
                          required
                        />
                        <p className="ml-2" style={{ color: "red" }}>
                          {error.email && language.enter_valid_email_address}
                        </p>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Control
                        as="textarea"
                        className="home_textarea"
                        placeholder={language.message}
                        validaterule={["required"]}
                        validatemsg={["Message is required"]}
                        value={this.state.message}
                        onChange={this.handleChange}
                        name="message"
                        required
                      />
                      <p className="ml-2" style={{ color: "red" }}>
                        {error.message && language.message_is_required}
                      </p>
                    </Form.Group>
                    <div className="mb-4">
                      <ReCAPTCHA
                        sitekey={RecaptchaApiKey()}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                    <Button
                      type="submit"
                      // disabled={this.state.captchaValue === null}
                      className="submit_form_home commn_button"
                    >
                      {language.submit_btn}
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    );
  }
}

class ContactsItem extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="home_cont_inner_inner">
        <div className="home_cot_heading_part">
          <div className={`cont_left_box ${this.props.color}`}>
            <img src={this.props.icon} />
          </div>
          <div className={`cont_right_text ${this.props.textColor}`}>
            <h3>{this.props.title}</h3>
          </div>
        </div>
        <div className="home_cont_mail_part">
          <p className="home_mail">
            <span>
              <img src={Home_mail_img} />
            </span>
            <a href="mailto:support@heavymachinerycare.com">
              support@heavymachinerycare.com
            </a>
          </p>
          <p className="home_mail home_c">
            <span>
              <img src={Home_call_img} />
            </span>
            <a href={`tel:(+91) ${this.props.mobile}`}>
              (+91) {this.props.mobile}
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ContactPage);
