import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ReactPlayer from "react-player";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  excavator_second_hand_for_sale,
  heavy_machinery_service_centers,
  heavy_machinery_parts_dealers,
  bulldozer_operator_job_hiring,
  jobseeker_icon,
  total_user,
  equipment_on_sell,
  equipment_on_rent,
  serivce_provider,
  parts_dealer,
  video_bg_img,
  play_icon_img,
  features_heading,
  create_and_manage_multiple_profiles,
  buy_industry_best_machines,
  resale_machines_with_heightened_ease,
  rent_machines_for_each_of_your_projects,
  locate_your_machine_parts_within_your_reach,
  manage_your_construction_projects,
  find_jobs_as_per_your_expertise_and_requirements,
  machine_services_individual_and_company_providers,
  construction_equipment_expert,
  road_construction_equipment_rental,
  services_heading,
  our_projects_heading,
  resale_construction_equipment_online,
  heavy_equipment_operator_jobs,
  Eye_Show,
} from "../../assets/images";
import "./HomeNew.css";
import { Link } from "react-router-dom";
import axios from "../../config/axios";
import validation from "../../components/Validate/validator";
import ReCAPTCHA from "react-google-recaptcha";
import message from "../../utils/messages";
import { isDesktop, isIOS, isAndroid } from "react-device-detect";
import MobileApp from "./MobileApp";
import Select from "react-select";
import _ from "lodash";
import { RecaptchaApiKey } from "../../utils/SecretKey";
import AutoCompleteLocation from "../../utils/AutoCompleteLocation";
import CustomPopupFullScreen from "../../utils/CustomPopupFullScreen";
import LoadingOverlay from "react-loading-overlay";
import config from "../../config/apiHeader";
import { MEDIA_BASE_URL, stringReplace } from "../../utils/Helper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Helmet } from "react-helmet";
import Seo from "../../components/seo/Seo";
import { GetLanguageDetails } from "../../app/action/LanguageActions";
import { connect } from "react-redux";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

const mapActionToProps = (dispatch) => {
  return {
    getLanguageContent: (body) => dispatch(GetLanguageDetails(body)),
  };
};

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: "",
      latitude: "",
      longitude: "",
      address: "",
      deviceType: "",
      allEquipments: [],
      topBrand: [],
      topRentedMachine: [],
      topSaleMachine: [],

      footer_faq_data: [],
      seo_data: {},
    };
  }

  componentDidMount() {
    // let { loading, language } = this.props.language;

    // if (!loading && !language) {
    //   this.props.getLanguageContent({});
    // }

    this.setLoading(true);
    this.getMetaData();

    window.scrollTo(0, 0);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let utm_campaign = params.get("utm_campaign");

    if (utm_campaign !== undefined && utm_campaign !== null) {
      this.saveUrlCampaignData();
    }
    this.getAllEquipments();
    this.getDashbordDetails();

    if (this.props.language.error) {
      message.error(this.props.language.error.message);
    }
  }

  setLoading = (isLoading) => {
    this.setState({ isLoading });
    if (isLoading) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!isLoading) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  };

  getMetaData = () => {
    axios
      .get(
        `/web-seo-content?page_key=home&call_from=page&lang_type=${localStorage.getItem(
          "language"
        )}`
      )
      .then((res) => {
        if (res.data.status === "1") {
          let { seo_data, footer_faq_data } = res.data.data;
          this.setState({ seo_data, footer_faq_data });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAllEquipments = () => {
    axios
      .get("/all-machine-type-list")
      .then((res) => {
        if (res.data.status === "1") {
          let allEquipments = [];
          res.data.data.machine_types.map((equipment) => {
            return allEquipments.push({
              value: equipment.machine_type_hash_id,
              label: equipment.machine_type_name,
              equipment,
            });
          });
          this.setState({
            allEquipments,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getDashbordDetails = () => {
    axios
      .get("/dashboard")
      .then((res) => {
        this.setLoading(false);

        if (res.data.status === 1) {
          let { brand, top_rented_equipment, top_sale_equipment } =
            res.data.data;

          this.setState({
            topSaleMachine: top_sale_equipment,
            topRentedMachine: top_rented_equipment,
            topBrand: brand,
          });
        }
      })
      .catch((err) => {
        this.setLoading(false);
        console.error(err);
      });
  };

  saveUrlCampaignData = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let utm_campaign = params.get("utm_campaign");

    if (utm_campaign !== undefined && utm_campaign !== null) {
      let deviceLink = "";

      if (isAndroid) {
        deviceLink =
          "https://play.google.com/store/apps/details?id=com.wgt.hmc";
        await this.setState({
          deviceType: "android",
        });
      } else if (isIOS) {
        deviceLink =
          "https://apps.apple.com/in/app/heavy-machinery-care/id1582959119";
        await this.setState({
          deviceType: "ios",
        });
      } else if (isDesktop) {
        deviceLink = "https://www.heavymachinerycare.com/";
        await this.setState({
          deviceType: "desktop",
        });
      } else {
        deviceLink = "https://www.heavymachinerycare.com/";
        await this.setState({
          deviceType: "others",
        });
      }

      let formData = {
        utm_campaign: utm_campaign,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        address: this.state.address,
        device_type: this.state.deviceType,
      };

      axios
        .post("/campaign-tracking", formData)
        .then((res) => {
          window.location = deviceLink;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  render() {
    let { seo_data } = this.state;
    let { loading, language } = this.props.language;

    let language_content = {};

    if (!loading && language) {
      language_content = language.home;
    }
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          {seo_data && (
            <Helmet>
              <title>{seo_data.meta_title}</title>
              <meta name="description" content={seo_data.meta_description} />
              <meta name="keywords" content={seo_data.meta_key} />
              <link rel="canonical" href={seo_data.canonical_url} />
            </Helmet>
          )}
          <Slider
            allEquipments={this.state.allEquipments}
            language={language_content}
          />
          <StatisticCounter language={language_content} />
          <MachineType
            allEquipments={this.state.allEquipments}
            language={language_content}
          />
          <RentedMachineTop
            topRentedMachine={this.state.topRentedMachine}
            language={language_content}
          />
          <TopBrands
            topBrand={this.state.topBrand}
            language={language_content}
          />
          <SellingTop
            topSaleMachine={this.state.topSaleMachine}
            language={language_content}
          />
          <FeaturesPart language={language_content} />
          <ExpertAdvice language={language_content} />
          <OurServices language={language_content} />
          <OurProjects language={language_content} />
          <OurCareer language={language_content} />
          <OurFinance language={language_content} />
          <MobileApp />
          <Seo {...this.state} />
        </div>
      </LoadingOverlay>
    );
  }
}

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      requestPopup: false,
      partsPopup: false,
      servicePopup: false,
      hireResourcePopup: false,
      isPopupOpen: false,
      captchaValue: null,
      error: {},
      email: "",
      name: "",
      mobile: "",
      user_hash_id: "",

      // EQUIPMENT
      equipment_for: "",
      machine_hash_id: "",
      machine_model_name: "",
      isMachineOther: false,
      equipment_type_hash_id: "",
      brand_hash_id: "",
      isBrandOther: false,
      brand_other: "",

      // PARTS
      parts_name: "",
      equipment_qty: "1",
      delievery_location: "",
      isOpenLocation: false,

      // SERVICES
      service_for: "",
      service_name: [],
      isServiceNameOther: false,
      service_name_other: "",

      // JOB
      skill: [],
      isSkillOther: false,
      skill_other: "",

      comment: "",
      allMachineModels: [],
      allBrands: [],
      allServiceSkills: [],
      allExpertise: [],
    };
  }
  componentDidMount = async () => {
    let userdata = JSON.parse(localStorage.getItem("userdata"));

    if (userdata) {
      await this.setState({
        user_hash_id: userdata.hash_id,
        isLogin: true,
      });
    }
    this.getAllMachineModels();
    this.getAllBrands();
    this.getAllServiceSkills();
    this.getAllExpertise();
  };

  getAllMachineModels = () => {
    axios
      .post("/machine-model-list", { medium: "web" })
      .then((res) => {
        if (res.data.status === "1") {
          let allMachineModels = [];
          res.data.data.machines.map((machine) => {
            return allMachineModels.push({
              value: machine.machine_hash_id,
              label: machine.model_name,
            });
          });
          this.setState({
            allMachineModels,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAllBrands = () => {
    axios
      .get("/brand-list")
      .then((res) => {
        if (res.data.status === "1") {
          let allBrands = [];
          res.data.data.brand.map((brand) => {
            return allBrands.push({
              value: brand.brand_hash_id,
              label: brand.brand_name,
            });
          });
          this.setState({
            allBrands,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAllServiceSkills = () => {
    axios
      .get("/skills")
      .then((res) => {
        if (res.data.status === "1") {
          let allServiceSkills = [];
          res.data.data.skills.map((skill) => {
            return allServiceSkills.push({
              value: skill.id,
              label: skill.name,
            });
          });
          this.setState({
            allServiceSkills,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAllExpertise = () => {
    axios
      .get("/category")
      .then((res) => {
        if (res.data.status === "1") {
          let allExpertise = res.data.data.category;
          this.setState({
            allExpertise,
          });
        }
      })
      .catch((err) => {
        console.error("Something went wrong!", err);
      });
  };

  isValidateForm = () => {
    let isValid = true;
    let { error } = this.state;

    for (const [, value] of Object.entries(error)) {
      if (value.length > 0) {
        isValid = false;
      }
    }

    return isValid;
  };

  sumbitRequest = (formData) => {
    this.popupClose(false);
    const hideMessage = message.loading("Please wait ...", 0);
    axios
      .post("/request-management-save", formData, config)
      .then((res) => {
        hideMessage();
        if (res.data.status === "1") {
          message.success(res.data.message, 2);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        hideMessage();
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  requestNow = (e) => {
    e.preventDefault();
    if (this.isValidateForm()) {
      let {
        user_hash_id,
        email,
        mobile,
        equipment_for,
        machine_hash_id,
        machine_model_name,
        brand_hash_id,
        equipment_type_hash_id,
        comment,
        error,
        brand_other,
        name,
      } = this.state;

      if (!machine_model_name) {
        error.machine_hash_id = "Select Equipment Model name";
        this.setState({ error });
        return;
      }

      let formData = {
        user_hash_id,
        email,
        mobile,
        comment,
        equipment_for,
        equipment_type_hash_id,
        machine_hash_id,
        machine_model_name,
        request_for: "1",
        brand_hash_id,
        brand_other,
        name,
      };
      this.sumbitRequest(formData);
    }
  };

  partsNow = (e) => {
    e.preventDefault();
    if (this.isValidateForm()) {
      let {
        user_hash_id,
        email,
        mobile,
        comment,
        equipment_type_hash_id,
        brand_hash_id,
        equipment_qty,
        delievery_location,
        parts_name,
        machine_hash_id,
        machine_model_name,
        name,
      } = this.state;

      let formData = {
        user_hash_id,
        email,
        mobile,
        comment,
        request_for: "3",
        parts_for: "",
        equipment_type_hash_id,
        brand_hash_id,
        equipment_qty,
        delievery_location,
        parts_name,
        machine_hash_id,
        machine_model_name,
        name,
      };
      this.sumbitRequest(formData);
    }
  };

  serviceNow = (e) => {
    e.preventDefault();
    if (this.isValidateForm()) {
      let {
        user_hash_id,
        email,
        mobile,
        service_for,
        comment,
        service_name,
        error,
        service_name_other,
        equipment_type_hash_id,
        name,
      } = this.state;

      if (service_name.length === 0) {
        error.service_name = "Select Service name";
        this.setState({ error });
        return;
      } else {
        error.service_name = "";
        this.setState({ error });
      }
      _.remove(service_name, (val) => {
        return val === "Other";
      });
      if (service_name_other)
        service_name = _.concat(service_name, service_name_other);
      service_name = service_name.toString();

      let formData = {
        user_hash_id,
        email,
        mobile,
        comment,
        request_for: "2",
        service_for,
        service_name,
        service_name_other,
        equipment_type_hash_id,
        name,
      };
      this.sumbitRequest(formData);
    }
  };

  hireResourceNow = (e) => {
    e.preventDefault();
    if (this.isValidateForm()) {
      let {
        user_hash_id,
        email,
        mobile,
        skill,
        comment,
        error,
        skill_other,
        name,
      } = this.state;

      if (skill.length === 0) {
        error.skill = "Please provide the skill type";
        this.setState({ error });
        return;
      } else {
        error.skill = "";
        this.setState({ error });
      }

      let formData = {
        user_hash_id,
        email,
        mobile,
        comment,
        skill: skill.toString(),
        request_for: "4",
        skill_other,
        name,
      };

      this.sumbitRequest(formData);
    }
  };

  popupClose = (e) => {
    this.resetFields();
  };

  onChange = (value) => {
    this.setState({ captchaValue: value });
  };

  handleChange = (e, skillValue = "") => {
    e.persist();

    let { error } = this.state;
    let name = e.target.name;
    let value = e.target.value;

    if (name === "mobile") {
      if (value.length >= 10 && value.length <= 15) {
        error[name] = "";
      } else {
        error[name] = "Enter valid phone number";
      }
      this.setState({ error, [name]: value.replace(/\D/g, "") });
      return;
    }

    if (name === "skill") {
      if (skillValue === "Others") {
        this.setState({ isSkillOther: !this.state.isSkillOther });
      }
      let allSkill = this.state.skill;
      if (
        _.find(allSkill, (val) => {
          return val === value;
        })
      ) {
        allSkill = _.filter(allSkill, (val) => {
          return val !== value;
        });
      } else {
        allSkill = _.concat(allSkill, value);
      }
      if (allSkill.length === 0) {
        error[name] = "Please provide the skill type";
      } else {
        error[name] = "";
      }
      this.setState({ skill: allSkill, error });
      return;
    }

    this.setState({
      [name]: value,
    });

    var valid_obj = {
      value: e.target.value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((err) => {
      error[name] = err;
      this.setState({ error });
    });
  };

  handleSelect = (name, item) => {
    let { error } = this.state;
    error[name] = "";

    if (name === "service_name") {
      let service_name = [];
      if (
        _.find(item, (val) => {
          return val.label === "Other";
        })
      ) {
        this.setState({ isServiceNameOther: true });
      } else {
        error.service_name_other = "";
        this.setState({ isServiceNameOther: false });
      }

      item.map((val) => {
        // if (val.label !== "Other")
        return service_name.push(val.label);
      });
      this.setState({ service_name, error });
      return;
    }
    this.setState({ [name]: item.value, error });
    // FOR MACHINE MODEL NAME
    if (name === "machine_hash_id") {
      if (item.label === "Other") {
        this.setState({
          isMachineOther: true,
          machine_model_name: "",
          machine_hash_id: "",
        });
      } else {
        error.machine_model_name = "";
        this.setState({
          isMachineOther: false,
          error,
          machine_model_name: item.label,
        });
      }
    }

    // FOR BRAND NAME
    if (name === "brand_hash_id") {
      if (item.label === "Other") {
        this.setState({ isBrandOther: true });
      } else {
        error.brand_other = "";
        this.setState({ isBrandOther: false, error });
      }
    }
  };

  handleGetLocation = (value) => {
    if (value) {
      this.setState({ delievery_location: value.label, isOpenLocation: false });
    }
  };

  resetFields = () => {
    this.setState({
      requestPopup: false,
      partsPopup: false,
      servicePopup: false,
      hireResourcePopup: false,
      isPopupOpen: false,
      captchaValue: null,
      error: {},
      email: "",
      name: "",
      mobile: "",

      // EQUIPMENT
      equipment_for: "",
      machine_hash_id: "",
      machine_model_name: "",
      isMachineOther: false,
      equipment_type_hash_id: "",
      brand_hash_id: "",
      isBrandOther: false,
      brand_other: "",

      // PARTS
      parts_name: "",
      equipment_qty: "1",
      delievery_location: "",
      isOpenLocation: false,

      // SERVICES
      service_for: "",
      service_name: [],
      isServiceNameOther: false,
      service_name_other: "",

      // JOB
      skill: [],
      isSkillOther: false,
      skill_other: "",

      comment: "",
    });
  };

  render() {
    let { isLogin, error, allExpertise } = this.state;
    let { language } = this.props;
    return (
      <div className="banner_slider1">
        <Carousel
          responsive={responsive}
          centerMode={false}
          className="slider__banner"
          infinite="false"
          arrows={false}
          showDots={true}
          autoPlay={
            this.props.deviceType !== "mobile" && !this.state.isPopupOpen
          }
          autoPlaySpeed={15000}
        >
          <div>
            <LazyLoadImage
              className="d-block w-100"
              src={excavator_second_hand_for_sale}
              alt="Excavator Second Hand for Sale"
              effect="blur"
            />
            <div className="tetx_cont">
              <Container fluid>
                <div className="banner_cont">
                  <h1>{language.new_used_equipment}</h1>
                  <p>{language.new_used_equipment_desc}</p>
                  <Button
                    className="submit_form_home commn_button"
                    onClick={() => {
                      this.setState({ requestPopup: true, isPopupOpen: true });
                    }}
                  >
                    {language.request_now_btn}
                  </Button>
                  <CustomPopupFullScreen
                    title={language.request_for_equipment || ""}
                    show={this.state.requestPopup}
                    onClose={this.popupClose}
                  >
                    <div className="form_center">
                      <Form className="home_form" onSubmit={this.requestNow}>
                        {!isLogin && (
                          <>
                            <Form.Group>
                              <Form.Label>
                                <b>
                                  {language.request_for_equipment_label_name}
                                </b>
                                <span className="required">*</span>
                              </Form.Label>
                              <Form.Control
                                placeholder={
                                  language.request_for_equipment_plh_enter_your_name
                                }
                                value={this.state.name}
                                onChange={this.handleChange}
                                name="name"
                                type="text"
                                validaterule={["required"]}
                                validatemsg={["Name is required"]}
                                required={!isLogin}
                              />
                              <p className="invalid_input">
                                {error.name &&
                                  language.request_for_equipment_errormsg_name_required}
                              </p>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                <b>
                                  {language.request_for_equipment_label_mobile}
                                </b>
                                <span className="required">*</span>
                              </Form.Label>
                              <Form.Control
                                placeholder={
                                  language.request_for_equipment_plh_enter_your_mobile
                                }
                                value={this.state.mobile}
                                onChange={this.handleChange}
                                name="mobile"
                                type="text"
                                required={!isLogin}
                              />
                              <p className="invalid_input">
                                {error.mobile &&
                                  language.request_for_equipment_errormsg_valid_phone}
                              </p>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                <b>
                                  {language.request_for_equipment_label_email}
                                </b>
                              </Form.Label>
                              <Form.Control
                                placeholder={
                                  language.request_for_equipment_plh_enter_your_email
                                }
                                value={this.state.email}
                                onChange={this.handleChange}
                                name="email"
                                type="email"
                              />
                              <p className="invalid_input">{error.email}</p>
                            </Form.Group>
                          </>
                        )}

                        <Form.Group>
                          <Form.Label>
                            <b>
                              {
                                language.request_for_equipment_label_specify_request
                              }
                            </b>
                          </Form.Label>
                          <ul className="reuest_listteing">
                            <li>
                              <label>
                                <input
                                  type="radio"
                                  value="2"
                                  name="equipment_for"
                                  onChange={this.handleChange}
                                  required
                                />
                                <span>
                                  {
                                    language.request_for_equipment_label_user_equipment
                                  }
                                </span>
                              </label>
                            </li>

                            <li>
                              <label>
                                <input
                                  type="radio"
                                  value="4"
                                  name="equipment_for"
                                  onChange={this.handleChange}
                                  required
                                />
                                <span>
                                  {
                                    language.request_for_equipment_label_rent_equipment
                                  }
                                </span>
                              </label>
                            </li>

                            <li>
                              <label>
                                <input
                                  type="radio"
                                  value="1"
                                  name="equipment_for"
                                  onChange={this.handleChange}
                                  required
                                />
                                <span>
                                  {
                                    language.request_for_equipment_label_brand_new
                                  }
                                </span>
                              </label>
                            </li>
                          </ul>
                        </Form.Group>

                        <Form.Group>
                          <Select
                            options={this.state.allMachineModels}
                            onChange={(item) => {
                              this.handleSelect("machine_hash_id", item);
                            }}
                            placeholder={
                              language.request_for_equipment_plh_model_name
                            }
                            name="machine_hash_id"
                          />
                          <p className="invalid_input">
                            {error.machine_hash_id}
                          </p>
                        </Form.Group>

                        {this.state.isMachineOther && (
                          <Form.Group>
                            <Form.Control
                              placeholder={
                                language.request_for_equipment_other_type_your_model_name
                              }
                              value={this.state.machine_model_name}
                              onChange={this.handleChange}
                              name="machine_model_name"
                              type="text"
                              validaterule={["required"]}
                              validatemsg={["This field is required"]}
                              required={this.state.isMachineOther}
                            />
                            <p className="invalid_input">
                              {error.machine_model_name &&
                                language.other_this_field_is_required}
                            </p>
                          </Form.Group>
                        )}
                        <Form.Group>
                          <Select
                            options={this.props.allEquipments}
                            onChange={(item) => {
                              this.handleSelect("equipment_type_hash_id", item);
                            }}
                            placeholder={
                              language.request_for_equipment_plh_select_type_equipment
                            }
                            name="equipment_type_hash_id"
                          />
                        </Form.Group>

                        <Form.Group>
                          <Select
                            options={this.state.allBrands}
                            onChange={(item) => {
                              this.handleSelect("brand_hash_id", item);
                            }}
                            placeholder={
                              language.request_for_equipment_plh_select_brand
                            }
                            name="brand_hash_id"
                          />
                        </Form.Group>

                        {this.state.isBrandOther && (
                          <Form.Group>
                            <Form.Control
                              placeholder={
                                language.request_for_equipment_other_type_your_brand_name
                              }
                              value={this.state.brand_other}
                              onChange={this.handleChange}
                              name="brand_other"
                              type="text"
                              validaterule={["required"]}
                              validatemsg={["This field is required"]}
                              required={this.state.isBrandOther}
                            />
                            <p className="invalid_input">
                              {error.brand_other &&
                                language.other_this_field_is_required}
                            </p>
                          </Form.Group>
                        )}

                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            placeholder={
                              language.request_for_equipment_plh_leave_comment_here
                            }
                            style={{ height: "100px" }}
                            name="comment"
                            value={this.state.comment}
                            onChange={this.handleChange}
                          />
                        </Form.Group>

                        <div className="mb-3">
                          <ReCAPTCHA
                            sitekey={RecaptchaApiKey()}
                            onChange={this.onChange}
                            required
                          />
                        </div>
                        <Button
                          type="submit"
                          disabled={this.state.captchaValue === null}
                          className="submit_button commn_button"
                        >
                          {language.request_for_equipment_submit_btn}
                        </Button>
                      </Form>
                    </div>
                  </CustomPopupFullScreen>
                </div>
              </Container>
            </div>
          </div>

          <div>
            <LazyLoadImage
              className="d-block w-100"
              src={heavy_machinery_parts_dealers}
              alt="Heavy Machinery Parts Dealers"
              effect="blur"
            />
            <div className="tetx_cont">
              <Container fluid>
                <div className="banner_cont">
                  <h2>{language.spare_parts}</h2>
                  <p>{language.spare_parts_desc}</p>
                  <Button
                    className="submit_form_home commn_button"
                    onClick={() => {
                      this.setState({ partsPopup: true, isPopupOpen: true });
                    }}
                  >
                    {language.find_spare_parts_now_btn}
                  </Button>
                  <CustomPopupFullScreen
                    title={language.request_parts || ""}
                    show={this.state.partsPopup}
                    onClose={this.popupClose}
                  >
                    <div className="form_center">
                      <Form className="home_form" onSubmit={this.partsNow}>
                        {!isLogin && (
                          <>
                            <Form.Group>
                              <Form.Label>
                                <b>{language.request_parts_label_name}</b>
                                <span className="required">*</span>
                              </Form.Label>
                              <Form.Control
                                placeholder={
                                  language.request_parts_plh_enter_name
                                }
                                value={this.state.name}
                                onChange={this.handleChange}
                                name="name"
                                type="text"
                                validaterule={["required"]}
                                validatemsg={["Name is required"]}
                                required={!isLogin}
                              />
                              <p className="invalid_input">
                                {error.name &&
                                  language.request_parts_errormsg_name_required}
                              </p>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                <b>{language.request_parts_label_mobile}</b>
                                <span className="required">*</span>
                              </Form.Label>
                              <Form.Control
                                placeholder={language.request_parts_plh_mobile}
                                value={this.state.mobile}
                                onChange={this.handleChange}
                                name="mobile"
                                type="text"
                                required={!isLogin}
                              />
                              <p className="invalid_input">
                                {error.mobile &&
                                  language.request_parts_errormsg_valid_phone}
                              </p>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                <b>{language.request_parts_label_email}</b>
                              </Form.Label>
                              <Form.Control
                                placeholder={
                                  language.request_parts_plh_enter_email
                                }
                                value={this.state.email}
                                onChange={this.handleChange}
                                name="email"
                                type="email"
                              />
                              <p className="invalid_input">{error.email}</p>
                            </Form.Group>
                          </>
                        )}

                        <Form.Group>
                          <Form.Label>
                            <b>{language.request_parts_label_parts_name}</b>
                          </Form.Label>
                          <Form.Control
                            placeholder={
                              language.request_parts_plh_enter_parts_name
                            }
                            value={this.state.parts_name}
                            onChange={this.handleChange}
                            name="parts_name"
                            type="text"
                            validaterule={["required"]}
                            validatemsg={["Enter parts name"]}
                            required
                          />
                          <p className="invalid_input">
                            {error.parts_name &&
                              language.request_parts_errormsg_parts_name}
                          </p>
                        </Form.Group>

                        <Form.Group>
                          <Select
                            options={this.state.allMachineModels}
                            onChange={(item) => {
                              this.handleSelect("machine_hash_id", item);
                            }}
                            placeholder={language.request_parts_plh_model_name}
                            name="machine_hash_id"
                          />
                          <p className="invalid_input">
                            {error.machine_hash_id}
                          </p>
                        </Form.Group>

                        {this.state.isMachineOther && (
                          <Form.Group>
                            <Form.Control
                              placeholder={
                                language.request_for_equipment_other_type_your_model_name
                              }
                              value={this.state.machine_model_name}
                              onChange={this.handleChange}
                              name="machine_model_name"
                              type="text"
                              validaterule={["required"]}
                              validatemsg={["This field is required"]}
                              required={this.state.isMachineOther}
                            />
                            <p className="invalid_input">
                              {error.machine_model_name &&
                                language.other_this_field_is_required}
                            </p>
                          </Form.Group>
                        )}

                        <Form.Group>
                          <Form.Label>
                            <b>{language.request_parts_label_quantity}</b>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            className="home_select"
                            name="equipment_qty"
                            value={this.state.equipment_qty}
                            onChange={this.handleChange}
                          >
                            <option defaultValue="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group>
                          <Form.Label>
                            <b>
                              {language.request_parts_label_delivery_location}
                            </b>
                          </Form.Label>

                          <AutoCompleteLocation
                            handleGetLocation={this.handleGetLocation}
                            value={this.state.delievery_location}
                          />
                        </Form.Group>

                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            placeholder={
                              language.request_parts_plh_leave_comment
                            }
                            style={{ height: "100px" }}
                            name="comment"
                            value={this.state.comment}
                            onChange={this.handleChange}
                          />
                        </Form.Group>

                        <div className="mb-4">
                          <ReCAPTCHA
                            sitekey={RecaptchaApiKey()}
                            onChange={this.onChange}
                            required
                          />
                        </div>
                        <Button
                          type="submit"
                          disabled={this.state.captchaValue === null}
                          className="submit_button commn_button"
                        >
                          {language.request_parts_submit_btn}
                        </Button>
                      </Form>
                    </div>
                  </CustomPopupFullScreen>
                </div>
              </Container>
            </div>
          </div>

          <div>
            <LazyLoadImage
              className="d-block w-100"
              src={heavy_machinery_service_centers}
              alt="Heavy Machinery Service Centers"
              effect="blur"
            />
            <div className="tetx_cont">
              <Container fluid>
                <div className="banner_cont">
                  <h2>{language.service_maintenance}</h2>
                  <p>{language.service_maintenance_desc}</p>
                  <Button
                    className="submit_form_home commn_button"
                    onClick={() => {
                      this.setState({ servicePopup: true, isPopupOpen: true });
                    }}
                  >
                    {language.service_provide_near_btn}
                  </Button>
                  <CustomPopupFullScreen
                    title={language.request_service || ""}
                    show={this.state.servicePopup}
                    onClose={this.popupClose}
                  >
                    <div className="form_center">
                      <Form className="home_form" onSubmit={this.serviceNow}>
                        {!isLogin && (
                          <>
                            <Form.Group>
                              <Form.Label>
                                <b>{language.request_service_label_name}</b>
                                <span className="required">*</span>
                              </Form.Label>
                              <Form.Control
                                placeholder={
                                  language.request_service_plh_enter_name
                                }
                                value={this.state.name}
                                onChange={this.handleChange}
                                name="name"
                                type="text"
                                validaterule={["required"]}
                                validatemsg={["Name is required"]}
                                required={!isLogin}
                              />
                              <p className="invalid_input">
                                {error.name &&
                                  language.request_service_errormsg}
                              </p>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                <b>{language.request_service_label_mobile}</b>
                                <span className="required">*</span>
                              </Form.Label>
                              <Form.Control
                                placeholder={
                                  language.request_service_plh_enter_mobile
                                }
                                value={this.state.mobile}
                                onChange={this.handleChange}
                                name="mobile"
                                type="text"
                                required={!isLogin}
                              />
                              <p className="invalid_input">
                                {error.mobile &&
                                  language.request_service_errormsg_valid_phone_number}
                              </p>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                <b>{language.request_service_label_email}</b>
                              </Form.Label>
                              <Form.Control
                                placeholder={
                                  language.request_service_plh_enter_email
                                }
                                value={this.state.email}
                                onChange={this.handleChange}
                                name="email"
                                type="email"
                              />
                              <p className="invalid_input">{error.email}</p>
                            </Form.Group>
                          </>
                        )}

                        <Form.Group className="mb-2">
                          <Form.Label>
                            <b>{language.request_service_label_service_type}</b>
                          </Form.Label>
                          <ul className="reuest_listteing">
                            <li>
                              <label>
                                <input
                                  type="radio"
                                  value="individual"
                                  name="service_for"
                                  onChange={this.handleChange}
                                  required
                                />
                                <span>
                                  {
                                    language.request_service_label_service_person
                                  }
                                </span>
                              </label>
                            </li>

                            <li>
                              <label>
                                <input
                                  type="radio"
                                  value="workshop"
                                  name="service_for"
                                  onChange={this.handleChange}
                                  required
                                />
                                <span>
                                  {language.request_service_label_workshop}
                                </span>
                              </label>
                            </li>
                          </ul>
                        </Form.Group>

                        <Form.Group>
                          <Select
                            options={this.state.allServiceSkills}
                            isMulti
                            onChange={(item) => {
                              this.handleSelect("service_name", item);
                            }}
                            placeholder={
                              language.request_service_plh_service_name
                            }
                            name="service_name"
                            closeMenuOnSelect={false}
                          />

                          <p className="invalid_input">{error.service_name}</p>
                        </Form.Group>

                        {this.state.isServiceNameOther && (
                          <Form.Group>
                            <Form.Control
                              placeholder={
                                language.request_service_other_type_your_skill_name
                              }
                              value={this.state.service_name_other}
                              onChange={this.handleChange}
                              name="service_name_other"
                              type="text"
                              validaterule={["required"]}
                              validatemsg={["This field is required"]}
                              required={this.state.isServiceNameOther}
                            />
                            <p className="invalid_input">
                              {error.service_name_other &&
                                language.other_this_field_is_required}
                            </p>
                          </Form.Group>
                        )}

                        <Form.Group>
                          <Select
                            options={this.props.allEquipments}
                            onChange={(item) => {
                              this.handleSelect("equipment_type_hash_id", item);
                            }}
                            placeholder={
                              language.request_service_plh_type_equipment
                            }
                            name="equipment_type_hash_id"
                          />
                        </Form.Group>

                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            placeholder={
                              language.request_service_plh_leave_comment
                            }
                            style={{ height: "100px" }}
                            name="comment"
                            value={this.state.comment}
                            onChange={this.handleChange}
                          />
                        </Form.Group>

                        <div className="mb-4">
                          <ReCAPTCHA
                            sitekey={RecaptchaApiKey()}
                            onChange={this.onChange}
                            required
                          />
                        </div>
                        <Button
                          type="submit"
                          disabled={this.state.captchaValue === null}
                          className="submit_button commn_button"
                        >
                          {language.request_service_submit_btn}
                        </Button>
                      </Form>
                    </div>
                  </CustomPopupFullScreen>
                </div>
              </Container>
            </div>
          </div>

          <div>
            <LazyLoadImage
              className="d-block w-100"
              src={bulldozer_operator_job_hiring}
              alt="Bulldozer Operator Job Hiring"
              effect="blur"
            />
            <div className="tetx_cont">
              <Container fluid>
                <div className="banner_cont">
                  <h2>{language.hire_manpower}</h2>
                  <p>{language.hire_manpower_desc}</p>
                  <Button
                    className="submit_form_home commn_button"
                    onClick={() => {
                      this.setState({
                        hireResourcePopup: true,
                        isPopupOpen: true,
                      });
                    }}
                  >
                    {language.hire_resource_btn}
                  </Button>
                  <CustomPopupFullScreen
                    title={language.hire_resourse || ""}
                    show={this.state.hireResourcePopup}
                    onClose={this.popupClose}
                  >
                    <div className="form_center">
                      <Form
                        className="home_form"
                        onSubmit={this.hireResourceNow}
                      >
                        {!isLogin && (
                          <>
                            <Form.Group>
                              <Form.Label>
                                <b>{language.hire_resourse_label_name}</b>
                                <span className="required">*</span>
                              </Form.Label>
                              <Form.Control
                                placeholder={
                                  language.hire_resourse_plh_enter_name
                                }
                                value={this.state.name}
                                onChange={this.handleChange}
                                name="name"
                                type="text"
                                validaterule={["required"]}
                                validatemsg={["Name is required"]}
                                required={!isLogin}
                              />
                              <p className="invalid_input">
                                {error.name &&
                                  language.hire_resourse_errormsg_name_required}
                              </p>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                <b>{language.hire_resourse_label_mobile}</b>
                                <span className="required">*</span>
                              </Form.Label>
                              <Form.Control
                                placeholder={
                                  language.hire_resourse_plh_enter_mobile_number
                                }
                                value={this.state.mobile}
                                onChange={this.handleChange}
                                name="mobile"
                                type="text"
                                required={!isLogin}
                              />
                              <p className="invalid_input">
                                {error.mobile &&
                                  language.hire_resourse_errormsg_valid_phone_number}
                              </p>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                <b>{language.hire_resourse_label_email}</b>
                              </Form.Label>
                              <Form.Control
                                placeholder={
                                  language.hire_resourse_plh_enter_email
                                }
                                value={this.state.email}
                                onChange={this.handleChange}
                                name="email"
                                type="email"
                              />
                              <p className="invalid_input">{error.email}</p>
                            </Form.Group>
                          </>
                        )}

                        <Form.Label>
                          <b>{language.hire_resourse_label_experties}</b>
                        </Form.Label>
                        {allExpertise &&
                          allExpertise.map((expertise, index) => {
                            return (
                              <div key={index} className="mb-2">
                                {expertise.sub_category.length > 0 ? (
                                  <Form.Group>
                                    <Form.Label className="sub_category_label">
                                      {expertise.category_name}
                                    </Form.Label>
                                    <div className="check_container">
                                      {expertise.sub_category.map(
                                        (sub_cat, ind) => {
                                          return (
                                            <Form.Check
                                              type="checkbox"
                                              label={sub_cat.category_name}
                                              onChange={this.handleChange}
                                              value={sub_cat.id}
                                              name="skill"
                                              className="sub_category_check"
                                              key={ind}
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                    <hr />
                                  </Form.Group>
                                ) : (
                                  <>
                                    <Form.Check
                                      type="checkbox"
                                      label={expertise.category_name}
                                      onChange={(e) => {
                                        this.handleChange(
                                          e,
                                          expertise.category_name
                                        );
                                      }}
                                      value={expertise.id}
                                      name="skill"
                                    />
                                    {expertise.category_name === "Others" &&
                                      this.state.isSkillOther && (
                                        <Form.Group>
                                          <Form.Control
                                            placeholder={
                                              language.hire_resourse_other_type_your_expertise_name
                                            }
                                            value={this.state.skill_other}
                                            onChange={this.handleChange}
                                            name="skill_other"
                                            type="text"
                                            validaterule={["required"]}
                                            validatemsg={[
                                              "This field is required",
                                            ]}
                                            required={this.state.isSkillOther}
                                          />
                                          <p className="invalid_input">
                                            {error.skill_other &&
                                              language.other_this_field_is_required}
                                          </p>
                                        </Form.Group>
                                      )}
                                  </>
                                )}
                              </div>
                            );
                          })}

                        <p className="invalid_input">
                          {error.skill &&
                            language.please_provide_the_skill_type}
                        </p>

                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            placeholder={
                              language.hire_resourse_plh_leave_comment
                            }
                            style={{ height: "100px" }}
                            name="comment"
                            value={this.state.comment}
                            onChange={this.handleChange}
                          />
                        </Form.Group>

                        <div className="mb-4">
                          <ReCAPTCHA
                            sitekey={RecaptchaApiKey()}
                            onChange={this.onChange}
                            required
                          />
                        </div>
                        <Button
                          type="submit"
                          disabled={this.state.captchaValue === null}
                          className="submit_button commn_button"
                        >
                          {language.hire_resourse_submit_btn}
                        </Button>
                      </Form>
                    </div>
                  </CustomPopupFullScreen>
                </div>
              </Container>
            </div>
          </div>
        </Carousel>
      </div>
    );
  }
}

class StatisticCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
    };
  }
  componentDidMount() {
    axios
      .get("/statistic")
      .then((res) => {
        if (res.data.status === 1) {
          this.setState({ data: res.data.data.statistic });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
      },
      tablet: {
        breakpoint: { max: 1024, min: 675 },
        items: 4,
      },
      tablet1: {
        breakpoint: { max: 674, min: 575 },
        items: 3,
      },
      mobile: {
        breakpoint: { max: 574, min: 474 },
        items: 2,
      },

      mobile1: {
        breakpoint: { max: 473, min: 0 },
        items: 1,
      },
    };

    let { language } = this.props;

    return (
      <section className="countiong">
        <Container fluid>
          <div className="banner_bottom_part">
            <Carousel
              responsive={responsive}
              centerMode={false}
              className="slider__banner_bottom"
              infinite="true"
              arrows={false}
              showDots={true}
              autoPlay={false}
              //autoPlay={this.props.deviceType !== "mobile" ? true : true}
              //autoPlaySpeed={4000}
              slidesToSlide={5}
            >
              <CounterItem
                title={language.total_users}
                count={this.state.data.total_user || 0}
                icon={total_user}
              />
              <CounterItem
                title={language.service_provider}
                count={this.state.data.serivce_provider || 0}
                icon={serivce_provider}
                link="/total-service-provider"
              />
              <CounterItem
                title={language.parts_dealers}
                count={this.state.data.parts_dealer || 0}
                icon={parts_dealer}
                link="/total-parts-dealer"
              />
              <CounterItem
                title={language.job_seekers}
                count={this.state.data.jobseeker || 0}
                icon={jobseeker_icon}
                link="/total-job-seeker"
              />
              <CounterItem
                title={language.equipment_for_rent}
                count={this.state.data.equipment_on_rent || 0}
                icon={equipment_on_rent}
                link="/total-rent-equipment"
              />
              <CounterItem
                title={language.equipment_for_resale}
                count={this.state.data.equipment_on_sell || 0}
                icon={equipment_on_sell}
                link="/total-resale-equipment"
              />
            </Carousel>
          </div>
        </Container>
      </section>
    );
  }
}

class CounterItem extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="banner_inner_part">
        <div className="countercont">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="counter_topsvg"
            viewBox="0 0 218 14"
            fill="none"
          >
            <path
              d="M0 10.5511C0 4.81766 4.83351 0.260839 10.5594 0.554452C89.6826 4.61175 136.194 4.68318 207.374 0.62502C213.125 0.297132 218 4.86311 218 10.6235V14H0V10.5511Z"
              fill="white"
            />
          </svg>
          <div className="banner_inner_inner">
            <div className="counter_img">
              <img src={this.props.icon} alt={this.props.title} />
            </div>
            <h3>
              {this.props.link ? (
                <Link to={this.props.link}>{this.props.title}</Link>
              ) : (
                <>{this.props.title}</>
              )}
            </h3>
            <div className="counter_texttts">{this.props.count}</div>
          </div>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="counter_bottomsvg"
            viewBox="0 0 218 14"
            // preserveAspectRatio="xMinYMin"
            fill="none"
          >
            <path
              d="M0 3.44892C0 9.18234 4.83351 13.7392 10.5594 13.4455C89.6826 9.38825 136.194 9.31682 207.374 13.375C213.125 13.7029 218 9.13689 218 3.37646V0H0V3.44892Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    );
  }
}

class MachineType extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 7,
        slidesToSlide: 7, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 5,
        slidesToSlide: 5, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
      },
    };
    let { allEquipments } = this.props;
    let { language } = this.props;

    return (
      <div className="machine_type">
        <Container fluid>
          <div className="machine_heading">
            <h2>
              {language.machine_types}({allEquipments.length})
            </h2>
          </div>
          <div className="machine_inner_parts">
            <Carousel
              //swipeable={false}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={6000}
              // keyBoardControl={true}
              // customTransition="all .5"
              // transitionDuration={500}
              containerClass="carousel-container"
              arrows={true}
              slidesToSlide={6}
              deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              //itemClass="carousel-item-padding-40-px"
            >
              {allEquipments &&
                allEquipments.map((machine, key) => {
                  return (
                    <Link
                      to={`/product/${stringReplace(
                        machine.label,
                        " ",
                        "-"
                      )}/M${machine.equipment.id}`}
                      className="machine_listing"
                      key={key}
                    >
                      <div className="machine_inner_partts machine_type_inner_partts">
                        <img
                          src={MEDIA_BASE_URL + machine.equipment.image}
                          alt={machine.label}
                        />
                        <label>{machine.label}</label>
                        {/* <div className="total_machine_count">
                          <img src={Total_Machines} /> :<span>3</span>
                        </div> */}
                      </div>
                    </Link>
                  );
                })}
            </Carousel>
          </div>
        </Container>
      </div>
    );
  }
}

class RentedMachineTop extends Component {
  render() {
    let { topRentedMachine, language } = this.props;

    // const responsive = {
    //   desktop: {
    //     breakpoint: { max: 3000, min: 1024 },
    //     items: 4,
    //     slidesToSlide: 4, // optional, default to 1.
    //   },
    //   tablet: {
    //     breakpoint: { max: 1024, min: 464 },
    //     items: 2,
    //     slidesToSlide: 2, // optional, default to 1.
    //   },
    //   mobile: {
    //     breakpoint: { max: 464, min: 0 },
    //     items: 1,
    //     slidesToSlide: 1, // optional, default to 1.
    //   },
    // };

    // const arrowStyle = {
    //   background: "transparent",
    //   border: 0,
    //   color: "#fff",
    //   fontSize: "80px",
    // };

    // const CustomRight = ({ onClick }) => (
    //   <button className="arrow right" onClick={onClick} style={arrowStyle}>
    //     <img src={arrow_right} />
    //   </button>
    // );

    // const CustomLeft = ({ onClick }) => (
    //   <button className="arrow left" onClick={onClick} style={arrowStyle}>
    //     <img src={Arrow_Left} />
    //   </button>
    // );

    return (
      <section className="top_rented_machine">
        <Container fluid>
          <div className="machine_heading">
            <h2>{language.top_rented_equipment}</h2>
          </div>
          <div className="rented_machine rented_grid cust_arrow">
            {/* <Carousel
              swipeable={false}
              draggable={true}
              showDots={true}
              responsive={responsive}
              ssr={true}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={6000}
              containerClass="carousel-container"
              arrows={true}
              renderButtonGroupOutside={true}
              slidesToSlide={4}
              deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              customRightArrow={<CustomRight />}
              customLeftArrow={<CustomLeft />}
            > */}
            {topRentedMachine &&
              topRentedMachine.map((machine, key) => {
                return (
                  <Link
                    to={`/equipment-details/${machine.machine_activity_hash_id}/${machine.machine_hash_id}/${machine.profile_hash_id}`}
                    className="rented_machine_inner"
                    key={key}
                  >
                    <div className="rented_machine_inner_inner">
                      <div className="rented_machine_inner_top">
                        <LazyLoadImage
                          src={MEDIA_BASE_URL + machine.cover_image}
                          alt={machine.model_name}
                          effect="blur"
                        />
                        <span className="eye_show">
                          <img src={Eye_Show} />
                          {machine.view_count}
                        </span>
                      </div>
                      <div className="rented_machine_inner_bottom">
                        <h2>{machine.model_name}</h2>
                        <div className="year_brand">
                          {/* <span>
                              <label>Year</label> 2011
                            </span> */}
                          <span>
                            <label>Brand </label> {machine.brand_name}
                          </span>
                        </div>
                        {/* <div className="rented_lcation1">
                          <div className="total_machine_count">
                            Total Equipment(s):<span>3</span>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </Link>
                );
              })}
            {/* </Carousel> */}
          </div>
          {/* <div className="read_more_equipment mb-5">
                <Link to="#" className="submit_form_home commn_button">Read More</Link>
          </div> */}
        </Container>
      </section>
    );
  }
}

class TopBrands extends Component {
  render() {
    let { topBrand, language } = this.props;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 7,
        slidesToSlide: 7, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 5,
        slidesToSlide: 5, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
      },
    };
    return (
      <div className="machine_type brad_part">
        <Container fluid>
          <div className="machine_heading">
            <h2>
              {language.top_brands} ({topBrand.length})
            </h2>
          </div>
          <div className="machine_inner_parts">
            <Carousel
              swipeable={false}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={6000}
              // keyBoardControl={true}
              // customTransition="all .5"
              // transitionDuration={500}
              containerClass="carousel-container"
              arrows={true}
              deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              //itemClass="carousel-item-padding-40-px"
            >
              {topBrand &&
                topBrand.map((machine, key) => {
                  return (
                    <Link
                      to={`/product/${stringReplace(
                        machine.brand_name,
                        " ",
                        "-"
                      )}/B${machine.id}`}
                      className="machine_listing"
                      key={key}
                    >
                      <div className="machine_inner_partts brand_partts">
                        <img
                          src={MEDIA_BASE_URL + machine.brand_img_url}
                          alt={machine.brand_name}
                        />
                      </div>
                    </Link>
                  );
                })}
            </Carousel>
          </div>
        </Container>
      </div>
    );
  }
}

class SellingTop extends Component {
  render() {
    let { topSaleMachine, language } = this.props;
    return (
      <section className="top_rented_machine">
        <Container fluid>
          <div className="machine_heading">
            <h2>{language.top_resale_equipment}</h2>
          </div>
          <div className="rented_machine rented_grid cust_arrow">
            {topSaleMachine &&
              topSaleMachine.map((machine, key) => {
                return (
                  <Link
                    to={`/equipment-details/${machine.machine_activity_hash_id}/${machine.machine_hash_id}/${machine.profile_hash_id}`}
                    className="rented_machine_inner"
                    key={key}
                  >
                    <div className="rented_machine_inner_inner">
                      <div className="rented_machine_inner_top">
                        <LazyLoadImage
                          src={MEDIA_BASE_URL + machine.cover_image}
                          alt={machine.model_name}
                          effect="blur"
                        />
                        <span className="eye_show">
                          <img src={Eye_Show} />
                          {machine.view_count}
                        </span>
                      </div>
                      <div className="rented_machine_inner_bottom">
                        <h2>{machine.model_name}</h2>
                        <div className="year_brand">
                          {/* <span>
                              <label>Year</label> 2011
                            </span> */}
                          <span>
                            <label>Brand </label> {machine.brand_name}
                          </span>
                        </div>
                        {/* <div className="rented_lcation1">
                          <div className="total_machine_count">
                            Total Equipment(s):<span>3</span>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
          {/* <div className="read_more_equipment mb-5">
                <Link to="#" className="submit_form_home commn_button">Read More</Link>
          </div> */}
        </Container>
      </section>
    );
  }
}
class MarketPlace extends Component {
  render() {
    return (
      <div className="market_place">
        <Container fluid>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about_comp_players">
                <div className="abt_heading">
                  <h2>About Company</h2>
                </div>
                <div className="videoplayerbg">
                  <div className="videobg">
                    <ReactPlayer
                      url="https://www.youtube.com/embed/5kwEhgsNitE"
                      width="100%"
                      height="400px"
                      playing
                      controls
                      playIcon={
                        <button className="play_button">
                          <img src={play_icon_img} />
                        </button>
                      }
                      light={video_bg_img}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="player_text">
                <div className="commn_heading">
                  <h2>A Junction of the Heavy Machinery Industry</h2>
                </div>
                <p>
                  HMC is a single platform that helps bring the entire
                  construction equipment industry. Register on the HMC app now.
                  Along with that, HMC can assist job seekers in the
                  construction equipment industry land the ideal job. In fact,
                  HMC is efficient at helping you find solutions to all of your
                  construction equipment needs.
                </p>

                <p>
                  Locate heavy machinery, spare parts, or the best manpower with
                  a single click at no extra cost. With a click from our digital
                  connecting place, you can buy, sell, or rent your heavy
                  machinery for all construction industries with a click. Do all
                  of your dealings directly on the app, without the
                  participation of any middlemen or agents.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

class FeaturesPart extends Component {
  render() {
    let { language } = this.props;
    return (
      <section className="features_part">
        <Container fluid>
          <Row>
            <Col lg="3" md="4" sm="12">
              <div className="features_left_partss">
                <div className="commn_heading">
                  <h2>{language.hmc_feature}</h2>
                </div>
                <p>{language.hmc_feature_desc1}</p>
                <p>{language.hmc_feature_desc2}</p>
              </div>
            </Col>
            <Col lg="9" md="8" sm="12">
              <div className="features_right_partts">
                <LazyLoadImage src={features_heading} effect="blur" />
                <div className="features_item">
                  <FeaturedItem
                    color="color1"
                    icon={create_and_manage_multiple_profiles}
                    heading={language.create_manage_profile}
                    desc={language.create_manage_profile_desc}
                  />

                  <FeaturedItem
                    color="color2"
                    icon={buy_industry_best_machines}
                    heading={language.buy_heavy_equipment}
                    desc={language.buy_heavy_equipment_desc}
                  />
                  <FeaturedItem
                    color="color3"
                    icon={resale_machines_with_heightened_ease}
                    heading={language.sell_used_equipment}
                    desc={language.sell_used_equipment_desc}
                  />
                  <FeaturedItem
                    color="color4"
                    icon={rent_machines_for_each_of_your_projects}
                    heading={language.rent_equipment}
                    desc={language.rent_equipment_desc}
                  />
                  <FeaturedItem
                    color="color5"
                    icon={locate_your_machine_parts_within_your_reach}
                    heading={language.spare_parts}
                    desc={language.spare_parts_desc}
                  />
                  <FeaturedItem
                    color="color6"
                    icon={manage_your_construction_projects}
                    heading={language.manage_your_projects}
                    desc={language.manage_your_projects_desc}
                  />
                  <FeaturedItem
                    color="color7"
                    icon={find_jobs_as_per_your_expertise_and_requirements}
                    heading={language.find_job}
                    desc={language.find_job_desc}
                  />
                  <FeaturedItem
                    color="color8"
                    icon={machine_services_individual_and_company_providers}
                    heading={language.service_workshop}
                    desc={language.service_workshop_desc}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

class FeaturedItem extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="features_inner_item">
        <div className="features_inner_inner">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="features_top_svg"
            viewBox="0 0 204 44"
            fill="none"
          >
            <path
              d="M0 10.6909C0 4.55016 5.48311 -0.139904 11.5497 0.811714L195.55 29.6745C200.415 30.4376 204 34.6291 204 39.5537V44H0V10.6909Z"
              fill="#181C24"
            />
          </svg>
          <div className={`features_top_icon ${this.props.color}`}>
            <img src={this.props.icon} alt={this.props.heading} />
          </div>
          <div className="features_contts">
            <h2>{this.props.heading}</h2>
            <p>{this.props.desc}</p>
          </div>
        </div>
      </div>
    );
  }
}

class ExpertAdvice extends Component {
  render() {
    let { language } = this.props;
    return (
      <section className="expert_advice2">
        <Container fluid>
          <Row>
            <Col lg="5" md="5" sm="12" className="expert_lfttr">
              <div className="expert_left">
                <div className="commn_heading">
                  <h2>{language.need_help}</h2>
                  <h5>{language.get_help_from_our_expert}</h5>
                </div>
                <p>{language.need_help_desc}</p>
                <div className="expert_button">
                  <Link to="/expert-connect">{language.connect_btn}</Link>
                </div>
              </div>
            </Col>
            <Col lg="7" md="7" sm="12" className="expert_righttr">
              <div className="expert_right">
                <LazyLoadImage
                  src={construction_equipment_expert}
                  className="img-fluid"
                  alt="Construction Equipment Expert"
                  effect="blur"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

class OurServices extends Component {
  render() {
    let { language } = this.props;
    return (
      <section className="our_services">
        <div className="services_left">
          <LazyLoadImage
            src={road_construction_equipment_rental}
            className="img-fluid"
            alt="Road Construction Equipment Rental"
            effect="blur"
          />
        </div>
        <div className="services_right">
          <div className="commn_heading">
            <h2>{language.services}</h2>
          </div>
          <p>{language.services_desc}</p>
          {/* <div className="services_learn_more">
            <a href="#">Learn more</a>
          </div> */}
          <div className="services_heading">
            <LazyLoadImage src={services_heading} effect="blur" />
          </div>
        </div>
        <div className="sevices_skew_img" />
      </section>
    );
  }
}

class OurProjects extends Component {
  render() {
    let { language } = this.props;
    return (
      <section className="our_projects">
        <Container fluid>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="prj_left">
                <div className="commn_heading">
                  <h2>{language.projects}</h2>
                </div>
                <p>{language.projects_desc}</p>

                <div className="services_learn_more">
                  <Link to="/projects">{language.learn_more_btn}</Link>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="prj_right">
                <div className="prj_heading">
                  <LazyLoadImage src={our_projects_heading} effect="blur" />
                </div>
                <div className="prj_right_img">
                  <LazyLoadImage
                    src={resale_construction_equipment_online}
                    className="img-fluid"
                    alt="Resale Construction Equipment Online"
                    effect="blur"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

class OurCareer extends Component {
  render() {
    let { language } = this.props;
    return (
      <section className="our_career">
        <Container fluid>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="career_image">
                <LazyLoadImage
                  src={heavy_equipment_operator_jobs}
                  alt="Heavy Equipment Operator Jobs"
                  effect="blur"
                />
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="career_right_text">
                <div className="commn_heading">
                  <h2>{language.jobs}</h2>
                </div>
                <p>{language.jobs_desc}</p>
                {/* <div className="services_learn_more">
                  <a href="#">Learn more</a>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

class OurFinance extends Component {
  render() {
    let { language } = this.props;
    return (
      <section className="our_finance">
        <Container fluid>
          <Row>
            <Col lg="6" md="6" sm="12"></Col>
            <Col lg="6" md="6" sm="12">
              <div className="finance_right_text">
                <div className="commn_heading">
                  <h2>{language.finance}</h2>
                  <h3>{language.financer_for_your_equipment}</h3>
                </div>
                <p>{language.finance_desc}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default connect(mapStateToProps, mapActionToProps)(HomePage);
