import React, { Component } from "react";
import { Form, Button, Container } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "./login.css";
import axios from "../../../config/axios";
import message from "../../../utils/messages";
import OtpInput from "react-otp-input";
import LoadingOverlay from "react-loading-overlay";
import { Login_img } from "../../../assets/images";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Seo from "../../../components/seo/Seo";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language, country_code } = state;
  return { language, country_code };
};

class MobileLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country_code: "",
      mobile: "",
      isLoading: false,
      phoneValue: "",
      error: "",
      isOtp: false,
      onComplete: false,
      timer: "00",
      otp: "",
      otpError: false,
      country_code_list: [],
      countryName: "",

      footer_faq_data: [],
      seo_data: {},
    };
  }
  componentDidMount = () => {
    if (localStorage.getItem("api-token")) {
      this.props.history.push({
        pathname: "/dashboard",
      });
    }
    this.getMetaData();
  };

  getMetaData = () => {
    axios
      .get(
        `/web-seo-content?page_key=login&call_from=page&lang_type=${localStorage.getItem(
          "language"
        )}`
      )
      .then((res) => {
        if (res.data.status === "1") {
          let { seo_data, footer_faq_data } = res.data.data;
          this.setState({ seo_data, footer_faq_data });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handlePhoneChange = (value) => {
    let { error } = this.state;
    if (value !== undefined) {
      this.setState({ phoneValue: value });

      let number = parsePhoneNumber(value);
      if (number) {
        let country_code = "+" + number.countryCallingCode;
        let mobile = number.nationalNumber;
        this.setState({ country_code, mobile });
      }
      if (isValidPhoneNumber(value)) {
        error = "";
      } else {
        error = "Enter Valid Mobile Number";
      }
      this.setState({ error });
    } else {
      error = "Enter Valid Mobile Number";
      this.setState({ error, mobile: "" });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.error) {
      return;
    }
    this.getOtp();
  };

  getOtp = () => {
    this.setState({ isLoading: true });
    let country_code = this.state.country_code.substring(1);
    axios
      .get(
        `/otp-verification/${this.state.phoneValue}?country_code=${country_code}`
      )
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          message.success(res.data.message);
          this.setState({ onComplete: false, isOtp: true });
          this.startTimer(30);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  startTimer = (seconds) => {
    var myTimer = setInterval(() => {
      if (seconds === 0) {
        clearInterval(myTimer);
        this.setState({ onComplete: true });
      }
      this.setState({ timer: seconds > 9 ? seconds : "0" + seconds });
      seconds--;
    }, 1000);
  };

  verifyOtp = (otp) => {
    let formData = {
      mobile: this.state.phoneValue,
      otp,
      platform: "web",
    };

    this.setState({ isLoading: true });

    axios
      .post("/otp-check", formData)
      .then((res) => {
        if (res.data.status === "1") {
          if (res.data.data.need_basic_info === "1") {
            let { phoneValue } = this.state;
            let { temp_id } = res.data.data;

            // go to create profile
            window.location.pathname = `signup/${phoneValue}/${temp_id}`;
          } else {
            // get profile details page
            localStorage.setItem("api-token", res.data.data.api_token_web);
            localStorage.setItem("userdata", JSON.stringify(res.data.data));
            this.getProfileDetails(res.data.data);
          }
        } else {
          this.setState({ isLoading: false });
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  getProfileDetails = (userData) => {
    const formData = {
      hash_id: userData.hash_id,
    };
    const config = {
      headers: { api_token: localStorage.getItem("api-token") },
    };

    axios
      .post("/profile-details", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1" && res.data.message === "Success") {
          let { media_base_url } = res.data.data;
          localStorage.setItem("media_base_url", media_base_url);
          res.data.data.profile_data.forEach((profile) => {
            if (profile.profile_type === "1") {
              localStorage.setItem("basicProfile", JSON.stringify(profile));
            } else if (profile.profile_type === "2") {
              localStorage.setItem("sellerProfile", JSON.stringify(profile));
            }
          });
        }
        window.location.pathname = "dashboard";
      })
      .catch(() => {
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
        localStorage.clear();
      });
  };

  handleOtpChange = (otp) => {
    this.setState({ otp });
    // if (otp.length === 4) {
    //   this.verifyOtp(otp);
    // }
  };

  resetField = () => {
    this.setState({
      isLoading: false,
      error: "",
      isOtp: false,
      onComplete: false,
      timer: "00",
      otp: "",
      otpError: false,
    });
  };

  render() {
    let { seo_data, country_code_list } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.mobile_login;
    } else {
      language = {};
    }

    let country_loading = this.props.country_code.loading;
    let country_code = this.props.country_code.country_code_list;

    if (!country_loading && country_code) {
      country_code_list = country_code.country_code_list.map((val) => {
        return val.code;
      });
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        {seo_data && (
          <Helmet>
            <title>{seo_data.meta_title}</title>
            <meta name="description" content={seo_data.meta_description} />
            <meta name="keywords" content={seo_data.meta_key} />
            <link rel="canonical" href={seo_data.canonical_url} />
          </Helmet>
        )}

        <div className="home_new">
          <section className="moblie_login_partts">
            <div className="mobile_login_inner_partts">
              <Container>
                <div className="mobile_logininner_box">
                  <div className="mobile_box_left">
                    <h2>{language.welcome_to_hmc}</h2>
                    <p>{language.welcome_to_hmc_desc}</p>
                    <div className="login_boximg">
                      <img alt="HMC" src={Login_img} />
                    </div>
                  </div>

                  {this.state.isOtp ? (
                    <div className="mobile_box_right">
                      <div className="mobile_right_inner_box">
                        <h4>{language.mobile_phone_verification}</h4>
                        <div className="otp_field">
                          <p className="verify_no">
                            {language.enter_code_send_on_your_phone}{" "}
                            <b>
                              {this.state.country_code +
                                " " +
                                this.state.mobile}
                            </b>{" "}
                            <span onClick={this.resetField}>
                              {language.change_number}
                            </span>
                          </p>
                          <OtpInput
                            onChange={this.handleOtpChange}
                            numInputs={4}
                            inputStyle="inputStyle"
                            isInputNum={true}
                            placeholder="****"
                            hasErrored={this.state.otpError}
                            errorStyle="error"
                            value={this.state.otp}
                          />
                          <div className="countdown">
                            {language.time_left} : {this.state.timer}
                          </div>
                          {this.state.onComplete && (
                            <div className="resend">
                              {language.receive_the_code}{" "}
                              <span onClick={this.getOtp}>
                                {language.resend}
                              </span>
                            </div>
                          )}
                          <div className="otp_submit mt-2">
                            <Button
                              className="btn btn-dark"
                              onClick={() => {
                                this.resetField();
                                this.setState({
                                  country_code: "",
                                  mobile: "",
                                  phoneValue: "",
                                });
                              }}
                            >
                              {language.cancel_btn}
                            </Button>
                            <Button
                              className="ml-3 btn btn-warning"
                              onClick={() => {
                                if (this.state.otp.length !== 4) {
                                  this.setState({
                                    otpError: true,
                                  });
                                } else {
                                  this.setState({
                                    otpError: false,
                                  });
                                  this.verifyOtp(this.state.otp);
                                }
                              }}
                            >
                              {language.verify_btn}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mobile_box_right">
                      <div className="mobile_right_inner_box">
                        <h2>
                          {language.register} / {language.login}
                        </h2>
                        <Form onSubmit={this.handleSubmit}>
                          <Form.Group
                            className="login_box_partts"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              {language.login_label_phone_number}
                              <span className="required">*</span>
                            </Form.Label>
                            {country_code_list.length !== 0 && (
                              <PhoneInput
                                className="phone_field"
                                defaultCountry={
                                  localStorage.getItem("country") || "IN"
                                }
                                placeholder={
                                  language.login_plh_enter_mobile_number
                                }
                                countries={country_code_list}
                                value={this.state.phoneValue}
                                onChange={this.handlePhoneChange}
                                required
                                addInternationalOption={false}
                              />
                            )}
                            <p className="invalid_input">
                              {this.state.error &&
                                language.login_errormsg_enter_valid_mobile_number}
                            </p>
                          </Form.Group>

                          <Button
                            type="submit"
                            className="login_submit_but commn_button"
                          >
                            {language.login_btn}
                          </Button>
                        </Form>
                        <div className="login_or_partss">
                          <span>{language.login_label_or}</span>
                        </div>
                        <div className="login_using_email_id">
                          <Link to="/email-login">
                            <Button className="login_submit_but commn_button">
                              {language.login_using_email_id_btn}
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Container>
            </div>
          </section>
          <Seo {...this.state} />
        </div>
      </LoadingOverlay>
    );
  }
}

export default connect(mapStateToProps)(MobileLogin);
