import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import {
  about_banner_text,
  vision,
  mission,
  expert_advice,
  about_banner_img,
} from "../../assets/images";
import "./AboutUsPage.css";
import MobileApp from "../home/MobileApp";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import axios from "../../config/axios";
import LoadingOverlay from "react-loading-overlay";
import Seo from "../../components/seo/Seo";
import { GetLanguageDetails } from "../../app/action/LanguageActions";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

const mapActionToProps = (dispatch) => {
  return {
    getLanguageContent: (body) => dispatch(GetLanguageDetails(body)),
  };
};
class AboutUsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,

      footer_faq_data: [],
      seo_data: {},
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.getMetaData();
  };

  getMetaData = () => {
    this.setState({ isLoading: true });

    axios
      .get(
        `/web-seo-content?page_key=about_us&call_from=page&lang_type=${localStorage.getItem(
          "language"
        )}`
      )
      .then((res) => {
        this.setState({ isLoading: false });

        if (res.data.status === "1") {
          let { seo_data, footer_faq_data } = res.data.data;
          this.setState({ seo_data, footer_faq_data });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
      });
  };

  render() {
    let { seo_data } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.about_us;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          {seo_data && (
            <Helmet>
              <title>{seo_data.meta_title}</title>
              <meta name="description" content={seo_data.meta_description} />
              <meta name="keywords" content={seo_data.meta_key} />
              <link rel="canonical" href={seo_data.canonical_url} />
            </Helmet>
          )}

          <AboutUsPartts language={language} />
          <AboutMission language={language} />
          <AboutExpertAdvice language={language} />
          <MobileApp />
          <Seo {...this.state} />
        </div>
      </LoadingOverlay>
    );
  }
}

class AboutUsPartts extends Component {
  render() {
    let { language } = this.props;
    return (
      <section className="about_us_partts">
        <div className="aout_left_ppartts">
          <span className="about_left_img">
            <LazyLoadImage src={about_banner_text} alt="HMC" />
          </span>
          <div className="about_left_imggg">
            <LazyLoadImage
              src={about_banner_img}
              alt="Construction Machinery"
            />
          </div>
        </div>
        <div className="aout_right_ppartts">
          <div className="about_rightt_innner_ppsrtts">
            <div className="commn_heading">
              <h2>{language.about_us}</h2>
            </div>
            <p>{language.about_us_desc}</p>
          </div>
        </div>
      </section>
    );
  }
}
class AboutMission extends Component {
  render() {
    let { language } = this.props;
    return (
      <section className="mission">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="mission_left">
                <div className="mission_top_box">
                  <LazyLoadImage src={vision} alt="Vision" effect="blur" />
                </div>
                <h2>{language.vision}</h2>
                <p>{language.vision_desc}</p>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="mission_left mission_right">
                <div className="mission_top_box">
                  <LazyLoadImage src={mission} alt="Mission" effect="blur" />
                </div>
                <h2>{language.mission}</h2>
                <p>{language.mission_desc}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

class AboutExpertAdvice extends Component {
  render() {
    let { language } = this.props;
    return (
      <section className="abt_exp_adv">
        <div className="abt_exp_left"></div>
        <div className="abt_exp_right">
          <Container>
            <div className="exp_text_img">
              <div className="abt_exp_left_img">
                <LazyLoadImage
                  src={expert_advice}
                  alt="Expert Advice"
                  effect="blur"
                />
              </div>
              <div className="abt_exp_right_text">
                <div className="commn_heading">
                  <h2>{language.expert_advice}</h2>
                </div>
                <p>{language.expert_advice_desc}</p>
                <div className="exp_connect">
                  <Link to="/expert-connect">
                    {language.about_us_connect_btn}
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>
    );
  }
}

export default connect(mapStateToProps, mapActionToProps)(AboutUsPage);
