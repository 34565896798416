import React, { Component } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import axios from "../../config/axios";
import message from "../../utils/messages";
import Select from "react-select";
import "./finance.css";
import config from "../../config/apiHeader";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Seo from "../../components/seo/Seo";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};
class Finance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hash_id: "",
      newEqu: false,
      oldEqu: false,
      machine_hash_id: "",
      machine_model_name: "",

      allMachineModels: [],
      error: {},

      footer_faq_data: [],
      seo_data: {},
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.getMetaData();

    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }
    this.getAllMachineModels();
  };

  getMetaData = () => {
    axios
      .get(
        `/web-seo-content?page_key=finance&call_from=page&lang_type=${localStorage.getItem(
          "language"
        )}`
      )
      .then((res) => {
        if (res.data.status === "1") {
          let { seo_data, footer_faq_data } = res.data.data;
          this.setState({ seo_data, footer_faq_data });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAllMachineModels = () => {
    this.setState({ isLoading: true });

    const formData = {
      medium: "web",
      section_name: "finance_get_all_machine_model",
    };

    axios
      .post("/machine-model-list", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let allMachineModels = [];
          res.data.data.machines.map((machine) => {
            return allMachineModels.push({
              value: machine.machine_hash_id,
              label: machine.model_name,
            });
          });
          this.setState({
            allMachineModels,
          });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  onSubmitRequest = () => {
    let {
      machine_hash_id,
      machine_model_name,
      error,
      hash_id,
      newEqu,
      oldEqu,
    } = this.state;

    if (!newEqu && !oldEqu) {
      error.equipment_type_old_new =
        "Select new or used or both type of equipment";
      this.setState({ error });
      return;
    } else {
      error.equipment_type_old_new = "";
      this.setState({ error });
    }

    if (!machine_hash_id) {
      error.machine_hash_id = "Select Equipment Model";
      this.setState({ error });
      return;
    }

    const formData = {
      user_hash_id: hash_id,
      equipment_type_old_new: newEqu && oldEqu ? 3 : newEqu ? 1 : 2,
      request_for: "5",
      machine_hash_id,
      machine_model_name,
      medium: "web",
      section_name: "finance_submit_request",
    };
    this.setState({ isLoading: true });
    axios
      .post("/request-management-save", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  render() {
    let { error, seo_data } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.finance;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        {seo_data && (
          <Helmet>
            <title>{seo_data.meta_title}</title>
            <meta name="description" content={seo_data.meta_description} />
            <meta name="keywords" content={seo_data.meta_key} />
            <link rel="canonical" href={seo_data.canonical_url} />
          </Helmet>
        )}
        <div className="home_new">
          <div className="finance_inner_part dashboard_part">
            <Container fluid>
              <Row className="justify-content-center">
                <Col className="ds_right" lg="9" md="8" sm="12">
                  <div className="finance_box">
                    <h2>{language.finance_h}</h2>
                    <div className="finance_inner_box">
                      {!localStorage.getItem("api-token") && (
                        <div className="alert alert-warning text-center mb-4">
                          {language.need_to_logeed_in_use_features}{" "}
                          <Link to="/login">{language.login_link_text}</Link>
                        </div>
                      )}
                      <Form.Group
                        className="finance_box_partts"
                        controlId="formBasicEmail"
                      >
                        <div className="finance_box_partts_left">
                          <Form.Label>
                            {language.finance_label_type_of_equipment}
                          </Form.Label>
                        </div>
                        <div className="finance_box_partts_right d-flex">
                          <Form.Group className="eqp_check">
                            <Form.Control
                              type="checkbox"
                              id="new"
                              onChange={() => {
                                this.setState({ newEqu: !this.state.newEqu });
                              }}
                            />
                            <Form.Label htmlFor="new">
                              {language.finance_type_equipment_option_new}
                            </Form.Label>
                          </Form.Group>

                          <Form.Group className="eqp_check">
                            <Form.Control
                              type="checkbox"
                              id="used"
                              onChange={() => {
                                this.setState({ oldEqu: !this.state.oldEqu });
                              }}
                            />
                            <Form.Label htmlFor="used">
                              {language.finance_type_equipment_option_used}
                            </Form.Label>
                          </Form.Group>
                          <p className="invalid_input">
                            {error.equipment_type_old_new}
                          </p>
                        </div>
                      </Form.Group>

                      <Form.Group className="finance_box_partts">
                        <div className="finance_box_partts_left">
                          <Form.Label>
                            {language.finance_label_equipment_model_name}
                          </Form.Label>
                        </div>
                        <div className="finance_box_partts_right">
                          <Select
                            options={this.state.allMachineModels}
                            onChange={(item) => {
                              error.machine_hash_id = "";
                              this.setState({
                                machine_hash_id: item.value,
                                machine_model_name: item.label,
                                error,
                              });
                            }}
                            placeholder={
                              language.finance_plh_select_equipm_model_name
                            }
                            name="machine_hash_id"
                          />
                          <p className="invalid_input">
                            {error.machine_hash_id}
                          </p>
                        </div>
                      </Form.Group>

                      <Form.Group
                        className="finance_box_partts"
                        controlId="formBasicEmail"
                      >
                        <div className="finance_box_partts_left">
                          <Form.Label>
                            {language.finance_label_credit_score}
                          </Form.Label>
                        </div>
                        <div className="finance_box_partts_right">
                          <div className="flex-wrap d-flex">
                            <Form.Group
                              className="eqp_check"
                              controlId="formBasicEmail"
                            >
                              <input
                                type="radio"
                                id="test1"
                                name="radio-group"
                              />
                              <label for="test1">
                                {language.finance_option_yes}
                              </label>
                            </Form.Group>

                            <Form.Group
                              className="eqp_check"
                              controlId="formBasicEmail"
                            >
                              <input
                                type="radio"
                                id="test2"
                                name="radio-group"
                              />
                              <label for="test2">
                                {language.finance_option_no}
                              </label>
                            </Form.Group>
                          </div>
                        </div>
                      </Form.Group>

                      <Form.Group
                        className="finance_box_partts"
                        controlId="formBasicEmail"
                      >
                        <div className="finance_box_partts_left">
                          <Form.Label>
                            {language.finance_label_tentative_score}
                          </Form.Label>
                        </div>
                        <div className="finance_box_partts_right">
                          <div className="radio-toolbar">
                            <input
                              type="radio"
                              id="radioApple"
                              name="radioFruit"
                              value="apple"
                            />
                            <label for="radioApple">500 - 600</label>

                            <input
                              type="radio"
                              id="radioBanana"
                              name="radioFruit"
                              value="banana"
                            />
                            <label for="radioBanana">600 - 700</label>

                            <input
                              type="radio"
                              id="radioOrange"
                              name="radioFruit"
                              value="orange"
                            />
                            <label for="radioOrange">700 - 800</label>
                            <input
                              type="radio"
                              id="radioOrange1"
                              name="radioFruit"
                              value="orange1"
                            />
                            <label for="radioOrange1">800 - 900</label>
                          </div>
                        </div>
                      </Form.Group>

                      <Form.Group
                        className="finance_box_partts"
                        controlId="formBasicEmail"
                      >
                        <div className="finance_box_partts_left">
                          <Form.Label>
                            {language.finance_label_buy_the_machine}
                          </Form.Label>
                        </div>
                        <div className="finance_box_partts_right1">
                          <div className="finance_box_partts_right1 d-flex flex-wrap">
                            <Form.Group
                              className="eqp_check"
                              controlId="formBasicEmail"
                            >
                              <input
                                type="radio"
                                id="test9"
                                name="radio-group"
                              />
                              <label for="test9">
                                {
                                  language.finance_buy_the_machine_option_immediate
                                }
                              </label>
                            </Form.Group>

                            <Form.Group
                              className="eqp_check"
                              controlId="formBasicEmail"
                            >
                              <input
                                type="radio"
                                id="test20"
                                name="radio-group"
                              />
                              <label for="test20">
                                {
                                  language.finance_buy_the_machine_option_1_month
                                }
                              </label>
                            </Form.Group>

                            <Form.Group
                              className="eqp_check"
                              controlId="formBasicEmail"
                            >
                              <input
                                type="radio"
                                id="test3"
                                name="radio-group"
                              />
                              <label for="test3">
                                {
                                  language.finance_buy_the_machine_option_3_month
                                }
                              </label>
                            </Form.Group>

                            <Form.Group
                              className="eqp_check"
                              controlId="formBasicEmail"
                            >
                              <input
                                type="radio"
                                id="test4"
                                name="radio-group"
                              />
                              <label for="test4">
                                {
                                  language.finance_buy_the_machine_option_6_month
                                }
                              </label>
                            </Form.Group>
                          </div>
                        </div>
                      </Form.Group>
                      <div className="submit_inner">
                        <Button
                          variant="primary"
                          type="submit"
                          className="btn_primary "
                          onClick={this.onSubmitRequest}
                          disabled={!localStorage.getItem("api-token")}
                        >
                          {language.finance_submit_btn}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Seo {...this.state} />
      </LoadingOverlay>
    );
  }
}

export default connect(mapStateToProps)(Finance);
