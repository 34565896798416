import React, { Component } from "react";
import { Col, Nav } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import {
  prof_icon_menu,
  Dashboard_icon_img,
  Dashboard_icon3_img,
  Dashboard_icon4_img,
  Dashboard_icon5_img,
  Lead_manage_Img,
  Dashboard_icon7_img,
} from "../../assets/images";
import "./leftpanelpage.css";
import axios from "../../config/axios";
import { MEDIA_BASE_URL } from "../../utils/Helper";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};
class LeftPanelPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hash_id: "",
      name: "",
      email: "",
      mobile: "",
      img_url: "",
      sub_menus: null,
      leadManagement: null,
    };
  }

  componentDidMount = async () => {
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }
    let profiledata = JSON.parse(localStorage.getItem("basicProfile"));
    if (profiledata) {
      let { first_name, img_url, last_name, country_code, mobile, email } =
        profiledata;
      let name = first_name + " " + last_name;
      mobile = mobile.startsWith("+") ? mobile : country_code + mobile;
      this.setState({ name, email, mobile, img_url });
    }
    this.getLeadPermission();
  };

  getLeadPermission = () => {
    let { hash_id } = this.state;
    const config = {
      headers: {
        api_token: localStorage.getItem("api-token"),
        Authorization: hash_id,
      },
    };

    const formData = {
      hash_id,
      medium: "web",
      section_name: "lm_leftpannel_lead_permission",
    };

    axios
      .post("/get-permission-menue", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let leadManagement = res.data.data;
          if (leadManagement) {
            leadManagement = leadManagement[0];
            let { sub_menus } = leadManagement;
            delete leadManagement.sub_menus;
            this.setState({ sub_menus, leadManagement });
            localStorage.setItem("sub_menus", JSON.stringify(sub_menus));
          } else {
            localStorage.setItem("sub_menus", null);
          }
          localStorage.setItem("lead_user_role", res.data.lead_user_role);
          localStorage.setItem("lead_user", res.data.lead_user);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getMenuNameByLanguage = (menu) => {
    const local_language = localStorage.getItem("language");
    if (local_language === "en") return menu.name;
    return menu[local_language];
  };

  render() {
    let { sub_menus, hash_id, leadManagement } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.left_menu;
    } else {
      language = {};
    }

    return (
      <Col lg="3" md="4" sm="12" className="ds_left">
        <div className="left_panel_dash">
          <div className="dash_user_image">
            <div className="dash_user_inner_image">
              <img
                alt="HMC"
                src={
                  this.state.img_url
                    ? MEDIA_BASE_URL + this.state.img_url
                    : prof_icon_menu
                }
              />
            </div>
            <Link
              to={`/basic-profile/${hash_id}/1`}
              className="upload-btn-wrapper"
            >
              <div className="upload_edit">
                <i className="fa fa-pencil" aria-hidden="true" />
              </div>
            </Link>
          </div>
          <div className="user_name_email">
            <h2>{this.state.name}</h2>
            <p>{this.state.email || this.state.mobile}</p>
          </div>
          <div className="dash_menu">
            <Nav as="ul" className="dashmenu_ul">
              <Nav.Item as="li">
                <NavLink to="/dashboard" exact>
                  <span className="pro-icon-wrapper">
                    {/* <img alt="HMC" src={Dashboard_icon_img} /> */}
                    <i className="material-icons">dashboard</i>
                  </span>
                  {language.dashboard}
                </NavLink>
              </Nav.Item>
              <Nav.Item as="li">
                <NavLink to="/profile" exact>
                  <span className="pro-icon-wrapper">
                    <i className="fa fa-user-o" aria-hidden="true" />
                  </span>
                  {language.my_profiles}
                </NavLink>
              </Nav.Item>

              <SubMenu
                className="nav-item"
                title={language.equipments}
                icon={<img alt="HMC" src={Dashboard_icon3_img} />}
              >
                <MenuItem>
                  <NavLink to="/all-equipment" exact>
                    {language.buy_rent_equipment}
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to="/my-equipments" exact>
                    {language.my_equipments}
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to="/add-equipment" exact>
                    {language.add_equipment}
                  </NavLink>
                </MenuItem>
              </SubMenu>

              <Nav.Item as="li">
                <NavLink to="/service-provider" exact>
                  <span className="pro-icon-wrapper">
                    <img alt="HMC" src={Dashboard_icon4_img} />
                  </span>
                  {language.service_providers}
                </NavLink>
              </Nav.Item>
              <Nav.Item as="li">
                <NavLink to="/parts" exact>
                  <span className="pro-icon-wrapper">
                    <img alt="HMC" src={Dashboard_icon5_img} />
                  </span>
                  {language.parts_dealers}
                </NavLink>
              </Nav.Item>
              <Nav.Item as="li">
                <NavLink to="/job-seeker" exact>
                  <span className="pro-icon-wrapper">
                    <img alt="HMC" src={Dashboard_icon7_img} />
                  </span>
                  {language.job_seeker}
                </NavLink>
              </Nav.Item>
              <Nav.Item as="li">
                <NavLink to="/request-management" exact>
                  <span className="pro-icon-wrapper">
                    <i className="fa fa-pencil-square-o" aria-hidden="true" />
                  </span>
                  {language.request_management}
                </NavLink>
              </Nav.Item>
              {sub_menus && (
                <SubMenu
                  className="nav-item"
                  title={this.getMenuNameByLanguage(leadManagement)}
                  icon={
                    <i className="material-icons">equalizer</i>
                    // <img alt="HMC" src={Lead_manage_Img} />
                  }
                >
                  {sub_menus.map((menu, key) => {
                    return (
                      <MenuItem key={key}>
                        <NavLink to={"/" + menu.url} exact>
                          {this.getMenuNameByLanguage(menu)}
                        </NavLink>
                      </MenuItem>
                    );
                  })}
                </SubMenu>
              )}

              <Nav.Item as="li">
                <NavLink to="/my-notification" exact>
                  <span className="pro-icon-wrapper">
                    <i className="fa fa-bell-o" aria-hidden="true" />
                  </span>
                  {language.notification}
                  {/* <i className="sb_count">5</i> */}
                </NavLink>
              </Nav.Item>

              {/* <Nav.Item as="li">
                <NavLink to="/mydocument" exact>
                  <span>
                    <img alt="HMC" src={Dashboard_icon8_img} />
                  </span>{" "}
                  My Documents
                </NavLink>
              </Nav.Item>
              <Nav.Item as="li">
                <NavLink to="/share-app" exact>
                  <span>
                    <img alt="HMC" src={Dashboard_icon9_img} />
                  </span>
                  Share App
                </NavLink>
              </Nav.Item> */}
            </Nav>
          </div>
        </div>
      </Col>
    );
  }
}
export default connect(mapStateToProps)(LeftPanelPage);
