import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Nav,
  Form,
  ProgressBar,
  FormControl,
  Dropdown,
  Select,
  Button,
} from "react-bootstrap";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import MachineProfile from "../../images/machin_profile.png";
import { NavLink } from "react-router-dom";
import Home from "../../images/home.png";
import MyPr from "../../images/my_pr.png";
import MachineList from "../../images/machine_list.png";
import MyQueries from "../../images/my_queries.png";
import BuyMachine from "../../images/buy_machine.png";
import SaleMachine from "../../images/sale_machine.png";
import MyParts from "../../images/my_parts.png";
import ProvSearch from "../../images/prov_search.png";
import ProvFilter from "../../images/prov_filter.png";
import MyServ from "../../images/my_serv.png";
import MyReport from "../../images/my_reports.png";
import MyAttachment from "../../images/my_attachment.png";
import MyMessage from "../../images/my_message.png";
import MyFeature from "../../images/my_feature.png";
import MySettings from "../../images/my_settings.png";
/**************sale images*******************/
import FilTer1 from "../../images/filter_1.png";
import MessageImg from "../../images/message_user_img.png";
import Phone1 from "../../images/phone_1.png";
import Phone2 from "../../images/phone_2.png";
import Phone3 from "../../images/phone_3.png";
import Phone4 from "../../images/phone_4.png";
import JpgUpload from "../../images/jpg_upload.png";
import Upload2 from "../../images/upload_2.png";
import Emoji from "../../images/emoji.png";
import Icon4 from "../../images/icon_4.png";
import MessageSendBut from "../../images/message_send_but.png";
/**************sale images*******************/
import AdAcnt from "../../images/add_acnt.png";
import "./chat.css";
import ExpertAdvice from "../../components/ExpertAdvice/ExpertAdvice";
// import Leftpanel from '../../components/leftpanel/leftpanel';

export default class ChatPage extends Component {
  render() {
    return (
      <div className="home">
        {/* <MachinaryStorePlus2 /> */}
        <FeaturePart3 />
        <ExpertAdvice />
      </div>
    );
  }
}

class MachinaryStorePlus2 extends Component {
  render() {
    return (
      <div className="machinary_store">
        <Container>
          <div className="machinary_streinner">
            <div className="machinary_streinner_left">
              <div className="profile_big_image">
                <img src={MachineProfile} />
              </div>
              <div className="prf_machine_text">
                <h2>Varun Kumar Ganguly</h2>
                <p>
                  <a href="mailto:vikashemail@gmail.com">
                    vikashemail@gmail.com
                  </a>
                </p>
                <div className="prf_completed">
                  Profile Completed <ProgressBar now={60} />
                  85
                </div>
              </div>
            </div>
            <div className="machinary_streinner_right">
              <div className="basic_details">
                <div className="details_partssb">
                  <i className="fa fa-info-circle"></i>
                  Basic Details
                </div>
                <div className="details_partssb">
                  <i className="fa fa-user"></i>
                  Name : V. K Karmakar
                </div>
                <div className="details_partssb">
                  <i className="fa fa-phone"></i>
                  Phone Number : 6548798774
                </div>

                <div className="details_partssb">
                  <i className="fa fa-map-marker"></i>
                  Belpkur more Haveli, Kolkata,
                  <br /> Westbengal
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

class FeaturePart3 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      city: null,
      show: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  handleInputChange(event) {
    this.setState({
      city: event.target.value,
    });
  }
  render() {
    return (
      <section className="feature_partts prf2_featured">
        <Container>
          <Row>
            {/* <Leftpanel panelName="chat"/> */}
            <Col lg="9" md="8" sm="12">
              <div className="my_listing_partts listing_partts_my">
                <div className="search_seller_rental">
                  <div className="prov_search my_listing_search">
                    <a href="#">
                      <img src={FilTer1} />
                    </a>
                    <Form.Group
                      className="prov_boxx"
                      controlId="formBasicEmail"
                    >
                      <div className="login_text_box">
                        <Form.Control
                          type="email"
                          className="prov_search_field"
                          placeholder="Search"
                        />
                        <div className="prov_img1">
                          <img src={ProvSearch} />
                        </div>
                      </div>
                    </Form.Group>
                    <a href="#">
                      <img src={ProvFilter} />
                    </a>
                  </div>
                  <div className="search_part_prov my_listing_prov">
                    <div className="sale_your_equipment">
                      <h2>Chat</h2>
                      <ul>
                        <li>
                          <a href="#">Messages</a>
                        </li>
                        <li>Chat</li>
                      </ul>
                    </div>
                    <div className="result_right my_listing_right">
                      <div className="add_acnt_view">
                        <a href="#">
                          {" "}
                          <span>
                            <img src={AdAcnt} /> Add Account
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chat_inner">
                  <div className="chat_inner_left">
                    <div className="recent_chat_partts">
                      <div className="chat_input_field">
                        <Form.Group
                          className="mb-3 marg_0"
                          controlId="formBasicEmail"
                        >
                          <Form.Control type="text" placeholder="Search..." />
                        </Form.Group>
                        <Button
                          variant="primary"
                          type="submit"
                          className="chat_search_but"
                        >
                          Search
                        </Button>
                      </div>
                      <div className="call_contacts">
                        <ul>
                          <li>
                            <a href="#">RECENT CHAT</a>
                          </li>
                          <li>
                            <a href="#">CALL</a>
                          </li>
                          <li>
                            <a href="#">CONTACTS</a>
                          </li>
                        </ul>
                        <div className="name_of_person">
                          <a className="inner_name active" href="#">
                            <div className="user_imageert">
                              <img src={MessageImg} />
                              <span className="img_count">1</span>
                            </div>
                            <div className="inner_name_of_person">
                              <h3>Name of Person</h3>
                              <p>Lorem Ipsum dummy text here Lorem text here</p>
                            </div>
                            <div className="inner_name_date">
                              <p>3 hours</p>
                            </div>
                          </a>
                          <a className="inner_name" href="#">
                            <div className="user_imageert">
                              <img src={MessageImg} />
                              <span className="img_count">1</span>
                            </div>
                            <div className="inner_name_of_person">
                              <h3>Name of Person</h3>
                              <p>Lorem Ipsum dummy text here Lorem text here</p>
                            </div>
                            <div className="inner_name_date">
                              <p>3 hours</p>
                            </div>
                          </a>
                          <a className="inner_name" href="#">
                            <div className="user_imageert">
                              <img src={MessageImg} />
                              <span className="img_count">1</span>
                            </div>
                            <div className="inner_name_of_person">
                              <h3>Name of Person</h3>
                              <p>Lorem Ipsum dummy text here Lorem text here</p>
                            </div>
                            <div className="inner_name_date">
                              <p>3 hours</p>
                            </div>
                          </a>
                          <a className="inner_name" href="#">
                            <div className="user_imageert">
                              <img src={MessageImg} />
                              {/* <span className='img_count'>1</span> */}
                            </div>
                            <div className="inner_name_of_person">
                              <h3>Name of Person</h3>
                              <p>Lorem Ipsum dummy text here Lorem text here</p>
                            </div>
                            <div className="inner_name_date">
                              <p>3 hours</p>
                            </div>
                          </a>
                          <a className="inner_name" href="#">
                            <div className="user_imageert">
                              <img src={MessageImg} />
                              {/* <span className='img_count'>1</span> */}
                            </div>
                            <div className="inner_name_of_person">
                              <h3>Name of Person</h3>
                              <p>Lorem Ipsum dummy text here Lorem text here</p>
                            </div>
                            <div className="inner_name_date">
                              <p>3 hours</p>
                            </div>
                          </a>
                          <a className="inner_name" href="#">
                            <div className="user_imageert">
                              <img src={MessageImg} />
                              {/* <span className='img_count'>1</span> */}
                            </div>
                            <div className="inner_name_of_person">
                              <h3>Name of Person</h3>
                              <p>Lorem Ipsum dummy text here Lorem text here</p>
                            </div>
                            <div className="inner_name_date">
                              <p>3 hours</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="chat_inner_right">
                    <div className="chat_inner_top_partts">
                      <div className="online_person_left">
                        <div className="online_person_image">
                          <img src={MessageImg} />
                        </div>
                        <div className="online_person_text">
                          <h3>Name of Person</h3>
                          <p>
                            <span></span> Online
                          </p>
                        </div>
                      </div>
                      <div className="online_person_right">
                        <ul>
                          <li>
                            <a href="#">
                              <img src={Phone1} />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <img src={Phone2} />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <img src={Phone3} />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <img src={Phone4} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="pers_chat_box">
                      <div className="person_chat">
                        <div className="days_ago">
                          <span>3 days ago</span>
                        </div>
                        <div className="inner_person_chat">
                          <div className="inner_p_chat_side">
                            <div className="inner_p_chat_left">
                              <img src={MessageImg} />
                            </div>
                            <div className="inner_p_chat_right">
                              <div className="inner_p_chat_right_text">
                                Lorem Ipsum is simply dummy text of the printing
                              </div>
                              <div className="inner_p_chat_right_text riext1213q">
                                Lorem Ipsum is simply
                              </div>
                              <div className="inner_p_chat_time">
                                <span>9:32 am</span>
                              </div>
                            </div>
                          </div>
                          <div className="inner_p_chat_other_side">
                            <div className="inner_p_chat_right">
                              <div className="inner_p_chat_right_text">
                                Lorem Ipsum is simply dummy
                              </div>
                              <div className="inner_p_chat_right_text text11q2">
                                Lorem Ipsum is simply dummy
                              </div>
                              <div className="inner_p_chat_time">
                                <span>9:32 am</span>
                              </div>
                            </div>
                            <div className="inner_p_chat_left">
                              <img src={MessageImg} />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="person_chat">
                        <div className="days_ago">
                          <span>3 days ago</span>
                        </div>
                        <div className="inner_person_chat">
                          <div className="inner_p_chat_side">
                            <div className="inner_p_chat_left">
                              <img src={MessageImg} />
                            </div>
                            <div className="inner_p_chat_right">
                              <div className="inner_p_chat_right_text">
                                Lorem Ipsum is simply dummy text of the printing
                              </div>
                              <div className="inner_p_chat_time">
                                <span>9:32 am</span>
                              </div>
                            </div>
                          </div>
                          <div className="inner_p_chat_other_side">
                            <div className="inner_p_chat_right">
                              <div className="inner_p_chat_right_text">
                                Lorem Ipsum is simply dummy
                              </div>
                              <div className="inner_p_chat_right_text text11q2">
                                Lorem Ipsum is simply dummy
                              </div>
                              <div className="inner_p_chat_time">
                                <span>9:32 am</span>
                              </div>
                            </div>
                            <div className="inner_p_chat_left">
                              <img src={MessageImg} />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="person_chat">
                        <div className="days_ago">
                          <span>3 days ago</span>
                        </div>
                        <div className="inner_person_chat">
                          <div className="inner_p_chat_side">
                            <div className="inner_p_chat_left">
                              <img src={MessageImg} />
                            </div>
                            <div className="inner_p_chat_right">
                              <div className="inner_p_chat_right_text">
                                Lorem Ipsum is simply dummy text of the printing
                              </div>
                              <div className="inner_p_chat_time">
                                <span>9:32 am</span>
                              </div>
                            </div>
                          </div>
                          <div className="inner_p_chat_other_side">
                            <div className="inner_p_chat_right">
                              <div className="inner_p_chat_right_text">
                                Lorem Ipsum is simply dummy
                              </div>
                              <div className="inner_p_chat_right_text text11q2">
                                Lorem Ipsum is simply dummy
                              </div>
                              <div className="inner_p_chat_time">
                                <span>9:32 am</span>
                              </div>
                            </div>
                            <div className="inner_p_chat_left">
                              <img src={MessageImg} />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="person_chat">
                        <div className="days_ago">
                          <span>3 days ago</span>
                        </div>
                        <div className="inner_person_chat">
                          <div className="inner_p_chat_side">
                            <div className="inner_p_chat_left">
                              <img src={MessageImg} />
                            </div>
                            <div className="inner_p_chat_right">
                              <div className="inner_p_chat_right_text">
                                Lorem Ipsum is simply dummy text of the printing
                              </div>
                              <div className="inner_p_chat_time">
                                <span>9:32 am</span>
                              </div>
                            </div>
                          </div>
                          <div className="inner_p_chat_other_side">
                            <div className="inner_p_chat_right">
                              <div className="inner_p_chat_right_text">
                                Lorem Ipsum is simply dummy
                              </div>
                              <div className="inner_p_chat_right_text text11q2">
                                Lorem Ipsum is simply dummy
                              </div>
                              <div className="inner_p_chat_time">
                                <span>9:32 am</span>
                              </div>
                            </div>
                            <div className="inner_p_chat_left">
                              <img src={MessageImg} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="type_your_mesage_box">
                      <div className="message_left_icon">
                        <ul>
                          <li>
                            <a href="#">
                              <div className="flex_button flex1">
                                <div className="custom_file">
                                  <input
                                    type="file"
                                    onChange={this.handleImageChange}
                                    className="custom-file-input"
                                    id="inputGroupFile01"
                                    aria-describedby="inputGroupFileAddon01"
                                  />
                                  {/* <label
                                                    className="custom-file-label"
                                                    htmlFor="inputGroupFile01"
                                                    >
                                                    Change Picture
                                                    </label> */}
                                </div>
                                <div className="file_upload_button">
                                  <img src={JpgUpload} />
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <div className="flex_button flex1">
                                <div className="custom_file">
                                  <input
                                    type="file"
                                    onChange={this.handleImageChange}
                                    className="custom-file-input"
                                    id="inputGroupFile01"
                                    aria-describedby="inputGroupFileAddon01"
                                  />
                                  {/* <label
                                                    className="custom-file-label"
                                                    htmlFor="inputGroupFile01"
                                                    >
                                                    Change Picture
                                                    </label> */}
                                </div>
                                <div className="file_upload_button">
                                  <img src={Upload2} />
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <img src={Emoji} />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <img src={Icon4} />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="message_box_inner">
                        <Form.Control
                          type="text"
                          placeholder="Type your message.."
                          name="first_name"
                          className="mtext_box"
                        />
                        <div className="send_m_but">
                          <img src={MessageSendBut} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
