import React, { Component } from "react";
import { Container, Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import LeftPanelPage from "../../../components/leftpanel/LeftPanelPage";
import {
  hmc_user_onboard,
  Setting_Filter_Img,
  Lead_Arrow_Img,
  Three_Dots,
  hmc_user_not_onboard,
  not_hmc_user,
  image_preview,
  word_preview,
  pdf_preview,
  Plus_Black,
  Upload_img_icon_img,
  cross_preview,
  Up_doc_icon_img,
  excel_preview,
} from "../../../assets/images";
import AdvanceFilter from "../AdvanceFilter";
import axios from "../../../config/axios";
import message from "../../../utils/messages";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import _ from "lodash";
import CreatableSelect from "react-select/creatable";
import CustomPopup from "../../../utils/CustomPopup";
import ReactPaginate from "react-paginate";
import ReactDatePicker from "react-datepicker";
import config from "../../../config/apiHeader";
import AutoCompleteLocation from "../../../utils/AutoCompleteLocation";
import StatusChanges from "../../../utils/StatusChanges";
import DeletePopup from "../../../utils/DeletePopup";
import SetRemainder from "../../../utils/SetRemainder";
import { MEDIA_BASE_URL, getFileExtension } from "../../../utils/Helper";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class MyArchiveLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      totalPage: 0,
      per_page: 10,
      currentPage: 1,
      totalItems: 0,
      toggleEvent: false,
      clickedPosition: -1,

      my_assigned_lead: [],
      collapsedArray: [],
      all_tags: [],
      state_list: [],
      financer_list: [],
      contact_person_list: [],

      // ADVANCE FILTER
      isFiltered: false,
      customer_name: "",
      customer_phone: "",
      start_date: "",
      end_date: "",
      temp_lead_id: "",
      luid: [],
      customer_address_state: "",
      customer_address_city: "",
      euipment_category: "",
      temp_euipment_name: "",
      euipment_name: [],
      tags_id: [],
      selected_tags: [],
      finance_type: "",
      creator_id: "",
      status: "",
      sub_status: "",
      lead_type: "",

      // UPDATE STATUS
      lead_header: [],
      get_sub_header: [],
      show: false,
      contact_list: [],
      dashboard_id: "",

      selected_status_tags: [],
      subject: "",
      subject_id: "",
      sub_header_id: "",
      sub_header_title: "",
      assignee_name: "",
      assignee_phone: "",
      assign_to: "",
      notify_by: [],
      comments: "",
      tags: [],
      docs_label: "",
      lead_assignee_activity_id: "",
      date: "",
      financer_name: "",
      financer_contact_id: "",
      contact_person: "",
      contact_person_id: "",
      disbrusment_sub_status: "",

      heading_type: 0, // 0 -> default, 1 -> loged in, 2 -> disbrusment, 3 -> closed, 4 -> dead

      // UPLOAD DOCUMENT
      attachment_type: "",
      attachment_title: "",
      lead_assignee_attachment: [],
      relative_type: "",
      error: {},

      // ADD DAILY REPORT
      lead_assigne_activity_id: "",
      heading: "",
      travel_date: "",
      start_location: "",
      isStartLocation: false,
      end_location: "",
      isEndLocation: false,
      approx_km: "",
      travel_mode: "",
      travel_cost: "",
      accomodation: "",
      note: "",
      accomodation_cost: "",
      comunication_type: "",
      add_daily_report: false,

      // DAILY REPORT DOC
      lead_report_attachment: [],
      daily_report_attachment_type: [],
      daily_report_attachment_title: [],

      doc_details: [],
      previous_docs: [],

      // DOC VIEWER
      isDocViewer: false,
      doc_viewer: [],

      // COMMITION PAID
      isCommitionPaid: false,

      // SET REMAINDER
      isSetRemainder: false,
      lead_hash_id: "",

      // ADD DOCUMENT AND IMAGE
      isAddedDocument: false,
      lead_id: "",
      activity_id: "",
      equipment_images: [],
      old_images: [],
      old_attachment: [],
      equipment_docs: [],
      equipment_docs_preview: [],
      attachment_title: [],
      old_docs: [],

      lead_assignee_hash_id: "",
      selected_financer: {},
      selected_header_id: -1,

      // ARCHIVE LEAD
      isUnArchivedLead: false,
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }

    let state = this.props.location.state;
    if (state) {
      await this.setState({ currentPage: state.currentPage });
    }

    let sub_menus = await JSON.parse(localStorage.getItem("sub_menus"));
    if (sub_menus) {
      let { pathname } = this.props.history.location;
      pathname = pathname.substring(1);

      let res = _.find(sub_menus, (o) => {
        return o.url === pathname;
      });
      if (res) {
        await this.setState({ dashboard_id: res.dashboard_id });
        this.getAllLeads();
        this.getAllTagList();
        this.getAllStateList();
        this.getLeadHeaderList();
        this.getContactList();
        this.getFinancerList();
        this.getContactPersonList();
      } else {
        message.error(
          "Access Denied: Your account does not have sufficient permissions to perform the requested action."
        );
        this.props.history.push({
          pathname: "/",
        });
      }
    } else {
      message.error(
        "Access Denied: Your account does not have sufficient permissions to perform the requested action."
      );
      this.props.history.push({
        pathname: "/",
      });
    }
  };

  getAllLeads = () => {
    this.setState({ isLoading: true });

    let {
      hash_id,
      currentPage,
      per_page,
      start_date,
      end_date,
      customer_name,
      customer_phone,
      customer_address_city,
      customer_address_state,
      euipment_category,
      tags_id,
      finance_type,
      creator_id,
      assignee_name,
      status,
      sub_status,
      lead_type,
    } = this.state;

    const formData = {
      hash_id,
      per_page,
      page: currentPage,
      medium: "web",
      section_name: "lm_get_all_assigned_leads",

      start_date,
      end_date,
      customer_name,
      customer_phone,
      luid: this.state.luid.toString(),
      customer_address_city,
      customer_address_state,
      euipment_category,
      euipment_name: this.state.euipment_name.toString(),
      tags_id: tags_id.toString(),
      finance_type,
      creator_id,
      assignee_name,
      status,
      sub_status,
      lead_type,
    };

    axios
      .post("/get-my-archived-lead", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { my_assigned_lead, last_page, total } = res.data.data;
          let size = my_assigned_lead.length;
          let collapsedArray = _.fill(Array(size), this.state.isFiltered);
          this.setState({
            my_assigned_lead,
            totalPage: last_page,
            collapsedArray,
            totalItems: total,
          });
          if (total === 0) {
            message.warning("No Lead Found!!");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  getAllTagList = () => {
    let { hash_id } = this.state;

    const formData = {
      hash_id,
      medium: "web",
      section_name: "lm_get_all_tags",
    };

    axios
      .post("/all-tag-list-status-wise", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let all_tags = [];
          res.data.data.map((tag) => {
            return all_tags.push({
              label: tag.tag_title,
              value: tag.id,
              color: tag.tag_back_color,
              text_color: tag.tag_title_color,
            });
          });
          this.setState({ all_tags });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getAllStateList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_get_all_state",
    };

    axios
      .post("/state-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let { state_list } = res.data.data;

          this.setState({ state_list });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getLeadHeaderList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_assigned_get_all_header_list",
    };

    axios
      .post("/lead-header-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let lead_header = [];
          res.data.data.lead_header.forEach((header) => {
            // VERYFIED LEAD NOT OPEN IN MARKETING PERSON
            if (localStorage.getItem("lead_user_role") !== "6") {
              lead_header.push({
                label: header.header_label,
                value: header.id,
                header,
              });
            } else if (header.value !== 4) {
              lead_header.push({
                label: header.header_label,
                value: header.id,
                header,
              });
            }
          });
          this.setState({ lead_header });
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getContactList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_assigned_get_contact_list",
    };

    axios
      .post("/contact-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let contact_list = res.data.data.map((contact) => {
            return {
              label:
                contact.contact_type === "1"
                  ? contact.first_name + " " + contact.last_name
                  : contact.company_name,
              value: contact.user_id,
              contact,
            };
          });
          this.setState({ contact_list });
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getFinancerList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_all_lead_get_financer_list",
    };

    axios
      .post("/get-financer-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          this.setState({ financer_list: res.data.data });
        }
      })
      .catch((err) => {
        console.error(err);
        // message.error("Something went wrong!!");
      });
  };

  getContactPersonList = () => {
    const formData = {
      medium: "web",
      section_name: "lm_all_lead_get_contact_person",
    };
    axios
      .post("/get-contact-person-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          this.setState({ contact_person_list: res.data.data });
        }
      })
      .catch((err) => {
        console.error(err);
        // message.error("Something went wrong!!");
      });
  };

  handleOnChange = async (e) => {
    e.persist();
    let { name, value } = e.target;

    if (name === "attachment_type") {
      let files = e.target.files;
      let lead_assignee_attachment = [];
      let attachment_title;
      let attachment_type = "2";

      Object.keys(files).forEach(async (key) => {
        let file = files[key];
        let validExtension = ["png", "jpg", "jpeg", "doc", "docx", "pdf"];
        if (file !== undefined) {
          let extension = this.getExtension(file).toLowerCase();
          if (
            extension !== undefined &&
            _.findIndex(validExtension, (exe) => {
              return exe === extension;
            }) !== -1
          ) {
            let imageExe = ["png", "jpg", "jpeg"];
            if (
              _.findIndex(imageExe, (exe) => {
                return exe === extension;
              }) !== -1
            ) {
              attachment_type = "1";
            }
            lead_assignee_attachment.push(file);
            attachment_title = file.name;
          } else {
            message.error(file.name + " is not a valid document");
          }
        }
        await this.setState({
          lead_assignee_attachment,
          attachment_title,
          attachment_type,
        });
      });
      return;
    }

    if (name === "sub_header_id") {
      let { error } = this.state;
      if (value) {
        error.sub_header_id = "";
        let sub_header = this.state.get_sub_header[value];
        await this.setState({
          sub_header_id: sub_header.id,
          sub_header_title: sub_header.sub_header_label,
          error,
        });
      } else {
        await this.setState({ sub_header_id: "", sub_header_title: "" });
      }
      return;
    }

    if (name === "financer_contact_id") {
      let { financer_name, financer_contact_id } = this.state;

      const financer = this.state.financer_list[value];

      financer_name = financer.company_name;
      financer_contact_id = financer.id;
      await this.setState({ financer_name, financer_contact_id });
      return;
    }

    if (name === "contact_person_id") {
      let { contact_person, contact_person_id } = this.state;

      const person = this.state.contact_person_list[value];
      contact_person = person.first_name + " " + person.last_name;
      contact_person_id = person.id;

      await this.setState({ contact_person, contact_person_id });
      return;
    }

    await this.setState({ [name]: value });
    if (name === "per_page") this.getAllLeads();
  };

  getExtension = (file) => {
    let regex = new RegExp("[^.]+$");
    return file.name.match(regex)[0];
  };

  removeLuid = (index) => {
    let { luid } = this.state;
    luid.splice(index, 1);
    this.setState({ luid });
  };

  removeEquipment = (index) => {
    let { euipment_name } = this.state;
    euipment_name.splice(index, 1);
    this.setState({ euipment_name });
  };

  onToggle = () => {
    this.setState({ toggleEvent: !this.state.toggleEvent });
  };

  tagChanges = (value) => {
    let tags_id = [];
    let selected_tags = [];
    value.map((tag) => {
      return [tags_id.push(tag.value), selected_tags.push(tag)];
    });

    this.setState({ tags_id, selected_tags });
  };

  resetFilter = async () => {
    await this.setState({
      tags_id: [],
      luid: [],
      temp_lead_id: "",
      customer_name: "",
      customer_address_state: "",
      customer_address_city: "",
      euipment_name: [],
      temp_euipment_name: "",
      euipment_category: "",
      start_date: "",
      end_date: "",
      selected_tags: [],
      isFiltered: false,
      toggleEvent: false,
      finance_type: "",
      creator_id: "",
      assignee_name: "",
      status: "",
      sub_status: "",
    });
    this.getAllLeads();
  };

  setKeyWithValue = async (key, value) => {
    await this.setState({ [key]: value });
  };

  singleSelect = (key, value) => {
    this.setState({ [key]: value.value });
  };

  onAdvanceFilterClick = async () => {
    let { luid, temp_lead_id, euipment_name, temp_euipment_name } = this.state;

    await this.setState({
      isFiltered: true,
      totalPage: 0,
      per_page: 10,
      currentPage: 1,
    });
    if (temp_lead_id) {
      luid.push(temp_lead_id);
      await this.setState({ temp_lead_id: "", luid });
    }
    if (temp_euipment_name) {
      euipment_name.push(temp_euipment_name);
      await this.setState({ temp_euipment_name: "", euipment_name });
    }

    this.getAllLeads();
  };

  updateStatus = (data) => {
    let {
      subject,

      assignee_name,
      assignee_phone,
      assign_to,
      notify_by,
      comments,
      docs_label,
      tags,
      subject_id,
      sub_header_id,
      sub_header_title,
      assigne_contact_id,
      financer_name,
      financer_contact_id,
      contact_person,
      contact_person_id,
      disbrusment_sub_status,
      date,
      asking_amount,
      disbrust_amount,
      approved_amount,
      down_payment,
      brokerage_rate,
      loan_interest,
      cloasing_date,
      appac_no,
      hmc_percentage,
      asset_value,
    } = data;

    const formData = {
      subject,
      subject_id,
      user_hash_id: this.state.hash_id,
      lead_id: this.state.lead_id,
      assignee_name,
      assignee_phone,
      assign_to,
      notify_by: notify_by.toString(),
      comments,
      docs_label,
      tags,
      dashboard_id: this.props.dashboard_id,
      lead_assignee_activity_id: this.state.lead_assignee_activity_id,

      medium: "web",
      section_name: "lm_assigned_update_status",
      call_from: "my_assigned_list",

      sub_header_id,
      sub_header_title,
      assigne_contact_id,
      financer_name,
      financer_contact_id,
      contact_person,
      contact_person_id,
      disbrusment_sub_status,
      date,
      asking_amount,
      disbrust_amount,
      approved_amount,
      down_payment,
      brokerage_rate,
      loan_interest,
      cloasing_date,
      appac_no,
      hmc_percentage,
      asset_value,
    };

    this.setState({ show: false, isLoading: true });

    this.apiCall(formData, config);
  };

  handleSelectData = async (value, key) => {
    if (value) {
      await this.setState({ [key]: value.label, date: "" });
      let { error } = this.state;
      if (key === "subject") {
        error.subject = "";
        if (value.__isNew__) {
          await this.setState({
            subject_id: "",
            error,
            get_sub_header: [],
            heading_type: 0,
          });
        } else {
          // FOR LOGGED IN
          if (value.value === 6) {
            await this.setState({ heading_type: 1 });
            return;
          }
          //    FOR Disbrusment
          if (value.value === 7) {
            await this.setState({ heading_type: 2 });
            return;
          }

          //    FOR CLOSED
          if (value.value === 8) {
            await this.setState({ heading_type: 3 });
            return;
          }

          //    FOR CLOSED
          if (value.value === 9) {
            await this.setState({ heading_type: 4 });
            return;
          }

          let get_sub_header = value.header.get_sub_header;
          await this.setState({
            subject_id: value.value,
            error,
            get_sub_header,
            heading_type: 0,
          });
        }
      }
    } else {
      await this.setState({
        subject_id: "",
        get_sub_header: [],
        heading_type: 0,
        financer_name: "",
        financer_contact_id: "",
        contact_person: "",
        contact_person_id: "",
        disbrusment_sub_status: "",
        date: "",
      });
    }
  };

  onPageChange = async (val) => {
    let currentPage = val.selected + 1;
    await this.setState({ currentPage });
    this.getAllLeads();
  };

  closePopup = async (show) => {
    await this.setState({
      show,
      assign_to: "",
      assignee_name: "",
      assignee_phone: "",
      comments: "",
      docs_label: "",
      lead_assignee_activity_id: "",
      lead_id: "",
      notify_by: [],
      subject: "",
      subject_id: "",
      tags: [],
      selected_status_tags: [],
      attachment_type: "",
      attachment_title: "",
      lead_assignee_attachment: [],
      relative_type: "",
      error: {},

      get_sub_header: [],
      sub_header_id: "",
      sub_header_title: "",

      heading_type: 0,

      date: "",
      financer_name: "",
      financer_contact_id: "",
      contact_person: "",
      contact_person_id: "",
      disbrusment_sub_status: "",
      lead_hash_id: "",
      lead_assignee_hash_id: "",
      selected_financer: {},
      selected_header_id: -1,
    });
  };

  handleChange = async (e) => {
    e.persist();
    let { name, value } = e.target;

    if (
      name === "approx_km" ||
      name === "travel_cost" ||
      name === "accomodation_cost"
    ) {
      await this.setState({ [name]: value.replace(/\D/g, "") });
      return;
    }

    await this.setState({ [name]: value });
  };

  handleGetLocation = (key, value) => {
    if (value) {
      this.setState({ [key]: value.label });
      if (key === "start_location") {
        this.setState({ isStartLocation: false });
        return;
      }
      if (key === "end_location") {
        this.setState({ isEndLocation: false });
        return;
      }
    }
  };

  addDailyReport = (e) => {
    e.preventDefault();

    let {
      hash_id,
      lead_id,
      lead_assigne_activity_id,
      heading,
      travel_date,
      start_location,
      end_location,
      approx_km,
      travel_mode,
      travel_cost,
      accomodation,
      note,

      accomodation_cost,
      comunication_type,
    } = this.state;

    const formData = {
      lead_id,
      lead_assigne_activity_id,
      heading,
      travel_date,
      start_location,
      end_location,
      approx_km,
      travel_mode,
      travel_cost,
      accomodation,
      note,
      hash_id,
      accomodation_cost,
      comunication_type,

      medium: "web",
      section_name: "lm_add_daily_report",
    };

    this.setState({ add_daily_report: false, isLoading: true });

    axios
      .post("/add-daily-report", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          this.uploadDailyReportDocument(res.data.data.daily_report_id);
          message.success(res.data.message);
        } else {
          this.setState({ isLoading: false });
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        this.closeDailyReportPopup(false);
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  handleImages = async (e) => {
    e.persist();

    let {
      daily_report_attachment_title,
      daily_report_attachment_type,
      lead_report_attachment,
      doc_details,
    } = this.state;

    let validExtension = ["png", "jpg", "jpeg", "doc", "docx", "pdf"];
    let { files } = e.target;
    let file;

    for (let i = 0; i < files.length; i++) {
      file = files[i];

      if (file) {
        let extension = this.getExtension(file).toLowerCase();
        if (
          extension !== undefined &&
          _.findIndex(validExtension, (exe) => {
            return exe === extension;
          }) !== -1
        ) {
          lead_report_attachment.push(file);
          daily_report_attachment_title.push(file.name);
          if (file.type.split("/")[0] === "image") {
            daily_report_attachment_type.push(1);
          } else {
            daily_report_attachment_type.push(2);
          }
          doc_details.push({
            name: file.name,
            extension,
          });
        }
      }
    }

    this.setState({
      daily_report_attachment_title,
      daily_report_attachment_type,
      lead_report_attachment,
      doc_details,
    });
  };

  uploadDailyReportDocument = (lead_daily_report_id) => {
    let {
      daily_report_attachment_title,
      daily_report_attachment_type,
      lead_report_attachment,
      old_attachment,
    } = this.state;

    let formData = new FormData();
    formData.append("lead_daily_report_id", lead_daily_report_id);

    formData.append("medium", "web");
    formData.append("section_name", "lm_daily_report_doc_upload");

    old_attachment.map((id, key) => {
      formData.append(`old_attachment[${key}]`, id);
    });

    daily_report_attachment_title.forEach((id, key) => {
      formData.append(`attachment_title[${key}]`, id);
    });

    daily_report_attachment_type.forEach((id, key) => {
      formData.append(`attachment_type[${key}]`, id);
    });

    lead_report_attachment.forEach((id, key) => {
      formData.append(`lead_report_attachment[${key}]`, id);
    });

    axios
      .post("/lead-daily-report-document", formData, config)
      .then((res) => {
        this.closeDailyReportPopup(false);
        this.getAllLeads();
      })
      .catch((err) => {
        this.closeDailyReportPopup(false);
        console.error(err);
        this.getAllLeads();
        message.error("Something went wrong!!");
      });
  };

  openDocViewer = async (docs) => {
    let { doc_viewer } = this.state;
    doc_viewer = docs.map((doc) => {
      return {
        name: doc.attachment_title,
        extension: doc.attachment_title.split(".")[1].toLowerCase(),
        url: doc.attachment_url,
      };
    });

    await this.setState({ doc_viewer, isDocViewer: true });
  };

  closeDailyReportPopup = async (add_daily_report) => {
    await this.setState({
      add_daily_report,
      lead_assigne_activity_id: "",
      heading: "",
      travel_date: "",
      start_location: "",
      isStartLocation: false,
      end_location: "",
      isEndLocation: false,
      approx_km: "",
      travel_mode: "",
      travel_cost: "",
      accomodation: "",
      note: "",
      accomodation_cost: "",
      comunication_type: "",

      // DAILY REPORT DOC
      lead_report_attachment: [],
      daily_report_attachment_type: [],
      daily_report_attachment_title: [],
      old_attachment: [],

      doc_details: [],
      previous_docs: [],

      // DOC VIEWER
      isDocViewer: false,
      doc_viewer: [],
    });
  };

  commisionPaid = () => {
    let {
      hash_id,
      assignee_name,
      assignee_phone,
      assign_to,
      comments,

      assigne_contact_id,
      lead_id,
      lead_assignee_activity_id,
    } = this.state;

    const formData = {
      user_hash_id: hash_id,
      lead_id,
      assignee_name,
      assignee_phone,
      assign_to,
      notify_by: "",
      comments,

      dashboard_id: this.props.dashboard_id,
      lead_assignee_activity_id,

      medium: "web",
      section_name: "lm_assigned_update_status",
      call_from: "my_assigned_list",

      assigne_contact_id,
      subject: "Commision Paid",
    };

    this.setState({
      isLoading: true,
      lead_id: "",
      lead_assignee_activity_id: "",
    });

    this.apiCall(formData, config);
  };

  apiCall = (formData, config) => {
    axios
      .post("/update-change-status", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          this.getAllLeads();

          message.success(res.data.message);
          this.closePopup(false);
        } else {
          this.setState({ isLoading: false });
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  setRemainder = (data) => {
    let { hash_id, lead_hash_id } = this.state;
    let {
      meeting_time,
      meeting_date,
      meeting_label,
      meeting_description,
      to_contact_id,
    } = data;

    const formData = {
      lead_hash_id,
      meeting_time,
      meeting_date,
      meeting_label,
      meeting_description,
      to_contact_id,
      from_contact_id: hash_id,
      medium: "web",
      section_name: "lm_assign_set_remainder",
    };

    this.setState({ isLoading: true, isSetRemainder: false, lead_hash_id: "" });

    axios
      .post("/add-lead-reminder", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });

        if (res.data.status === "1") {
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  closeAddDocumentPopup = (isAddedDocument) => {
    this.setState({
      isAddedDocument,
      lead_id: "",
      activity_id: "",
      equipment_images: [],
      old_images: [],
      old_attachment: [],
      equipment_docs: [],
      equipment_docs_preview: [],
      attachment_title: [],
      old_docs: [],
      isLoading: false,
    });
  };

  handleDocument = async (e) => {
    e.persist();

    let { equipment_docs, equipment_docs_preview, attachment_title } =
      this.state;
    let files = e.target.files;

    let validExtension = ["doc", "docx", "pdf", "xls", "xlsx"];

    Object.keys(files).forEach(async (key) => {
      let file = files[key];
      if (file !== undefined) {
        let extension = getFileExtension(file);

        if (
          extension !== undefined &&
          _.findIndex(validExtension, (exe) => {
            return exe === extension;
          }) !== -1
        ) {
          let preview;
          if (extension === "docx" || extension === "doc") {
            preview = word_preview;
          } else if (extension === "pdf") {
            preview = pdf_preview;
          } else {
            preview = excel_preview;
          }
          equipment_docs.push(file);

          equipment_docs_preview.push({ name: file.name, preview });
          attachment_title.push(file.name);

          this.setState({
            equipment_docs,
            equipment_docs_preview,
          });
        } else {
          message.error(file.name + " is not a valid document");
        }
      }
    });
  };

  handleDocImages = async (e) => {
    e.persist();

    let { equipment_images } = this.state;

    let files = e.target.files;
    let validExtension = ["png", "jpg", "jpeg"];
    Object.keys(files).forEach(async (key) => {
      let file = files[key];
      if (file !== undefined) {
        let extension = this.getExtension(file).toLowerCase();
        if (
          extension !== undefined &&
          _.findIndex(validExtension, (exe) => {
            return exe === extension;
          }) !== -1
        ) {
          equipment_images.push(file);
        } else {
          message.error(file.name + " is not a valid image");
        }
      }
      await this.setState({ equipment_images });
    });
  };

  leadDocumentUpload = () => {
    this.setState({ isAddedDocument: false });
    let {
      lead_id,
      equipment_docs,
      attachment_title,
      old_attachment,
      activity_id,
      hash_id,
    } = this.state;

    let formData = new FormData();
    formData.append("lead_id", lead_id);
    formData.append("lead_assignee_act_id", activity_id);
    old_attachment.map((id, key) => {
      formData.append(`old_attachment[${key}]`, id);
    });
    attachment_title.map((title, key) => {
      formData.append(`attachment_title[${key}]`, title);
    });
    formData.append("attachment_type", 2);
    formData.append("medium", "web");
    formData.append("section_name", "lm_pre_assign_doc_upload");
    formData.append("hash_id", hash_id);

    equipment_docs.map((file, index) => {
      formData.append(`lead_attachment[${index}]`, file);
    });

    this.setState({ isLoading: true });

    axios
      .post("/lead-document-upload", formData, config)
      .then((res) => {
        this.leadImageUpload();

        if (res.data.status === "1") {
          message.success("Document Upload Successfully");

          this.setState({
            equipment_docs: [],
            equipment_docs_preview: [],
            attachment_title: [],
            old_docs: [],
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  leadImageUpload = () => {
    let { equipment_images, lead_id, old_attachment, activity_id, hash_id } =
      this.state;

    let formData = new FormData();
    formData.append("lead_id", lead_id);
    formData.append("lead_assignee_act_id", activity_id);
    formData.append("attachment_title", []);
    formData.append("attachment_type", 1);
    formData.append("medium", "web");
    formData.append("section_name", "lm_pre_assign_doc_upload");
    formData.append("hash_id", hash_id);

    old_attachment.map((id, key) => {
      formData.append(`old_attachment[${key}]`, id);
    });

    equipment_images.map((file, index) => {
      formData.append(`lead_attachment[${index}]`, file);
    });

    axios
      .post("/lead-document-upload", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          message.success("Image Upload Successfully");
          this.closeAddDocumentPopup(false);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });

        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getAllDocumentsLeadWise = (lead_hash_id, lead_assignee_hash_id) => {
    this.setState({ isLoading: true });
    const formData = {
      lead_hash_id,
      lead_assignee_hash_id,
      medium: "web",
      section_name: "lm_pre_assign_get_all_doc",
      call_from: "my_assigned_list",
    };
    axios
      .post("/get-lead-all-document", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let old_attachment = [];
          let old_images = [];
          let old_docs = [];
          res.data.data.forEach((doc) => {
            old_attachment.push(doc.id);
            if (doc.attachment_type === "1") {
              old_images.push({
                url: doc.attachment_url,
                id: doc.id,
              });
            } else {
              let url = doc.attachment_url;
              let preview;

              if (url.endsWith(".doc") || url.endsWith(".docx")) {
                preview = word_preview;
              } else if (url.endsWith(".pdf")) {
                preview = pdf_preview;
              } else {
                preview = excel_preview;
              }

              old_docs.push({
                url,
                id: doc.id,
                name: doc.attachment_title,
                preview,
              });
            }
          });

          this.setState({ old_attachment, old_images, old_docs });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });

        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  deleteOldImage = async (id, pos) => {
    let { old_attachment, old_images } = this.state;
    old_images.splice(pos, 1);

    let index = old_attachment.indexOf(id);
    old_attachment.splice(index, 1);

    await this.setState({ old_images, old_attachment });
  };

  deleteImage = async (pos) => {
    let { equipment_images } = this.state;
    equipment_images.splice(pos, 1);
    await this.setState({ equipment_images });
  };

  getSelectedFinancer = () => {
    let { lead_hash_id, lead_assignee_hash_id } = this.state;
    const formData = {
      lead_hash_id,
      lead_assignee_hash_id,
      call_from: "my_assigned_list",
    };
    this.setState({ isLoading: true });
    axios
      .post("/get-selected-financer-name", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });

        if (res.data.status === "1") {
          let { financer_details } = res.data.data;

          let selected_financer = _.findIndex(this.props.financerList, (o) => {
            return o.id === financer_details.financer_contact_id;
          });

          this.setState({
            selected_financer: {
              position: selected_financer,
              financer_contact_id: financer_details.financer_contact_id,
              financer_name: financer_details.financer_name,
            },
          });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });

        console.error(err);
      });
  };

  unArchiveLead = () => {
    let { hash_id, lead_hash_id, lead_assignee_hash_id } = this.state;

    const formData = {
      user_hash_id: hash_id,
      lead_hash_id,
      lead_assignee_hash_id,

      medium: "web",
      section_name: "lm_my_archive_lead",
    };

    this.setState({ isLoading: true });

    axios
      .post("/unarchive-lead-update", formData, config)
      .then((res) => {
        this.closePopup(false);
        if (res.data.status === "1") {
          this.getAllLeads();

          message.success(res.data.message);
        } else {
          this.setState({ isLoading: false });

          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });

        this.closePopup(false);
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  render() {
    let {
      my_assigned_lead,
      collapsedArray,
      equipment_images,
      equipment_docs_preview,
      old_images,
      old_docs,
      doc_details,
    } = this.state;

    let { loading, language } = this.props.language;
    let lead_action = {};
    let add_documents_images = {};
    let add_daily_report = {};
    let commision_paid = {};

    if (!loading && language) {
      lead_action = language.lead_action;
      add_documents_images = language.add_documents_images;
      add_daily_report = language.add_daily_report;
      commision_paid = language.commision_paid;

      language = language.my_archived_leads;
    } else {
      language = {};
    }

    const popperConfig = {
      strategy: "fixed",
    };

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />

                {/* STATUS CHANGES */}

                <StatusChanges
                  show={this.state.show}
                  leadHeader={this.state.lead_header}
                  financerList={this.state.financer_list}
                  contactPersonList={this.state.contact_person_list}
                  allTags={this.state.all_tags}
                  updateStatus={this.updateStatus}
                  getSelectedFinancer={this.getSelectedFinancer}
                  selected_financer={this.state.selected_financer}
                  selected_header_id={this.state.selected_header_id}
                  onClose={this.closePopup}
                />

                {/* ADD DAILY REPORT */}
                <CustomPopup
                  title={add_daily_report.add_daily_report_h}
                  show={this.state.add_daily_report}
                  onClose={this.closeDailyReportPopup}
                >
                  <div className="daily_report_form">
                    <div className="heading_form">
                      <Form onSubmit={this.addDailyReport}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            {add_daily_report.status_label}
                            {/* <span className="required">*</span> */}
                          </Form.Label>
                          <CreatableSelect
                            isClearable
                            onChange={(value) => {
                              this.handleSelectData(value, "heading");
                            }}
                            options={this.state.leadHeader}
                            placeholder={
                              add_daily_report.select_type_status_label_plh
                            }
                            name="heading"
                            // value={this.state.heading_details}
                          />
                          <p className="invalid_input">
                            {this.state.error.heading}
                          </p>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>
                            {add_daily_report.travel_date_label}
                            {/* <span className="required">*</span> */}
                          </Form.Label>
                          <ReactDatePicker
                            selected={
                              this.state.travel_date !== "" &&
                              new Date(this.state.travel_date)
                            }
                            className="control_field daily_report_date"
                            dateFormat="dd-MM-yyyy"
                            onChange={(date) => {
                              this.setState({
                                travel_date: moment(date).format("YYYY-MM-DD"),
                              });
                            }}
                            placeholderText={add_daily_report.select_date_plh}
                            maxDate={new Date()}
                            // required
                          />
                          <p className="invalid_input">
                            {this.state.error.travel_date}
                          </p>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>
                            {add_daily_report.comunication_type_label}
                            {/* <span className="required">*</span> */}
                          </Form.Label>
                          <Form.Control
                            as="select"
                            className="heading_text_field"
                            name="comunication_type"
                            value={this.state.comunication_type}
                            onChange={this.handleChange}
                            // required
                          >
                            <option value="">
                              {add_daily_report.select_comunication_type_plh}
                            </option>
                            <option value="1">
                              {add_daily_report.face_to_face}
                            </option>
                            <option value="2">{add_daily_report.email}</option>
                            <option value="3">
                              {add_daily_report.over_phone}
                            </option>
                          </Form.Control>
                        </Form.Group>

                        {this.state.comunication_type === "1" && (
                          <>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                {add_daily_report.start_location_label}
                                {/* <span className="required">*</span> */}
                              </Form.Label>

                              <AutoCompleteLocation
                                handleGetLocation={(v) => {
                                  this.handleGetLocation("start_location", v);
                                }}
                                value={this.state.start_location}
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>
                                {add_daily_report.end_location_label}
                                {/* <span className="required">*</span> */}
                              </Form.Label>

                              <AutoCompleteLocation
                                handleGetLocation={(v) => {
                                  this.handleGetLocation("end_location", v);
                                }}
                                value={this.state.end_location}
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>
                                {add_daily_report.approx_km_label}
                                {/* <span className="required">*</span> */}
                              </Form.Label>
                              <Form.Control
                                placeholder={add_daily_report.type_approx_km}
                                name="approx_km"
                                type="text"
                                value={this.state.approx_km}
                                className="heading_text_field"
                                onChange={this.handleChange}
                                // required
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>
                                {add_daily_report.travel_mode_label}
                                {/* <span className="required">*</span> */}
                              </Form.Label>
                              <Form.Control
                                as="select"
                                className="heading_text_field"
                                name="travel_mode"
                                value={this.state.travel_mode}
                                onChange={this.handleChange}
                                // required
                              >
                                <option value="">
                                  {add_daily_report.select_travel_mode_plh}
                                </option>
                                <option value="1">
                                  {add_daily_report.car}
                                </option>
                                <option value="2">
                                  {add_daily_report.bike}
                                </option>
                              </Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>
                                {add_daily_report.travel_cost_label}
                                {/* <span className="required">*</span> */}
                              </Form.Label>
                              <Form.Control
                                placeholder={add_daily_report.travel_cost_plh}
                                name="travel_cost"
                                type="text"
                                className="heading_text_field"
                                value={this.state.travel_cost}
                                onChange={this.handleChange}
                                // required
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>
                                {add_daily_report.accomodation_label}
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                name="accomodation"
                                value={this.state.accomodation}
                                rows={3}
                                className="heading_text_area"
                                placeholder={add_daily_report.accomodation_plh}
                                onChange={this.handleChange}
                                // required
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>
                                {add_daily_report.accomodation_cost_label}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="accomodation_cost"
                                value={this.state.accomodation_cost}
                                className="heading_text_area"
                                placeholder={
                                  add_daily_report.accomodation_cost_plh
                                }
                                onChange={this.handleChange}
                                // required
                              />
                            </Form.Group>
                          </>
                        )}

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>
                            {add_daily_report.submit_documents_label}
                          </Form.Label>
                          <div className="upload_doc">
                            {/* CURRENT ADDED DOCS */}
                            {doc_details &&
                              doc_details.map((doc, key) => {
                                let preview;
                                if (
                                  doc.extension === "jpg" ||
                                  doc.extension === "png" ||
                                  doc.extension === "jpeg"
                                ) {
                                  preview = image_preview;
                                } else if (doc.extension === "pdf") {
                                  preview = pdf_preview;
                                } else if (
                                  doc.extension === "doc" ||
                                  doc.extension === "docx"
                                ) {
                                  preview = word_preview;
                                }
                                return (
                                  <div className="doc_partts" key={key}>
                                    <div className="doc_partts_inner text-center">
                                      <img src={preview} alt={doc.name} />
                                      <label>{doc.name?.substring(0, 8)}</label>
                                    </div>
                                  </div>
                                );
                              })}

                            <div className="doc_partts">
                              <div className="doc_partts_inner text-center upload-btn-wrapper121">
                                <input
                                  type="file"
                                  name="myfile"
                                  accept="image/png, image/jpg, image/jpeg, .doc, .docx, .pdf"
                                  onChange={this.handleImages}
                                  multiple
                                />
                                <div className="add_more">
                                  <img src={Plus_Black} alt="add more" />
                                </div>
                                <label>{add_daily_report.add_more}</label>
                              </div>
                            </div>
                          </div>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>
                            {add_daily_report.notes_and_comments_label}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={5}
                            name="note"
                            value={this.state.note}
                            className="heading_text_area"
                            placeholder={add_daily_report.type_plh}
                            onChange={this.handleChange}
                            // required
                          />
                        </Form.Group>

                        <div className="status_footer">
                          <button type="submit" className="status_update">
                            {add_daily_report.submit_btn}
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </CustomPopup>

                {/* SET REMINDER */}

                <SetRemainder
                  show={this.state.isSetRemainder}
                  contactList={this.state.contact_list}
                  onClose={() => {
                    this.setState({ isSetRemainder: false });
                  }}
                  setRemainder={this.setRemainder}
                />

                {/* COMMISION PAID */}
                <DeletePopup
                  show={this.state.isCommitionPaid}
                  message={commision_paid.commision_paid_alert_msg}
                  yes_msg={commision_paid.yes_btn}
                  no_msg={commision_paid.no_btn}
                  no={() => {
                    this.setState({
                      isCommitionPaid: false,
                      lead_id: "",
                      lead_assignee_activity_id: "",
                    });
                  }}
                  yes={() => {
                    this.setState({
                      isCommitionPaid: false,
                    });
                    this.commisionPaid();
                  }}
                />

                {/* ADD DOCUMENT */}
                <CustomPopup
                  show={this.state.isAddedDocument}
                  title={add_documents_images.add_documents_images_h}
                  onClose={this.closeAddDocumentPopup}
                  isCenter={true}
                >
                  <LoadingOverlay
                    active={this.state.isLoading}
                    spinner
                    text="Loading ..."
                  >
                    {/* IMAGE */}
                    <Form.Group>
                      <Form.Label>
                        {add_documents_images.add_equipment_images}
                      </Form.Label>

                      <div className="upload_image_box">
                        {old_images &&
                          old_images.map((image, key) => {
                            return (
                              <div className="image_box" key={key}>
                                <img
                                  src={MEDIA_BASE_URL + image.url}
                                  alt="HMC"
                                />
                                <span
                                  className="close_preview"
                                  onClick={() => {
                                    this.deleteOldImage(image.id, key);
                                  }}
                                >
                                  <img src={cross_preview} alt="HMC" />
                                </span>
                              </div>
                            );
                          })}

                        {equipment_images &&
                          equipment_images.map((preview, index) => {
                            return (
                              <div className="image_box" key={index}>
                                <img
                                  src={URL.createObjectURL(preview)}
                                  alt="HMC"
                                />
                                <span
                                  className="close_preview"
                                  onClick={() => {
                                    this.deleteImage(index);
                                  }}
                                >
                                  <img src={cross_preview} alt="HMC" />
                                </span>
                              </div>
                            );
                          })}

                        <div className="upload_box">
                          <div className="upload_btn_wrappererer">
                            <button className="btnner">
                              <img src={Upload_img_icon_img} />
                            </button>

                            <Form.Control
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              name="images"
                              multiple
                              onChange={this.handleDocImages}
                            />
                          </div>
                        </div>
                      </div>
                    </Form.Group>

                    {/* DOCUMENT */}
                    <Form.Group>
                      <Form.Label>
                        {add_documents_images.add_document}
                      </Form.Label>

                      <div className="cert_box">
                        {old_docs &&
                          old_docs.map((doc, index) => {
                            return (
                              <div className="cert_box_inner" key={index}>
                                <div className="cret_file">
                                  <img src={doc.preview} alt="HMC" />
                                </div>
                                <Form.Label>{doc.name}</Form.Label>
                              </div>
                            );
                          })}
                        {equipment_docs_preview &&
                          equipment_docs_preview.map((doc, index) => {
                            return (
                              <div className="cert_box_inner" key={index}>
                                <div className="cret_file">
                                  <img src={doc.preview} alt="HMC" />
                                </div>
                                <Form.Label>{doc.name}</Form.Label>
                                <div className="click_drop"></div>
                              </div>
                            );
                          })}

                        <div className="cert_box_upload">
                          <div className="upload_btn_wrappererer">
                            <button className="btnner">
                              <img src={Up_doc_icon_img} />
                            </button>
                            <Form.Control
                              type="file"
                              accept=".doc, .docx, .pdf, .xls,.xlsx"
                              onChange={this.handleDocument}
                              multiple
                            />
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                    <div className="d-flex justify-content-end sub_container">
                      <button
                        type="submit"
                        className="submit_but"
                        onClick={this.leadDocumentUpload}
                      >
                        {add_documents_images.submit_btn}
                      </button>
                    </div>
                  </LoadingOverlay>
                </CustomPopup>

                {/* UNARCHIVE LEAD */}
                <DeletePopup
                  show={this.state.isUnArchivedLead}
                  message={language.alert_msg_move}
                  yes_msg={language.yes_btn}
                  no_msg={language.no_btn}
                  no={() => {
                    this.setState({
                      isUnArchivedLead: false,
                    });
                    this.closePopup();
                  }}
                  yes={() => {
                    this.setState({
                      isUnArchivedLead: false,
                    });
                    this.unArchiveLead();
                  }}
                />

                <Col lg="9" md="8" sm="12">
                  <div className="dashboard_right_panel">
                    <Row>
                      <Col
                        lg={this.state.toggleEvent ? "9" : "12"}
                        md="12"
                        sm="12"
                        className="lead_resuuultts"
                      >
                        <div className="my_lead_section">
                          <div className="my_lead_heading">
                            <h2>{language.my_archived_leads_h}</h2>
                            <div className="mylead_heading_right">
                              <div className="show_entries">
                                <Form.Label>{language.show_entries}</Form.Label>
                                <select
                                  className="show_field"
                                  name="per_page"
                                  onChange={this.handleOnChange}
                                  value={this.state.per_page}
                                >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                              </div>

                              <Button
                                className="toggle filter_buttonner"
                                onClick={this.onToggle}
                              >
                                <img alt="HMC" src={Setting_Filter_Img} />
                              </Button>
                            </div>
                          </div>

                          {/* SHOWING RESULTS */}
                          <div className="my_lead_inner_page my_lead_inner_box">
                            <div className="mylead_inner_inner_page my_lead_inner_content preassigned">
                              {my_assigned_lead &&
                                my_assigned_lead.map((lead, index) => {
                                  let create_date = moment(
                                    lead.created_at
                                  ).format("DD/MM/YYYY");

                                  let tags = lead.tags && lead.tags.split(",");
                                  let updated_at = lead.updated_at
                                    ? moment(lead.updated_at).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "";
                                  let header_status =
                                    lead.header_status &&
                                    lead.header_status.split("-");

                                  let hmc_user_type, hmc_user_msg;
                                  if (lead.is_login === "1") {
                                    hmc_user_msg = language.hmc_user_onboard;
                                    hmc_user_type = hmc_user_onboard;
                                  } else if (lead.is_login === "0") {
                                    hmc_user_msg =
                                      language.hmc_user_not_onboard;
                                    hmc_user_type = hmc_user_not_onboard;
                                  } else if (lead.is_login === null) {
                                    hmc_user_msg = language.not_hmc_user;
                                    hmc_user_type = not_hmc_user;
                                  }

                                  return (
                                    <div
                                      className={`lead_equipment eqp_lead ${
                                        this.state.clickedPosition === index
                                          ? "blue_border"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        this.setState({
                                          clickedPosition: index,
                                        });
                                      }}
                                      key={index}
                                    >
                                      <div className="eqp_lead_part">
                                        <div className="lead_eq_inner">
                                          <label>
                                            {language.customer_name}
                                          </label>
                                          <div className="d-flex align-items-center">
                                            <label className="lead_tooltip">
                                              <img
                                                src={hmc_user_type}
                                                data-tip
                                                data-for={`user_type${index}`}
                                              />
                                              <ReactTooltip
                                                id={`user_type${index}`}
                                                place="top"
                                                effect="solid"
                                              >
                                                {hmc_user_msg}
                                              </ReactTooltip>
                                            </label>
                                            <span>
                                              <label
                                                data-tip
                                                data-for={`customer_name${index}`}
                                              >
                                                {lead.customer_name}
                                                <ReactTooltip
                                                  id={`customer_name${index}`}
                                                  place="top"
                                                  effect="solid"
                                                >
                                                  {lead.customer_name +
                                                    ", " +
                                                    lead.customer_phone}
                                                </ReactTooltip>
                                              </label>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="lead_eq_inner">
                                          <label>{language.lead_type}</label>
                                          <span>
                                            <label
                                              data-tip
                                              data-for={`euipment_category${index}`}
                                            >
                                              {lead.euipment_category === "1"
                                                ? language.new_equipment
                                                : language.old_equipment}
                                              <ReactTooltip
                                                id={`euipment_category${index}`}
                                                place="top"
                                                effect="solid"
                                              >
                                                {lead.euipment_category === "1"
                                                  ? language.new_equipment
                                                  : language.old_equipment}
                                              </ReactTooltip>
                                            </label>
                                          </span>
                                        </div>

                                        <div className="lead_eq_inner">
                                          <label>{language.equip_name}</label>
                                          <span
                                            data-tip
                                            data-for={`euipment_name${index}`}
                                          >
                                            {lead.euipment_name}
                                            <ReactTooltip
                                              id={`euipment_name${index}`}
                                              place="top"
                                              effect="solid"
                                            >
                                              {lead.euipment_name}
                                            </ReactTooltip>
                                          </span>
                                        </div>

                                        <div className="lead_eq_inner">
                                          <label>
                                            {language.current_status}
                                          </label>

                                          {header_status &&
                                            header_status.length > 0 && (
                                              <div className="pending">
                                                {header_status[0]}
                                              </div>
                                            )}
                                        </div>

                                        <div className="lead_eq_inner">
                                          <label>{language.sub_status}</label>
                                          {header_status &&
                                            header_status.length > 0 && (
                                              <>
                                                <span
                                                  data-tip
                                                  data-for={`header_status${index}`}
                                                >
                                                  {header_status[1]}
                                                  <ReactTooltip
                                                    id={`header_status${index}`}
                                                    place="top"
                                                    effect="solid"
                                                  >
                                                    {header_status[1]}
                                                  </ReactTooltip>
                                                </span>
                                              </>
                                            )}
                                        </div>

                                        <div className="lead_eq_inner">
                                          <label>{language.action}</label>
                                          <div className="lead_label_inner">
                                            <Dropdown className="select_box_preassigneds">
                                              <Dropdown.Toggle
                                                variant="success"
                                                id="dropdown-basic"
                                              >
                                                <img src={Three_Dots} />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu
                                                popperConfig={popperConfig}
                                              >
                                                {/* <div
                                                  className="dropdown-item action-cursor"
                                                  onClick={() => {
                                                    this.setState({
                                                      show: true,
                                                      lead_id: lead.lead_id,
                                                      lead_assignee_activity_id:
                                                        lead.lead_assignee_activity_id,
                                                      lead_assignee_hash_id:
                                                        lead.lead_assignee_hash_id,
                                                      lead_hash_id:
                                                        lead.hash_id,
                                                      selected_header_id:
                                                        header_status &&
                                                        header_status.length > 0
                                                          ? header_status[2]
                                                          : -1,
                                                    });
                                                  }}
                                                >
                                                  Update Status
                                                </div>

                                                <Link
                                                  to={{
                                                    pathname: `/edit-lead/${lead.hash_id}`,
                                                    state: {
                                                      section_name:
                                                        "my_assigned_list",
                                                      lead_types:
                                                        LeadTypes.MY_ASSIGN_LEAD,
                                                    },
                                                  }}
                                                  className="dropdown-item"
                                                  role="button"
                                                >
                                                  Update Lead
                                                </Link>

                                                <Link
                                                  to={{
                                                    pathname: `/feedback-history/${lead.lead_id}/${lead.lead_assignee_activity_id}/my_assigned_list/${this.state.dashboard_id}`,
                                                    state: {
                                                      currentPage:
                                                        this.state.currentPage,
                                                      lead_types:
                                                        LeadTypes.MY_ASSIGN_LEAD,
                                                    },
                                                  }}
                                                  className="dropdown-item"
                                                  role="button"
                                                >
                                                  Status History
                                                </Link> */}

                                                {/* ONLY ADMIN */}
                                                {/* {localStorage.getItem(
                                                  "lead_user_role"
                                                ) === "1" && (
                                                  <div
                                                    className="dropdown-item action-cursor"
                                                    onClick={() => {
                                                      this.setState({
                                                        isCommitionPaid: true,
                                                        lead_id: lead.lead_id,
                                                        lead_assignee_activity_id:
                                                          lead.lead_assignee_activity_id,
                                                      });
                                                      this.commisionPaid();
                                                    }}
                                                  >
                                                    Commition Paid
                                                  </div>
                                                )} */}

                                                {/* <div
                                                  className="dropdown-item action-cursor"
                                                  onClick={() => {
                                                    this.setState({
                                                      lead_id: lead.lead_id,
                                                      isAddedDocument: true,
                                                      activity_id:
                                                        lead.lead_assignee_activity_id,
                                                    });
                                                    this.getAllDocumentsLeadWise(
                                                      lead.hash_id,
                                                      lead.lead_assignee_hash_id
                                                    );
                                                  }}
                                                >
                                                  Add Documents/Images
                                                </div>

                                                <div
                                                  className="dropdown-item action-cursor"
                                                  onClick={() => {
                                                    this.setState({
                                                      isSetRemainder: true,
                                                      lead_hash_id:
                                                        lead.hash_id,
                                                    });
                                                  }}
                                                >
                                                  Reminder Schedular
                                                </div>

                                                <div
                                                  className="dropdown-item action-cursor"
                                                  onClick={() => {
                                                    this.setState({
                                                      add_daily_report: true,
                                                      lead_id: lead.lead_id,
                                                      lead_assigne_activity_id:
                                                        lead.lead_assignee_activity_id,
                                                    });
                                                  }}
                                                >
                                                  Add Daily Report
                                                </div> */}

                                                <div
                                                  className="dropdown-item action-cursor"
                                                  onClick={() => {
                                                    this.setState({
                                                      isUnArchivedLead: true,

                                                      lead_assignee_hash_id:
                                                        lead.lead_assignee_hash_id,
                                                      lead_hash_id:
                                                        lead.hash_id,
                                                    });
                                                  }}
                                                >
                                                  {
                                                    language.move_to_my_assign_lead
                                                  }
                                                </div>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>

                                        <div className="lead_arrow">
                                          <img
                                            alt="HMC"
                                            src={Lead_Arrow_Img}
                                            onClick={() => {
                                              collapsedArray[index] =
                                                !collapsedArray[index];
                                              this.setState({
                                                collapsedArray,
                                              });
                                            }}
                                            className={
                                              collapsedArray[index]
                                                ? "t_iconhide"
                                                : "t_icon"
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div
                                        className={
                                          collapsedArray[index]
                                            ? "collapse lead_equipment show"
                                            : "collapse lead_equipment "
                                        }
                                      >
                                        <div className="lead_eq_inner">
                                          <label>{language.date}</label>
                                          <span>{create_date}</span>
                                        </div>
                                        <div className="lead_eq_inner">
                                          <label>
                                            {language.last_mod_date}
                                          </label>
                                          <span>{updated_at}</span>
                                        </div>
                                        <div className="lead_eq_inner">
                                          <label>{language.location}</label>
                                          <span>
                                            {lead.customer_address_city &&
                                              lead.customer_address_state &&
                                              lead.customer_address_city +
                                                ", " +
                                                lead.customer_address_state}
                                          </span>
                                        </div>
                                        <div className="lead_eq_inner">
                                          <label>{language.tags}</label>
                                          {tags &&
                                            tags.map((tag, key) => {
                                              tag = tag.split("-");
                                              let title = tag[0];
                                              let tag_title_color = tag[1];
                                              let tag_back_color = tag[2];
                                              return (
                                                <div
                                                  className="tags"
                                                  key={key}
                                                  style={{
                                                    background: tag_back_color,
                                                    color: tag_title_color,
                                                  }}
                                                >
                                                  {title}
                                                </div>
                                              );
                                            })}
                                        </div>
                                        <div className="lead_eq_inner">
                                          <label>{language.lui}</label>
                                          <span>{lead.luid}</span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>

                          {/* PAGINATION */}
                          <div className="lead_showing_result">
                            <label>
                              {language.showing_results}{" "}
                              {Math.min(
                                this.state.totalItems,
                                this.state.currentPage * this.state.per_page
                              )}{" "}
                              {language.out_of} {this.state.totalItems}
                            </label>
                            <div className="result_pagination">
                              <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={this.onPageChange}
                                pageRangeDisplayed={3}
                                pageCount={this.state.totalPage}
                                previousLabel="<"
                                activeClassName="active"
                                disabledClassName="disable"
                                renderOnZeroPageCount={null}
                                forcePage={parseInt(this.state.currentPage) - 1}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      {this.state.toggleEvent && (
                        <AdvanceFilter
                          resetFilter={this.resetFilter}
                          myState={this.state}
                          handleOnChange={this.handleOnChange}
                          setKeyWithValue={this.setKeyWithValue}
                          removeLuid={this.removeLuid}
                          removeEquipment={this.removeEquipment}
                          tagChanges={this.tagChanges}
                          singleSelect={this.singleSelect}
                          onAdvanceFilterClick={this.onAdvanceFilterClick}
                          activeKey="my_assign_lead"
                        />
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(MyArchiveLead);
