export const getFileExtension = (file) => {
  let regex = new RegExp("[^.]+$");
  return file.name.match(regex)[0].toLowerCase();
};

export const stringReplace = (str, replace_value, new_value) => {
  return str.replaceAll(replace_value, new_value);
};

export const isNumber = (value) => {
  return typeof value === "number";
};

// export const MEDIA_BASE_URL = localStorage.getItem("media_base_url");
export const MEDIA_BASE_URL =
  "https://hm-care-prod1.s3.ap-south-1.amazonaws.com"; // PROD
// export const MEDIA_BASE_URL =
//   "https://hm-care-staging1.s3.ap-south-1.amazonaws.com"; // STAGING
