import React, { Component, useRef } from "react";
import {
  Container,
  Row,
  Col,
  ProgressBar,
  Dropdown,
  Form,
  Select,
  Button,
} from "react-bootstrap";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import "./leaddetails.css";
import { Link } from "react-router-dom";
import LeftPanelPage from "../../components/leftpanel/LeftPanelPage";
import ReactTooltip from "react-tooltip";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import {
  hmc_user_onboard,
  Lead_cross_Img,
  Lead_Update_Img,
  Lead_chat_Img,
  Lead_Feedback_Img,
  not_hmc_user,
  not_onboard_Img,
  arrow_right,
  Export_1_Img,
  Export_2_Img,
  Man1_det_Image,
  Call_log1_Img,
  Act_Log_2,
  Lead_Location_Img,
  Lead_Ph_Img,
  Update_Leads_Img,
  Notification_img,
  Lead_det_Arrow_Img,
  Pdf_1_Img,
  Pdf_download_Img,
  Doc_File_Img,
} from "../../assets/images";
export default class MyLeadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
    };
  }
  render() {
    return (
      <div className="home_new">
        <section className="dashboard_part">
          <Container fluid>
            <Row>
              <LeftPanelPage />
              <Col lg="9" md="8" sm="12">
                <div className="dashboard_breadcrumb">
                  <a href="#">Home</a> / <a href="#">Lead Management</a> / My
                  Lead
                </div>
                <div className="dashboard_right_panel">
                  <Row>
                    <Col lg="12" md="12" sm="12" className="lead_resuuultts">
                      <div className="my_lead_section">
                        <div className="my_lead_heading">
                          <h2>Details Page</h2>
                          <div className="mylead_heading_right">
                            <div className="export_partts">
                              <a href="#">
                                <img src={Export_1_Img} />
                                <span>Export</span>
                              </a>
                            </div>
                            <div className="export_partts">
                              <a href="#">
                                <img src={Export_2_Img} />
                                <span>Chart View</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="man_details">
                          <div className="man_det_left">
                            <div className="man_det_image">
                              <div className="mandet_inner_image">
                                <img src={Man1_det_Image} />
                              </div>
                              <div className="actiity_log">
                                <div className="activity_call_part">
                                  <a href="#">
                                    <img src={Call_log1_Img} />
                                  </a>
                                  <span>Call</span>
                                </div>
                                <div className="activity_log_part">
                                  <a href="#">
                                    {/* <img src={Act_Log_2} /> */}
                                  </a>
                                  <span>Activity Log</span>
                                </div>
                              </div>
                            </div>
                            <div className="man_det_details">
                              <h2>
                                Shanawaj Khan{" "}
                                <span className="hmc_user">
                                  <img src={hmc_user_onboard} /> HMC User
                                </span>
                              </h2>
                              <p>
                                <img src={Lead_Location_Img} />
                                Chakiya, Uttar Pradesh
                              </p>
                              <div className="eqp_dettailss">
                                <p>
                                  <label>Equipment Name : </label>
                                  <span>1245 JCP M22 Lorem</span>
                                </p>
                                <p>
                                  <label>Lead Type : </label>
                                  <span>Text</span>
                                </p>
                                <p>
                                  <label>Lead Genaretor : </label>
                                  <span>Kousik Kumar Roy</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="man_det_right">
                            <div className="lead_creat_date">
                              <label>Lead Creation Date : </label>
                              <span className="create_dateeew">
                                12 June , 2022
                              </span>
                            </div>

                            <div className="lead_creat_date">
                              <label>Lead Status : </label>
                              <div className="lead_stat">
                                <span className="in_process"> IN PROCESS</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lead_act_partts">
                          <div className="lead_activity_heading">
                            <h2>Lead Activity</h2>
                            <div className="assign_dis">
                              <a href="/assigndistributer">
                                Assign to new Distributor{" "}
                              </a>
                            </div>
                          </div>
                          <div className="my_lead_inner_page lead_det_inner">
                            <div className="mylead_inner_inner_page">
                              <div className="lead_equipment">
                                <div className="lead_eq_inner">
                                  <label>Current assignee</label>
                                  <span>Mukesh Mandal</span>
                                  <div className="lead_ph">
                                    <img src={Lead_Ph_Img} />
                                    123456645
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Distributer name</label>
                                  <span>
                                    <img
                                      src={hmc_user_onboard}
                                      data-tip
                                      data-for="registerTip7"
                                      className="lead_tooltip_img"
                                    />
                                    <ReactTooltip
                                      id="registerTip7"
                                      place="top"
                                      effect="solid"
                                    >
                                      HMC User
                                    </ReactTooltip>
                                    Dinesh Kumar
                                  </span>
                                  <div className="lead_ph">
                                    <img src={Lead_Ph_Img} />
                                    123456645
                                  </div>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Expected close Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead Progress</label>
                                  <span style={{ width: "70%" }}>
                                    <ProgressBar now={60} />
                                  </span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead Status</label>
                                  <span className="lead_det_status">
                                    IN PROCESS
                                  </span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip122"
                                      >
                                        <img src={Update_Leads_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip122"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update Lead
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip121"
                                      >
                                        <img src={Notification_img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip121"
                                        place="top"
                                        effect="solid"
                                      >
                                        Notification
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>

                                <div className="lead_eq_inner">
                                  <div className="de_arrow">
                                    <img src={Lead_det_Arrow_Img} />
                                  </div>
                                </div>
                              </div>

                              <div className="lead_equipment">
                                <div className="lead_eq_inner">
                                  <label>Current assignee</label>
                                  <span>Mukesh Mandal</span>
                                  <div className="lead_ph">
                                    <img src={Lead_Ph_Img} />
                                    123456645
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Distributer name</label>
                                  <span>
                                    <img
                                      src={not_hmc_user}
                                      data-tip
                                      data-for="registerTip121345"
                                      className="lead_tooltip_img"
                                    />
                                    <ReactTooltip
                                      id="registerTip121345"
                                      place="top"
                                      effect="solid"
                                    >
                                      No User
                                    </ReactTooltip>
                                    Dinesh Kumar
                                  </span>
                                  <div className="lead_ph">
                                    <img src={Lead_Ph_Img} />
                                    123456645
                                  </div>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Expected close Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead Progress</label>
                                  <span style={{ width: "70%" }}>
                                    <ProgressBar now={60} />
                                  </span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead Status</label>
                                  <span className="lead_det_status">
                                    IN PROCESS
                                  </span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip122"
                                      >
                                        <img src={Update_Leads_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip122"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update Lead
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip121"
                                      >
                                        <img src={Notification_img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip121"
                                        place="top"
                                        effect="solid"
                                      >
                                        Notification
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>

                                <div className="lead_eq_inner">
                                  <div className="de_arrow">
                                    <img src={Lead_det_Arrow_Img} />
                                  </div>
                                </div>
                              </div>

                              <div className="lead_equipment">
                                <div className="lead_eq_inner">
                                  <label>Current assignee</label>
                                  <span>Mukesh Mandal</span>
                                  <div className="lead_ph">
                                    <img src={Lead_Ph_Img} />
                                    123456645
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Distributer name</label>
                                  <span>
                                    <img
                                      src={hmc_user_onboard}
                                      data-tip
                                      data-for="registerTip7"
                                      className="lead_tooltip_img"
                                    />
                                    <ReactTooltip
                                      id="registerTip7"
                                      place="top"
                                      effect="solid"
                                    >
                                      HMC User
                                    </ReactTooltip>
                                    Dinesh Kumar
                                  </span>
                                  <div className="lead_ph">
                                    <img src={Lead_Ph_Img} />
                                    123456645
                                  </div>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Expected close Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead Progress</label>
                                  <span style={{ width: "70%" }}>
                                    <ProgressBar now={60} />
                                  </span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead Status</label>
                                  <span className="lead_det_status">
                                    IN PROCESS
                                  </span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip122"
                                      >
                                        <img src={Update_Leads_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip122"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update Lead
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip121"
                                      >
                                        <img src={Notification_img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip121"
                                        place="top"
                                        effect="solid"
                                      >
                                        Notification
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>

                                <div className="lead_eq_inner">
                                  <div className="de_arrow">
                                    <img src={Lead_det_Arrow_Img} />
                                  </div>
                                </div>
                              </div>

                              <div className="lead_equipment">
                                <div className="lead_eq_inner">
                                  <label>Current assignee</label>
                                  <span>Mukesh Mandal</span>
                                  <div className="lead_ph">
                                    <img src={Lead_Ph_Img} />
                                    123456645
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Distributer name</label>
                                  <span>
                                    <img
                                      src={hmc_user_onboard}
                                      data-tip
                                      data-for="registerTip7"
                                      className="lead_tooltip_img"
                                    />
                                    <ReactTooltip
                                      id="registerTip7"
                                      place="top"
                                      effect="solid"
                                    >
                                      HMC User
                                    </ReactTooltip>
                                    Dinesh Kumar
                                  </span>
                                  <div className="lead_ph">
                                    <img src={Lead_Ph_Img} />
                                    123456645
                                  </div>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Expected close Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead Progress</label>
                                  <span style={{ width: "70%" }}>
                                    <ProgressBar now={60} />
                                  </span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead Status</label>
                                  <span className="lead_det_status">
                                    IN PROCESS
                                  </span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip122"
                                      >
                                        <img src={Update_Leads_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip122"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update Lead
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip121"
                                      >
                                        <img src={Notification_img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip121"
                                        place="top"
                                        effect="solid"
                                      >
                                        Notification
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>

                                <div className="lead_eq_inner">
                                  <div className="de_arrow">
                                    <img src={Lead_det_Arrow_Img} />
                                  </div>
                                </div>
                              </div>

                              <div className="lead_equipment">
                                <div className="lead_eq_inner">
                                  <label>Current assignee</label>
                                  <span>Mukesh Mandal</span>
                                  <div className="lead_ph">
                                    <img src={Lead_Ph_Img} />
                                    123456645
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Distributer name</label>
                                  <span>
                                    <img
                                      src={hmc_user_onboard}
                                      data-tip
                                      data-for="registerTip7"
                                      className="lead_tooltip_img"
                                    />
                                    <ReactTooltip
                                      id="registerTip7"
                                      place="top"
                                      effect="solid"
                                    >
                                      HMC User
                                    </ReactTooltip>
                                    Dinesh Kumar
                                  </span>
                                  <div className="lead_ph">
                                    <img src={Lead_Ph_Img} />
                                    123456645
                                  </div>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Expected close Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead Progress</label>
                                  <span style={{ width: "70%" }}>
                                    <ProgressBar now={60} />
                                  </span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead Status</label>
                                  <span className="lead_det_status">
                                    IN PROCESS
                                  </span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip122"
                                      >
                                        <img src={Update_Leads_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip122"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update Lead
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip121"
                                      >
                                        <img src={Notification_img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip121"
                                        place="top"
                                        effect="solid"
                                      >
                                        Notification
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>

                                <div className="lead_eq_inner">
                                  <div className="de_arrow">
                                    <img src={Lead_det_Arrow_Img} />
                                  </div>
                                </div>
                              </div>

                              <div className="lead_equipment">
                                <div className="lead_eq_inner">
                                  <label>Current assignee</label>
                                  <span>Mukesh Mandal</span>
                                  <div className="lead_ph">
                                    <img src={Lead_Ph_Img} />
                                    123456645
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Distributer name</label>
                                  <span>
                                    <img
                                      src={hmc_user_onboard}
                                      data-tip
                                      data-for="registerTip7"
                                      className="lead_tooltip_img"
                                    />
                                    <ReactTooltip
                                      id="registerTip7"
                                      place="top"
                                      effect="solid"
                                    >
                                      HMC User
                                    </ReactTooltip>
                                    Dinesh Kumar
                                  </span>
                                  <div className="lead_ph">
                                    <img src={Lead_Ph_Img} />
                                    123456645
                                  </div>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Expected close Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead Progress</label>
                                  <span style={{ width: "70%" }}>
                                    <ProgressBar now={60} />
                                  </span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead Status</label>
                                  <span className="lead_det_status">
                                    IN PROCESS
                                  </span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip122"
                                      >
                                        <img src={Update_Leads_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip122"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update Lead
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip121"
                                      >
                                        <img src={Notification_img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip121"
                                        place="top"
                                        effect="solid"
                                      >
                                        Notification
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>

                                <div className="lead_eq_inner">
                                  <div className="de_arrow">
                                    <img src={Lead_det_Arrow_Img} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="lead_status_activity lead_tabss">
                          <h2>Lead Status Activity</h2>
                          <div className="lead_sta_act_innernn">
                            <Tabs defaultActiveKey="second">
                              <Tab eventKey="first" title="Status">
                                <span>03</span>
                                Hii, I am 1st tab content
                              </Tab>
                              <Tab eventKey="second" title="Documents">
                                <div className="latest_files_upper">
                                  <div className="latest_files">
                                    <div className="latest_files_heading">
                                      <h3>Latest Files</h3>
                                      <p>Total 382 fiels, 2,6GB space usage</p>
                                    </div>
                                    <div className="view_all">
                                      <a href="#">View All</a>
                                    </div>
                                  </div>
                                  <div className="pdf_download">
                                    <div className="pdf_left">
                                      <img src={Pdf_1_Img} />
                                      <div className="pdf_text">
                                        <h4>Lorem Project tech requirements</h4>
                                        <p>
                                          22 Jan. 2022, 03:34 pm. uploaded by
                                          Abir Kumar Das
                                        </p>
                                      </div>
                                    </div>
                                    <div className="pdf_right">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant=""
                                          id="dropdown-basic"
                                        >
                                          <div className="pdf_right_box">
                                            <img src={Pdf_download_Img} />
                                          </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item href="#/action-1">
                                            Action
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-2">
                                            Another action
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-3">
                                            Something else
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>

                                  <div className="pdf_download">
                                    <div className="pdf_left">
                                      <img src={Doc_File_Img} />
                                      <div className="pdf_text">
                                        <h4>Lorem Project tech requirements</h4>
                                        <p>
                                          22 Jan. 2022, 03:34 pm. uploaded by
                                          Abir Kumar Das
                                        </p>
                                      </div>
                                    </div>
                                    <div className="pdf_right">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant=""
                                          id="dropdown-basic"
                                        >
                                          <div className="pdf_right_box">
                                            <img src={Pdf_download_Img} />
                                          </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item href="#/action-1">
                                            Action
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-2">
                                            Another action
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-3">
                                            Something else
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>

                                  <div className="pdf_download">
                                    <div className="pdf_left">
                                      <img src={Pdf_1_Img} />
                                      <div className="pdf_text">
                                        <h4>Lorem Project tech requirements</h4>
                                        <p>
                                          22 Jan. 2022, 03:34 pm. uploaded by
                                          Abir Kumar Das
                                        </p>
                                      </div>
                                    </div>
                                    <div className="pdf_right">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant=""
                                          id="dropdown-basic"
                                        >
                                          <div className="pdf_right_box">
                                            <img src={Pdf_download_Img} />
                                          </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item href="#/action-1">
                                            Action
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-2">
                                            Another action
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-3">
                                            Something else
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </Tab>
                              <Tab eventKey="third" title="Other Details">
                                Hii, I am 3rd tab content
                              </Tab>
                            </Tabs>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

// const ref = useRef();
//   const { events } = useDraggable(ref);
// const ref = useRef(null);
// const { onMouseDown } = useDraggableScroll(ref);
// const [startDate, setStartDate] = useState(new Date());
