import React, { Component } from "react";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import {
  dashboard_user,
  dash_add_new,
  dashboard_total_machine_icon,
  dashboard_resell_machine_icon,
  dashboard_rented_machines_icon,
  dashboard_sold_machines_icon,
  Dash_view_img,
  req_equipment_icon,
  req_service_icon,
  req_parts_icon,
  req_hire_resource_icon,
  req_finance_icon,
  dashboard_top_banner,
  eye_icon,
  dashboard_right_banner,
  req_hmc_service_icon,
} from "../../assets/images";
import LeftPanelPage from "../../components/leftpanel/LeftPanelPage";
import axios from "../../config/axios";
import message from "../../utils/messages";
import { Collapse } from "react-collapse";
import "./dashboard.css";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import config from "../../config/apiHeader";
import moment from "moment";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      machines: {},
      profile: {},
      toggleEvent: 0,
      user_hash_id: "",
      allRequestList: [],
      collapsedArray: [],
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    let userdata = JSON.parse(localStorage.getItem("userdata"));

    if (userdata) {
      await this.setState({
        user_hash_id: userdata.hash_id,
      });
    }
    this.getPromotionalDetails();
    this.getAllRequest();
  };

  getPromotionalDetails = () => {
    let userdata = JSON.parse(localStorage.getItem("userdata"));

    axios
      .get(`/promotional?hash_id=${userdata.hash_id}`, config)
      .then((res) => {
        if (res.data.status === 1) {
          let { machines, profile } = res.data.data;
          this.setState({ profile, machines });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getAllRequest = () => {
    let formData = {
      user_hash_id: this.state.user_hash_id,
    };

    this.setState({ isLoading: true });

    axios
      .post("/request-management-list", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let allRequestList = [];
          let collapsedArray = [];
          let data = res.data.data;
          let request;
          let { loading, language } = this.props.language;

          if (!loading && language) {
            language = language.dashboard;
          } else {
            language = {};
          }

          for (let i = 0; i < Math.min(data.length, 4); i++) {
            let result = {};
            request = data[i];

            result.id = request.id;
            result.created_at = moment(request.created_at).format(
              "DD MMMM,YYYY"
            );
            result.brand_name = request.brand_name || request.brand_other;
            result.status = request.status;

            if (request.status === "0") {
              result.status_title = language.status_not_attended;
              result.status_cls = "not-attend";
            } else if (request.status === "1") {
              result.status_title = language.status_in_progress;
              result.status_cls = "in-progress";
            } else if (request.status === "2") {
              result.status_title = language.status_closed;
              result.status_cls = "closed";
            } else if (request.status === "3") {
              result.status_title = language.status_completed;
              result.status_cls = "complete";
            } else if (request.status === "4") {
              result.status_title = language.status_pending;
              result.status_cls = "pending";
            }

            if (request.request_for === "1") {
              result.requestFor = language.request_for_equipment;
              result.requestIcon = req_equipment_icon;
              result.eq_type_title = language.type_title_equipment_type;
              result.eq_type = this.getEquipment(request.equipment_type_name);
              if (request.equipment_for === "1") {
                result.requestType = language.request_type_new;
              } else if (request.equipment_for === "2") {
                result.requestType = language.request_type_used;
              } else if (request.equipment_for === "4") {
                result.requestType = language.request_type_rent;
              }
            } else if (request.request_for === "2") {
              result.requestFor = language.request_for_service;
              result.requestIcon = req_service_icon;
              result.eq_type_title = language.type_title_service_name;
              result.eq_type = this.getService(request.service_name);
              if (request.service_for === "1") {
                result.requestType = language.request_type_individual;
              } else if (request.service_for === "2") {
                result.requestType = language.request_type_workshop;
              }
            } else if (request.request_for === "3") {
              result.requestFor = language.request_for_parts;
              result.requestIcon = req_parts_icon;
              result.eq_type_title = language.type_title_parts_name;
              result.eq_type =
                request.parts_name || request.equipment_type_name;
              if (request.service_for === "1") {
                result.requestType = language.request_type_used;
              } else if (request.service_for === "2") {
                result.requestType = language.request_type_new;
              } else {
                result.requestType = language.request_type_new_and_used;
              }
            } else if (request.request_for === "4") {
              result.requestFor = language.request_for_hire_resources;
              result.requestIcon = req_hire_resource_icon;
              result.requestType = language.request_type_job;
              result.eq_type_title = language.type_title_skill_type;
              result.eq_type = this.getSkill(request.skill);
            } else if (request.request_for === "5") {
              result.requestFor = language.request_for_finance;
              result.requestIcon = req_finance_icon;
              result.requestType = language.request_type_finance;
              result.eq_type_title = "";
              result.eq_type = "";
            } else if (request.request_for === "6") {
              result.requestFor = language.request_for_hmc_service;
              result.requestIcon = req_hmc_service_icon;
              result.requestType = language.request_type_hmc_service;
              result.eq_type_title = "";
              result.eq_type = "";
            }
            allRequestList[i] = result;
            collapsedArray[i] = false;
          }
          this.setState({ allRequestList, collapsedArray });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Something went wrong!");
        console.error(err);
      });
  };

  getSkill = (skill) => {
    return skill.split(",")[0];
  };

  getService = (service) => {
    return service.split(",")[0];
  };

  getEquipment = (service) => {
    return service.split(",")[0];
  };

  render() {
    let { machines, profile, allRequestList, collapsedArray } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.dashboard;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                <Col lg="9" md="8" sm="12" className="ds_right">
                  <div className="dashboard_right_panel">
                    <h2>{language.dashboard_h}</h2>
                    <div className="dashboard_ad_partts">
                      <img src={dashboard_top_banner} className="img-fluid" />
                    </div>
                    {/* MY EQUIPMENT PART */}

                    <div className="dash_top_part">
                      <div className="dash_top_left_part">
                        <div className="prf_bg_dash">
                          <img alt="HMC" src={dashboard_user} />
                        </div>
                        <div className="dash_user_name">
                          <h3>{language.total_profiles}</h3>
                          <h4>{profile.total_profiles_value || 0}</h4>
                        </div>

                        <div className="dash_progressbar">
                          <div className="prf_completed">
                            <label>{language.profile_completed}</label>
                            <span>
                              {profile.total_profiles_value * 10 || 0}%
                            </span>
                          </div>
                          <ProgressBar
                            now={profile.total_profiles_value * 10}
                          />
                        </div>
                        <div className="dash_add_new">
                          <Link to="/profile">
                            <span>
                              <img alt="HMC" src={eye_icon} />
                            </span>
                            {language.view_profile}
                          </Link>
                          <a href="#">
                            <span>
                              <img alt="HMC" src={dash_add_new} />
                            </span>
                            {language.add_new}
                          </a>
                        </div>
                      </div>
                      <div className="dash_top_right_part">
                        <div className="dashtoprightheading">
                          <h3>{language.my_equipment}</h3>
                          <p>{language.my_equipment_desc}</p>
                        </div>
                        <div className="dash_top_right_bottom_part">
                          <EquipmentItem
                            icon={dashboard_total_machine_icon}
                            value={machines.total_machines_value || 0}
                            title={language.total_machine}
                            className="dash_icon_box1"
                          />
                          <EquipmentItem
                            icon={dashboard_resell_machine_icon}
                            value={machines.new_machines || 0}
                            title={language.resell_machine}
                            className="dash_icon_box2"
                          />
                          <EquipmentItem
                            icon={dashboard_rented_machines_icon}
                            value={machines.rent_request || 0}
                            title={language.rented_machines}
                            className="dash_icon_box3"
                          />
                          <EquipmentItem
                            icon={dashboard_sold_machines_icon}
                            value="0"
                            title={language.sold_machines}
                            className="dash_icon_box4"
                          />
                        </div>
                      </div>
                    </div>
                    {/* REQUEST HISTORY */}
                    <div className="dash_bottom_parttsss">
                      <Row>
                        <Col lg="9" md="9" sm="12">
                          <div className="request_history">
                            <div className="request_heading_view">
                              <h2>{language.request_history_h}</h2>
                              <div className="dash_view_more">
                                <Link to="/request-management">
                                  <img alt="HMC" src={Dash_view_img} />{" "}
                                  {language.view_more_btn}
                                </Link>
                              </div>
                            </div>
                            {allRequestList && allRequestList.length > 0 && (
                              <div className="table_part_request">
                                <div className="request_table_top">
                                  <div className="table_top_inner">
                                    {language.request_for}
                                  </div>
                                  <div className="table_top_inner">
                                    {language.request_id}
                                  </div>
                                  <div className="table_top_inner">
                                    {language.date}
                                  </div>
                                  <div className="table_top_inner">
                                    {language.status}
                                  </div>
                                </div>

                                {allRequestList.map((request, key) => {
                                  return (
                                    <div
                                      className="equipment_part_table"
                                      key={key}
                                    >
                                      <div className="eq_part_table_top">
                                        <div className="req_equipment">
                                          <div className="requser">
                                            <img
                                              alt="HMC"
                                              src={request.requestIcon}
                                            />
                                          </div>
                                          <label>{request.requestFor}</label>
                                        </div>
                                        <div className="req_equipment">
                                          <label>#{request.id}</label>
                                        </div>
                                        <div className="req_equipment">
                                          <label>{request.created_at}</label>
                                        </div>
                                        <div className="req_equipment compl">
                                          <span className={request.status_cls}>
                                            {request.status_title}
                                          </span>

                                          <i
                                            className={`${
                                              collapsedArray[key]
                                                ? "fa fa-angle-down"
                                                : "fa fa-angle-right"
                                            } active`}
                                            onClick={() => {
                                              let cc = collapsedArray;
                                              cc[key] = !cc[key];
                                              this.setState({
                                                collapsedArray: cc,
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>

                                      <Collapse isOpened={collapsedArray[key]}>
                                        <div className="my-collapsible">
                                          <div className="my-collapsible__content">
                                            <div className="my-collapsible__content-inner">
                                              <div className="type_div">
                                                <div className="type_inner_div">
                                                  <label>{language.type}</label>
                                                  <span>
                                                    {request.requestType}
                                                  </span>
                                                </div>
                                                <div className="type_inner_div">
                                                  <label>
                                                    {language.model}
                                                  </label>
                                                  <span>R85</span>
                                                </div>
                                                <div className="type_inner_div">
                                                  <label>
                                                    {request.eq_type_title}
                                                  </label>
                                                  <span>{request.eq_type}</span>
                                                </div>
                                                <div className="type_inner_div">
                                                  <label>
                                                    {language.brand}
                                                  </label>
                                                  <span>
                                                    {request.brand_name}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Collapse>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg="3" md="3" sm="12">
                          <div className="dashboard_right_ad">
                            <img src={dashboard_right_banner} alt="HMC" />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}

class EquipmentItem extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { icon, className, value, title } = this.props;
    return (
      <div className="dash_inner_bottom_part">
        <div className="dash_inner_bottom_inner_part">
          <div className={className}>
            <img alt="HMC" src={icon} />
          </div>
          <div className="dash_right_text">
            <h2>{value}</h2>
            <label>{title}</label>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps)(Dashboard);
