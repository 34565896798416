import React, { Component } from "react";
import axios from "../config/axios";
import message from "../utils/messages";
import LoadingOverlay from "react-loading-overlay";
import { Helmet } from "react-helmet";
import Seo from "../components/seo/Seo";

export default class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,

      content: "",

      footer_faq_data: [],
      seo_data: {},
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getMetaData();
    this.getPrivacyContent();
  }

  getMetaData = () => {
    axios
      .get(
        `/web-seo-content?page_key=privacy_policy&call_from=page&lang_type=${localStorage.getItem(
          "language"
        )}`
      )
      .then((res) => {
        if (res.data.status === "1") {
          let { seo_data, footer_faq_data } = res.data.data;
          this.setState({ seo_data, footer_faq_data });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getPrivacyContent = () => {
    this.setState({ isLoading: true });

    axios
      .post("/web-cms-content", { cms_type: "privacy" })
      .then((res) => {
        this.setState({ isLoading: false });

        if (res.data.status === "1") {
          if (res.data.data) {
            let { text } = res.data.data;

            this.setState({ content: text });
          } else {
            message.error("No data found");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  render() {
    let { content, seo_data } = this.state;
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        {seo_data && (
          <Helmet>
            <title>{seo_data.meta_title}</title>
            <meta name="description" content={seo_data.meta_description} />
            <meta name="keywords" content={seo_data.meta_key} />
            <link rel="canonical" href={seo_data.canonical_url} />
          </Helmet>
        )}
        <div
          className="container mt-3 mb-5"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <Seo {...this.state} />
      </LoadingOverlay>
    );
  }
}
