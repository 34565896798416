import { toast } from "react-toastify";
import { logo_img } from "../assets/images";
toast.configure();

const notificationClick = (data) => {
  const { origin, href } = window.location;
  // console.log("window.location.href", window.location.href);
  // console.log("window.location.hostname", window.location.hostname);
  // console.log("window.location.pathname ", window.location.pathname);
  // console.log("window.location.protocol ", window.location.protocol);
  // console.log("window.location.host ", window.location.host);
  // console.log("window.location.origin ", window.location.origin);

  let url = "";
  switch (data.type) {
    case "my_assigned_list":
      if (data.call_from && data.call_from === "my_assigned_list") {
        url = `${origin}/feedback-history/${data.lead_id}/${data.lead_assignee_activity_id}/${data.call_from}/${data.dashboard_id}`;
        break;
      }
      url = `${origin}/my-assigned-lead`;
      break;
    case "assigned_list":
      url = `${origin}/feedback-history/${data.lead_id}/${data.lead_assignee_activity_id}/${data.call_from}/${data.dashboard_id}`;
      break;
    default:
      url = `${origin}/dashboard`;
      break;
  }
  if (href.includes("feedback-history") && url.includes("feedback-history")) {
    window.location.replace(url);
  } else {
    window.open(url);
  }
};

const MyMsg = (title, body, data) => (
  <div
    className="my_message"
    onClick={() => {
      notificationClick(data);
    }}
  >
    <div className="my_message_image">
      <img src={logo_img} alt={title} />
    </div>
    <div className="mesage_text">
      <h3>{title}</h3>
      <p>{body}</p>
    </div>
  </div>
);

export const showNotification = (title, body, data) => {
  toast(MyMsg(title, body, data), {
    position: toast.POSITION.TOP_RIGHT,
  });
  localStorage.setItem("notification_lead_details", data.feedback_history_data);
};
