import React, { Component } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import Geocode from "react-geocode";
import message from "./messages";
import { GeocodeApiKey } from "./SecretKey";
import { Location_shape_img } from "../assets/images";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};
class AutoCompleteLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: "",
      longitude: "",
      value: "",
      placeholder: "Type Location",
    };
  }

  componentDidMount = () => {
    Geocode.setApiKey(GeocodeApiKey());
  };

  getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (place) => {
          place = place.results[0];

          if (place.address_components !== undefined) {
            let { country, state, city } = this.getAddress(place);

            let address = {
              country,
              state,
              city,
              lat: position.coords.latitude,
              long: position.coords.longitude,
              label: place.formatted_address,
            };
            this.props.handleGetLocation &&
              this.props.handleGetLocation(address);
          }
        },
        (error) => {
          console.error(error);
          message.error(error.message);
        }
      );
    });
  };

  compIsType = (t, s) => {
    for (let z = 0; z < t.length; ++z) if (t[z] === s) return true;
    return false;
  };

  onSelect = (data) => {
    geocodeByAddress(data.label)
      .then((place) => {
        place = place[0];
        if (place.address_components !== undefined) {
          let { country, state, city } = this.getAddress(place);

          getLatLng(place)
            .then((latLng) => {
              let address = {
                country,
                state,
                city,
                lat: latLng.lat,
                long: latLng.lng,
                label: place.formatted_address,
              };

              this.props.handleGetLocation &&
                this.props.handleGetLocation(address);
            })
            .catch((error) => console.error("Error", error));
        }
      })
      .catch((error) => console.error("Error", error));
  };

  getAddress = (place) => {
    let country,
      state,
      city = null;
    place.address_components.forEach((addrComp) => {
      let typ = addrComp.types;
      if (this.compIsType(typ, "administrative_area_level_1"))
        state = addrComp.long_name; //store the state
      else if (this.compIsType(typ, "locality"))
        city = addrComp.long_name; //store the city
      else if (this.compIsType(typ, "country"))
        country = addrComp.long_name; //store the country
      else if (!city && this.compIsType(typ, "administrative_area_level_3"))
        city = addrComp.long_name; //store the city if locality not found
    });
    return { state, city, country };
  };

  render() {
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.basic_profile;
    } else {
      language = {};
    }

    return (
      <div className="ser_loc">
        <GooglePlacesAutocomplete
          autocompletionRequest={{
            bounds: [
              { lat: 50, lng: 50 },
              { lat: 100, lng: 100 },
            ],
          }}
          selectProps={{
            value: this.state.value,
            onChange: this.onSelect,
            placeholder: this.props.value || language.type_location_plh,
          }}
          required
        />
        <span className="location_shape_img">
          <img
            onClick={this.getCurrentLocation}
            src={Location_shape_img}
            alt="Current Location"
          />
        </span>
      </div>
    );
  }
}
export default connect(mapStateToProps)(AutoCompleteLocation);
