import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import axios from "../../config/axios";
import "./products.css";
import message from "../../utils/messages";
import config from "../../config/apiHeader";
import { Link } from "react-router-dom";
import EquipmentItems from "../allEquipment/EquipmentItems";
import Seo from "../../components/seo/Seo";
import { Helmet } from "react-helmet";
import { stringReplace } from "../../utils/Helper";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};
class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isUsedEquipment: true,
      hash_id: "",
      currentPage: 1,
      total: 0,
      per_page: 10,
      last_page: 0,

      machine_types: [],
      selected_machine_types: [],
      brand: [],
      // selected_brand: [],
      machine_activity: [],
      machine_count: 0,
      brand_count: 0,

      // QUERY FILTER
      machine_type: "",
      selected_brand: "",

      footer_faq_data: [],
      seo_data: {},
      title: "",

      machine: "",
      value: "",
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    this.setLoading(true);

    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }

    let { params } = this.props.match;
    if (params) {
      let { name, id } = params;
      if (id) {
        let machine = id.substring(0, 1);
        let value = id.substring(1);

        let { machine_type, selected_brand, title } = this.state;

        if (machine === "B") {
          selected_brand = value;
        } else {
          machine_type = value;
        }

        if (name) {
          title = stringReplace(name, "-", " ");
        }

        this.setState(
          { machine_type, selected_brand, title, value, machine },
          () => {
            this.getAllEquipments();
            this.getMetaData();
          }
        );
      } else {
        this.getAllEquipments();
      }
    } else {
      this.getAllEquipments();
    }
  };

  getMetaData = () => {
    let { value, machine } = this.state;
    axios
      .get(
        `/web-seo-content?call_from=${
          machine === "M"
            ? `machine_type_id&machine_type_id=${value}`
            : `brand&brand_id=${value}`
        }&lang_type=${localStorage.getItem("language")}`
      )
      .then((res) => {
        if (res.data.status === "1") {
          let { seo_data, footer_faq_data } = res.data.data;
          this.setState({ seo_data, footer_faq_data });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  setLoading = async (isLoading) => {
    await this.setState({ isLoading });
    if (isLoading) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!isLoading) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  };

  getAllEquipments = () => {
    this.setState({ isLoading: true });
    let { currentPage, selected_brand, machine_type } = this.state;

    const formData = {
      page: currentPage,
      machine_type,
      brand: selected_brand,
    };

    axios
      .post("/latest-equipment-list", formData, config)
      .then((res) => {
        this.setLoading(false);

        if (res.data.status === "1") {
          let { machine_activity, total, last_page } = res.data.data;
          this.setState({
            machine_activity,
            total,
            last_page,
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setLoading(false);
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  render() {
    let { machine_activity, seo_data } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.equipments;
    } else {
      language = {};
    }
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          {seo_data && (
            <Helmet>
              <title>{seo_data.meta_title}</title>
              <meta name="description" content={seo_data.meta_description} />
              <meta name="keywords" content={seo_data.meta_key} />
              <link rel="canonical" href={seo_data.canonical_url} />
            </Helmet>
          )}

          <section className="inner_prdtsss">
            <Container>
              <div className="find_machine rent">
                <div className="find_machine_heading">
                  <h1>
                    {language.equipments} - {this.state.title}{" "}
                  </h1>
                </div>

                <div className="find_machine_inner">
                  <div className="select_brandds">
                    <div className="avalaible_matching">
                      <h2>
                        {this.state.total} {language.machine_available}
                      </h2>

                      <Row>
                        <EquipmentItems machine_activity={machine_activity} />
                      </Row>

                      <div className="showing_result text-center d-block">
                        <Link
                          to="/all-equipment"
                          className="submit_button commn_button"
                        >
                          {language.view_more_btn}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section>
          <Seo {...this.state} />
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(Products);
