import { createStore, combineReducers, applyMiddleware } from "redux";
import { LanguageReducer } from "../reducre/LanguageReducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { CountryReducer } from "../reducre/CountryReducer";

const reducer = combineReducers({
  language: LanguageReducer,
  country_code: CountryReducer,
});

const initialState = {};
const middleware = [thunk];

export const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
