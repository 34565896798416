import React from "react";
import chroma from "chroma-js";
import Select from "react-select";

export default function MultiSelectTags(props) {
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.7).css(),
        // backgroundColor: data.color, // DEFAULT COLOR
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.text_color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      // color: data.color,
      color: "white", // DEFAULT COLOR
      ":hover": {
        backgroundColor: data.color,
        color: "white",
      },
    }),
  };
  return (
    <Select
      closeMenuOnSelect={false}
      defaultValue={props.defaultTag}
      isMulti
      options={props.tags}
      styles={colourStyles}
      placeholder={props.placeholder}
      onChange={props.onChange}
    />
  );
}
