import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import "./projectlisting.css";
import { default_project_preview } from "../../assets/images";
import axios from "../../config/axios";
import message from "../../utils/messages";
import LoadingOverlay from "react-loading-overlay";
import { MEDIA_BASE_URL } from "../../utils/Helper";
import moment from "moment";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class ProjectListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hash_id: "",
      currentPage: 1,
      total: 0,
      per_page: 20,

      project_list: [],
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }
    this.getAllProjects();
  };

  getAllProjects = () => {
    this.setState({ isLoading: true });
    let { hash_id, currentPage } = this.state;
    const config = {
      headers: {
        api_token: localStorage.getItem("api-token"),
        Authorization: hash_id,
      },
    };

    const formData = {
      page: currentPage,
      medium: "web",
      section_name: "project_get_all_projects",
      sector_id: this.props.match.params.sector_id,
    };

    axios
      .post("/project-list", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });

        if (res.data.status === "1") {
          let { project_list, total, last_page } = res.data.data;
          this.setState({
            project_list: this.state.project_list.concat(project_list),
            total,
            last_page,
            currentPage: currentPage + 1,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };
  render() {
    let { project_list } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.projects;
    } else {
      language = {};
    }
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="proj_listing">
          <Container>
            <div className="prj_inner_listing1">
              <h2>{language.project}</h2>
              <div className="prj_listing_inner">
                {project_list.map((project, key) => {
                  return (
                    <div className="prj_listing_partts" key={key}>
                      <div className="prj_listing_left">
                        <img
                          src={
                            project.images.length > 0
                              ? MEDIA_BASE_URL + project.images[0].url
                              : default_project_preview
                          }
                          className="img-fluid"
                          alt={project.project_name}
                        />
                      </div>
                      <div className="prj_listing_right">
                        <div className="prj_inner_listing_right">
                          <h2>{project.project_name}</h2>
                          <p>{project.project_des}</p>
                          <div className="locat_create_at">
                            <div className="locate_one">
                              <label>
                                {language.location_label}:{" "}
                                <span>
                                  <b>{project.city}</b>
                                </span>
                              </label>
                            </div>
                            <div className="locate_one">
                              <label>
                                {language.created_at_label}:{" "}
                                <span>
                                  <b>
                                    {moment(project.created_at).format(
                                      "YYYY-MM-DD"
                                    )}
                                    <span className="locate_time">
                                      {moment(project.created_at).format(
                                        "hh:mm:ss a"
                                      )}
                                    </span>
                                  </b>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="showing_result">
                <p>
                  {language.pagination_showing_result_label}{" "}
                  {Math.min(
                    this.state.total,
                    (this.state.currentPage - 1) * this.state.per_page
                  )}{" "}
                  {language.pagination_out_of_label} {this.state.total}{" "}
                </p>
                <Button
                  className="submit_button commn_button"
                  type="submit"
                  disabled={this.state.currentPage - 1 >= this.state.last_page}
                  onClick={this.getAllProjects}
                >
                  {language.view_more_btn}
                </Button>
              </div>
            </div>
          </Container>
        </div>
      </LoadingOverlay>
    );
  }
}

export default connect(mapStateToProps)(ProjectListing);
