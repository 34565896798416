import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import "./popup.css";

export default class ExitPopup extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        backdrop="static"
        centered={true}
      >
        <Modal.Body>
          <h5>{this.props.message}</h5>
          <div className="delete_style">
            <Button
              className="add_another"
              type="button"
              onClick={this.props.add}
            >
              {this.props.add_title}
            </Button>
            <Button
              className="back_to_home"
              type="button"
              onClick={this.props.back}
            >
              {this.props.back_title}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
ExitPopup.propTypes = {
  message: PropTypes.string.isRequired,
  add_title: PropTypes.string.isRequired,
  back_title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  add: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
};
