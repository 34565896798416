import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import Layout from "./pages/layout";
import PrivacyPolicy from "./agreement/PrivacyPolicy";
import Products from "./pages/product/Products";
import ChatPage from "./pages/chat/ChatPage";
import EditEquipment from "./pages/addEquipment/editequipment/EditEquipment";
import { Helmet } from "react-helmet";
import HomePage from "./pages/home/HomePage";
import ContactUsPage from "./pages/contact/ContactUsPage";
import ThankYouPage from "./pages/aboutus/ThankYouPage";
import AboutUsPage from "./pages/aboutus/AboutUsPage";
import Signup from "./pages/auth/registration/Signup";
import Dashboard from "./pages/dashboard/Dashboard";
import TermsAndCondition from "./agreement/TermsAndCondition";
import MobileLogin from "./pages/auth/login/MobileLogin";
import EmailLogin from "./pages/auth/login/EmailLogin";
import AddEquipmentPage from "./pages/addEquipment/AddEquipmentPage";
import AddEquipmentTwo from "./pages/addEquipment/AddEquipmentTwo";
import LeadDetailsPage from "./pages/leaddetails/leaddetails";
import MyLead from "./pages/leadmanagement/MyLead";
import AddLead from "./pages/leadmanagement/addLead/AddLead";
import TagListing from "./pages/leadmanagement/tag/TagListing";
import EditLead from "./pages/leadmanagement/edit/EditLead";
import AllLead from "./pages/leadmanagement/allLead/AllLead";
import Finance from "./pages/Finance/Finance";
import FeedBackHistory from "./pages/leadmanagement/feedback/FeedBackHistory";
import MyAssignLead from "./pages/leadmanagement/myAssignLead/MyAssignLead";
import Project from "./pages/project/Project";
import ProjectListing from "./pages/project/ProjectListing";
import ServiceProvider from "./components/serviceProvider/ServiceProvider";
import TimeLine from "./pages/leadmanagement/timeline/Timeline";
import AllEquipments from "./pages/allEquipment/AllEquipments";
import EquipmentDetails from "./pages/allEquipment/EquipmentDetails";
import MyArchiveLead from "./pages/leadmanagement/archive/MyArchiveLead";
import MyNotification from "./pages/my-notifications/MyNotification";
import Equipments from "./pages/allEquipment/Equipments";
import ExpertConnect from "./components/ExpertAdvice/expert-connect/ExpertConnect";
import MyEquipments from "./pages/addEquipment/MyEquipments/MyEquipments";
import Profile from "./pages/profile/Profile";
import EditProfile from "./pages/profile/edit-profile/EditProfile";
import PaymentInvoice from "./pages/leadmanagement/payment-invoice/PaymentInvoice";
import EditBasicProfile from "./pages/profile/edit-profile/EditBasicProfile";
import RequestManagement from "./pages/request-menagement/RequestManagement";
import ReturnRefundCancellationPolicy from "./agreement/ReturnRefundCancellationPolicy";
import Disclaimer from "./agreement/Disclaimer";
import IntellectualProperty from "./agreement/IntellectualProperty";
import Parts from "./pages/partsdealer/Parts";
import PartsDetails from "./pages/partsdealer/PartsDetails";
import JobSeeker from "./pages/job-seeker/JobSeeker";
import NotFound from "./NotFound";
import TotalServiceProvider from "./pages/home/TotalServiceProvider";
import TotalPartDealers from "./pages/home/TotalPartDealers";
import TotalJobSeeker from "./pages/home/TotalJobSeeker";
import TotalRentEquipment from "./pages/home/TotalRentEquipment";
import TotalResaleEquipment from "./pages/home/TotalResaleEquipment";
import RemoveAccount from "./pages/auth/removeaccount/RemoveAccount";
import ImageProcessing from "./pages/leadmanagement/payment-invoice/ImageProcessing";

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  const local_language = localStorage.getItem("language");

  let { url, params } = rest.computedMatch;
  let { lang } = params;

  if (lang) {
    lang = lang.split("-");
    if (lang[0] !== "lang") {
      lang = null;
    } else {
      lang = lang[1];
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {!local_language || local_language === "en" ? (
            <>
              {lang ? (
                <>
                  <Redirect to={url.substring(0, url.lastIndexOf("/"))} />
                </>
              ) : (
                <>
                  <Layout {...props}>
                    <Component {...props} />
                  </Layout>
                </>
              )}
            </>
          ) : (
            <>
              {!lang ? (
                <Redirect
                  to={`${url}${url === "/" ? "" : "/"}lang-${local_language}`}
                />
              ) : (
                <>
                  {lang === local_language ? (
                    <Layout {...props}>
                      <Component {...props} />
                    </Layout>
                  ) : (
                    <NotFound />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    />
  );
}

function RouteProtect({ component: Component, layout: Layout, ...rest }) {
  const local_language = localStorage.getItem("language");

  let { url, params } = rest.computedMatch;
  let { lang } = params;

  if (lang) {
    lang = lang.split("-")[1];
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("api-token") ? (
          <>
            {!local_language || local_language === "en" ? (
              <>
                {lang ? (
                  <Redirect to={url.substring(0, url.lastIndexOf("/"))} />
                ) : (
                  <Layout {...props}>
                    <Component {...props} />
                  </Layout>
                )}
              </>
            ) : (
              <>
                {!lang ? (
                  <Redirect to={`${url}/lang-${local_language}`} />
                ) : (
                  <>
                    {lang === local_language ? (
                      <Layout {...props}>
                        <Component {...props} />
                      </Layout>
                    ) : (
                      <NotFound />
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Buy, Sell, Rent Used Construction Heavy Equipment in India | HMC
          </title>
          <link rel="canonical" href="https://www.heavymachinerycare.com/" />
          <meta
            name="description"
            content="India's trustworthy online connecting place to buy, sell, rent used construction equipment or find spare parts & hire manpower with free registration."
          />
          {/* <meta name="robots" content="index, follow" /> */}
          {/* <meta
            name="keywords"
            content="India's trustworthy online connecting place to buy, sell, rent used construction equipment or find spare parts & hire manpower with free registration."
          /> */}
        </Helmet>
        <Switch>
          <RouteWrapper
            path="/signup/:email/:temp_id/:lang?"
            exact
            component={Signup}
            layout={Layout}
          />
          <RouteWrapper
            path="/login/:lang?"
            exact
            component={MobileLogin}
            layout={Layout}
          />
          <RouteWrapper
            path="/email-login/:lang?"
            exact
            component={EmailLogin}
            layout={Layout}
          />
          <RouteProtect
            path="/dashboard/:lang?"
            exact
            component={Dashboard}
            layout={Layout}
          />
          <RouteWrapper
            path="/contact-us/:lang?"
            exact
            component={ContactUsPage}
            layout={Layout}
          />
          <RouteProtect
            path="/profile/:lang?"
            exact
            component={Profile}
            layout={Layout}
          />
          <RouteProtect
            path="/edit-profile/:hash_id/:profile_type/:lang?"
            exact
            component={EditProfile}
            layout={Layout}
          />
          <RouteProtect
            path="/basic-profile/:hash_id/:profile_type/:lang?"
            exact
            component={EditBasicProfile}
            layout={Layout}
          />

          {/* <RouteProtect
            path="/document-upload"
            exact
            component={DocumentUpload}
            layout={Layout}
          /> */}
          <RouteWrapper
            path="/privacy-policy/:lang?"
            exact
            component={PrivacyPolicy}
            layout={Layout}
          />
          <RouteWrapper
            path="/return-refund-cancellation-policy/:lang?"
            exact
            component={ReturnRefundCancellationPolicy}
            layout={Layout}
          />
          <RouteWrapper
            path="/disclaimer/:lang?"
            exact
            component={Disclaimer}
            layout={Layout}
          />
          <RouteWrapper
            path="/intellectual-property/:lang?"
            exact
            component={IntellectualProperty}
            layout={Layout}
          />
          <RouteWrapper
            path="/terms-conditions/:lang?"
            exact
            component={TermsAndCondition}
            layout={Layout}
          />
          {/* <RouteProtect
            path="/machine-list"
            exact
            component={MachineList}
            layout={Layout}
          /> */}
          <RouteWrapper
            path="/product/:lang?"
            exact
            component={Equipments}
            layout={Layout}
          />
          <RouteWrapper
            path="/product/:name/:id/:lang?"
            exact
            component={Products}
            layout={Layout}
          />
          <RouteProtect
            path="/service-provider/:lang?"
            exact
            component={ServiceProvider}
            layout={Layout}
          />
          <RouteProtect
            path="/parts/:lang?"
            exact
            component={Parts}
            layout={Layout}
          />

          <RouteProtect
            path="/parts-dealer-details/:profile_hash_id/:lang?"
            exact
            component={PartsDetails}
            layout={Layout}
          />
          <RouteWrapper
            path="/about-us/:lang?"
            exact
            component={AboutUsPage}
            layout={Layout}
          />
          <RouteWrapper
            path="/expert-connect/:lang?"
            exact
            component={ExpertConnect}
            layout={Layout}
          />
          <RouteProtect
            path="/job-seeker/:lang?"
            exact
            component={JobSeeker}
            layout={Layout}
          />
          <RouteProtect
            path="/request-management/:lang?"
            exact
            component={RequestManagement}
            layout={Layout}
          />
          <RouteProtect
            path="/add-equipment/:lang?"
            exact
            component={AddEquipmentPage}
            layout={Layout}
          />
          <RouteProtect
            path="/add-equipment-two/:lang?"
            exact
            component={AddEquipmentTwo}
            layout={Layout}
          />

          <RouteProtect
            path="/edit-equipment/:machine_activity_hash_id/:lang?"
            exact
            component={EditEquipment}
            layout={Layout}
          />

          <RouteProtect
            path="/my-equipments/:lang?"
            exact
            component={MyEquipments}
            layout={Layout}
          />
          {/* <RouteProtect
            path="/chat"
            exact
            component={ChatPage}
            layout={Layout}
          /> */}

          <RouteWrapper
            path="/thankyou/:lang?"
            exact
            component={ThankYouPage}
            layout={Layout}
          />

          <RouteProtect
            path="/add-lead/:lang?"
            exact
            component={AddLead}
            layout={Layout}
          />

          {/* <RouteProtect
            path="/my-lead"
            exact
            component={MyLead}
            layout={Layout}
          /> */}

          <RouteProtect
            path="/tag-listing/:lang?"
            exact
            component={TagListing}
            layout={Layout}
          />

          <RouteProtect
            path="/my-assigned-lead/:lang?"
            exact
            component={MyAssignLead}
            layout={Layout}
          />

          {/* <RouteProtect
            path="/myleaddetails"
            exact
            component={LeadDetailsPage}
            layout={Layout}
          /> */}
          <RouteWrapper
            path="/all-leads/:lang?"
            exact
            component={AllLead}
            layout={Layout}
          />
          <RouteProtect
            path="/edit-lead/:lead_hash_id/:lang?"
            exact
            component={EditLead}
            layout={Layout}
          />
          <RouteProtect
            path="/projects/:lang?"
            exact
            component={Project}
            layout={Layout}
          />
          <RouteProtect
            path="/projects/:sector_name/:sector_id/:lang?"
            exact
            component={ProjectListing}
            layout={Layout}
          />

          <RouteWrapper
            path="/finance/:lang?"
            exact
            component={Finance}
            layout={Layout}
          />

          {/* <RouteProtect
            path="/daily-report/:lead_hash_id/:lead_assigne_activity_id/:lead_id"
            exact
            component={DailyReport}
            layout={Layout}
          /> */}
          <RouteProtect
            path="/feedback-history/:lead_id/:lead_assignee_act_id/:call_from/:dashboard_id?/:lang?"
            exact
            component={TimeLine}
            layout={Layout}
          />

          <RouteProtect
            path="/all-equipment/:lang?"
            exact
            component={AllEquipments}
            layout={Layout}
          />
          <RouteProtect
            path="/equipment-details/:machine_activity_hash_id/:machine_hash_id/:profile_hash_id/:lang?"
            exact
            component={EquipmentDetails}
            layout={Layout}
          />
          <RouteProtect
            path="/my-archived-lead/:lang?"
            exact
            component={MyArchiveLead}
            layout={Layout}
          />
          <RouteProtect
            path="/my-notification/:lang?"
            exact
            component={MyNotification}
            layout={Layout}
          />
          <RouteProtect
            path="/payment-invoice/:lead_hash_id/:lang?"
            exact
            component={PaymentInvoice}
            layout={Layout}
          />

          <RouteWrapper
            path="/total-service-provider/:lang?"
            exact
            component={TotalServiceProvider}
            layout={Layout}
          />

          <RouteWrapper
            path="/total-parts-dealer/:lang?"
            exact
            component={TotalPartDealers}
            layout={Layout}
          />
          <RouteWrapper
            path="/total-job-seeker/:lang?"
            exact
            component={TotalJobSeeker}
            layout={Layout}
          />
          <RouteWrapper
            path="/total-rent-equipment/:lang?"
            exact
            component={TotalRentEquipment}
            layout={Layout}
          />
          <RouteWrapper
            path="/total-resale-equipment/:lang?"
            exact
            component={TotalResaleEquipment}
            layout={Layout}
          />
          <RouteWrapper
            path="/remove-account/:lang?"
            exact
            component={RemoveAccount}
            layout={Layout}
          />
          <RouteWrapper
            path="/image-processing/:lang?"
            exact
            component={ImageProcessing}
            layout={Layout}
          />

          {/* ALL single url (/contact-us, /about-us etc) redirect to home page thats why Home page url is end of the route */}
          <RouteWrapper
            path="/:lang?"
            exact
            component={HomePage}
            layout={Layout}
          />

          {/* <Redirect to="/" /> */}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
