import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "./projects.css";
import { projects_banner } from "../../assets/images";
import LoadingOverlay from "react-loading-overlay";
import axios from "../../config/axios";
import message from "../../utils/messages";
import { Link } from "react-router-dom";
import { MEDIA_BASE_URL } from "../../utils/Helper";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};
class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hash_id: "",
      currentPage: 1,
      sector_list: [],
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }
    this.getAllSectors();
  };

  getAllSectors = () => {
    this.setState({ isLoading: true });
    let { hash_id, currentPage } = this.state;
    const config = {
      headers: {
        api_token: localStorage.getItem("api-token"),
        Authorization: hash_id,
      },
    };

    const formData = {
      page: currentPage,
      medium: "web",
      section_name: "project_get_all_sectors",
      sector_id: 1,
    };

    axios
      .post("/all-project-sector-list", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });

        if (res.data.status === "1") {
          let { sector_list } = res.data.data;
          this.setState({ sector_list });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  render() {
    let { sector_list } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.projects;
    } else {
      language = {};
    }
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <div className="projects_top_banner">
            <div className="projects_bannerer">
              <img src={projects_banner} alt="Project Banner" />
              <div className="projets_banner_heading">
                <h2>{language.project}</h2>
              </div>
            </div>
          </div>

          <div className="proj_listing">
            <Container>
              <div className="prj_inner_listing">
                {sector_list &&
                  sector_list.map((sector, key) => {
                    return (
                      <div className="prj_inner_inner_listing" key={key}>
                        <Link
                          to={`/projects/${sector.sector_name}/${sector.id}`}
                        >
                          <div className="pjr_listing_part">
                            <div className="prj_listing_image">
                              <img
                                src={MEDIA_BASE_URL + sector.sector_image}
                                alt="HMC"
                              />
                              <div className="prj_cont">
                                <h2>{sector.sector_name}</h2>
                                <p>{language.total_projects}: {sector.total_project}</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </Container>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default connect(mapStateToProps)(Project);
