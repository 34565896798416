import React, { Component } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import LeftPanelPage from "../../components/leftpanel/LeftPanelPage";
import {
  hmc_user_onboard,
  Lead_Update_Img,
  Lead_chat_Img,
  Pencil1_Img,
  Lead_Arrow_Img,
  Setting_Filter_Img,
  Delete_Icon_Img,
} from "../../assets/images";
import "./mylead.css";
import ReactTooltip from "react-tooltip";
import ReactPaginate from "react-paginate";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import axios from "../../config/axios";
import message from "../../utils/messages";
import moment from "moment";
import _ from "lodash";
import AdvanceFilter from "./AdvanceFilter";
import DeletePopup from "../../utils/DeletePopup";
import { LeadTypes } from "../../utils/LeadTypes";

export default class MyLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      totalPage: 0,
      per_page: 10,
      currentPage: 1,
      totalItems: 0,
      clickedPosition: -1,

      // FILTER
      customer_name: "",
      start_date: "",
      end_date: "",
      lead_id: "",
      luid: [],
      customer_address_state: "",
      customer_address_city: "",
      euipment_category: "",
      temp_euipment_name: "",
      euipment_name: [],
      tags_id: [],
      selected_tags: [],
      finance_type: "",

      my_lead: [],
      all_tags: [],
      state_list: [],
      toggleEvent: false,
      collapsedArray: [],

      isFiltered: false,
      isDelete: false,
      delete_id: "",
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }
    this.getAllLeads();
    this.getAllTagList();
    this.getAllStateList();
  };

  getAllLeads = () => {
    this.setState({ isLoading: true });

    let {
      hash_id,
      currentPage,
      per_page,
      start_date,
      end_date,
      customer_name,
      customer_address_city,
      customer_address_state,
      euipment_category,
      tags_id,
      finance_type,
    } = this.state;

    const config = {
      headers: {
        api_token: localStorage.getItem("api-token"),
        Authorization: hash_id,
      },
    };

    const formData = {
      hash_id,
      per_page,
      page: currentPage,
      medium: "web",
      section_name: "lm_get_all_leads",

      start_date,
      end_date,
      customer_name,
      luid: this.state.luid.toString(),
      customer_address_city,
      customer_address_state,
      euipment_category,
      euipment_name: this.state.euipment_name.toString(),
      tags_id: tags_id.toString(),
      finance_type,
    };

    axios
      .post("/get-my-lead", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { my_lead, last_page, total } = res.data.data;
          let size = my_lead.length;
          let collapsedArray = _.fill(Array(size), this.state.isFiltered);
          this.setState({
            my_lead,
            totalPage: last_page,
            collapsedArray,
            totalItems: total,
          });
          if (total === 0) {
            message.warning("No Lead Found!!");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  getAllTagList = () => {
    let { hash_id } = this.state;
    const config = {
      headers: {
        api_token: localStorage.getItem("api-token"),
        Authorization: hash_id,
      },
    };

    const formData = {
      hash_id,
      medium: "web",
      section_name: "lm_get_all_tags",
    };

    axios
      .post("/all-tag-list-status-wise", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let all_tags = [];
          res.data.data.map((tag) => {
            return all_tags.push({
              label: tag.tag_title,
              value: tag.id,
              color: tag.tag_back_color,
              text_color: tag.tag_title_color,
            });
          });
          this.setState({ all_tags });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        // message.error("Something went wrong!!");
      });
  };

  getAllStateList = () => {
    let { hash_id } = this.state;
    const config = {
      headers: {
        api_token: localStorage.getItem("api-token"),
        Authorization: hash_id,
      },
    };

    const formData = {
      medium: "web",
      section_name: "lm_get_all_state",
    };

    axios
      .post("/state-list", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          let { state_list } = res.data.data;

          this.setState({ state_list });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        // message.error("Something went wrong!!");
      });
  };

  onPageChange = async (val) => {
    let currentPage = val.selected + 1;
    await this.setState({ currentPage });
    this.getAllLeads();
  };

  handleOnChange = async (e) => {
    e.persist();
    let { name, value } = e.target;

    await this.setState({ [name]: value });

    if (name === "per_page") {
      await this.setState({ currentPage: 1 });
      this.getAllLeads();
    }
  };

  removeLuid = (index) => {
    let { luid } = this.state;
    luid.splice(index, 1);
    this.setState({ luid });
  };

  removeEquipment = (index) => {
    let { euipment_name } = this.state;
    euipment_name.splice(index, 1);
    this.setState({ euipment_name });
  };

  onToggle = () => {
    this.setState({ toggleEvent: !this.state.toggleEvent });
  };

  deleteLead = (lead_hash_id) => {
    this.setState({ isLoading: true });
    let { hash_id } = this.state;
    const config = {
      headers: {
        api_token: localStorage.getItem("api-token"),
        Authorization: hash_id,
      },
    };

    const formData = {
      lead_hash_id,
      medium: "web",
      section_name: "lm_delete_my_lead",
    };

    axios
      .post("/lead-delete", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          this.getAllLeads();
          message.success(res.data.message);
        } else {
          this.setState({ isLoading: false });
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  tagChanges = (value) => {
    let tags_id = [];
    let selected_tags = [];
    value.map((tag) => {
      return [tags_id.push(tag.value), selected_tags.push(tag)];
    });

    this.setState({ tags_id, selected_tags });
  };

  resetFilter = async () => {
    await this.setState({
      tags_id: [],
      luid: [],
      temp_lead_id: "",
      customer_name: "",
      customer_address_state: "",
      customer_address_city: "",
      euipment_name: [],
      temp_euipment_name: "",
      euipment_category: "",
      start_date: "",
      end_date: "",
      selected_tags: [],
      isFiltered: false,
      toggleEvent: false,
      finance_type: "",
    });
    this.getAllLeads();
  };

  setKeyWithValue = async (key, value) => {
    await this.setState({ [key]: value });
  };

  onAdvanceFilterClick = async () => {
    let { luid, temp_lead_id, euipment_name, temp_euipment_name } = this.state;

    await this.setState({
      isFiltered: true,
      totalPage: 0,
      per_page: 10,
      currentPage: 1,
    });
    if (temp_lead_id) {
      luid.push(temp_lead_id);
      await this.setState({ temp_lead_id: "", luid });
    }
    if (temp_euipment_name) {
      euipment_name.push(temp_euipment_name);
      await this.setState({ temp_euipment_name: "", euipment_name });
    }

    this.getAllLeads();
  };

  render() {
    let { my_lead, collapsedArray } = this.state;

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                <DeletePopup
                  show={this.state.isDelete}
                  message="⚠️ Are you sure you want to permanently delete your lead?"
                  no={() => {
                    this.setState({
                      isDelete: false,
                    });
                  }}
                  yes={() => {
                    this.deleteLead(this.state.delete_id);
                  }}
                />
                <Col lg="9" md="8" sm="12">
                  <div className="dashboard_right_panel">
                    <Row>
                      <Col
                        lg={this.state.toggleEvent ? "9" : "12"}
                        md="12"
                        sm="12"
                        className="lead_resuuultts"
                      >
                        <div className="my_lead_section">
                          <div className="my_lead_heading">
                            <h2>My Leads</h2>
                            <div className="mylead_heading_right">
                              <div className="show_entries">
                                <Form.Label>Show</Form.Label>
                                <select
                                  className="show_field"
                                  name="per_page"
                                  value={this.state.per_page}
                                  onChange={this.handleOnChange}
                                >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                </select>
                                <span>entries</span>
                              </div>

                              <Link to="/add-lead" className="add_lead_box">
                                <img src={Pencil1_Img} />
                                <span>Add Lead</span>
                              </Link>

                              <Button
                                className="toggle filter_buttonner"
                                onClick={this.onToggle}
                              >
                                <img src={Setting_Filter_Img} />
                              </Button>
                            </div>
                          </div>

                          {/* SHOWING RESULTS */}
                          <div className="my_lead_inner_page my_lead_inner_box">
                            <div className="mylead_inner_inner_page my_lead_inner_content">
                              {my_lead.map((lead, index) => {
                                let create_date = moment(
                                  lead.created_at
                                ).format("DD/MM/YYYY");
                                let updated_date = moment(
                                  lead.updated_at
                                ).format("DD/MM/YYYY");

                                let tags = lead.tags && lead.tags.split(",");
                                return (
                                  <div
                                    className={`lead_equipment eqp_lead ${
                                      this.state.clickedPosition === index
                                        ? "blue_border"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      this.setState({ clickedPosition: index });
                                    }}
                                    key={index}
                                  >
                                    <div className="eqp_lead_part">
                                      <div className="lead_eq_inner">
                                        <label>Lead unique ID</label>
                                        <span>{lead.luid}</span>
                                      </div>

                                      <div className="lead_eq_inner">
                                        <label>Customer Name</label>
                                        <div className="d-flex align-items-center">
                                          <label className="lead_tooltip">
                                            <img
                                              src={hmc_user_onboard}
                                              data-tip
                                              data-for="registerTip7"
                                            />
                                            <ReactTooltip
                                              id="registerTip7"
                                              place="top"
                                              effect="solid"
                                            >
                                              HMC User
                                            </ReactTooltip>
                                          </label>
                                          <span>
                                            <label
                                              data-tip
                                              data-for={`customer_name${index}`}
                                            >
                                              {lead.customer_name}
                                              <ReactTooltip
                                                id={`customer_name${index}`}
                                                place="top"
                                                effect="solid"
                                              >
                                                {lead.customer_name}
                                              </ReactTooltip>
                                            </label>
                                          </span>
                                        </div>
                                      </div>

                                      <div className="lead_eq_inner">
                                        <label>Location</label>
                                        <span>
                                          {lead.customer_address_city &&
                                            lead.customer_address_state &&
                                            lead.customer_address_city +
                                              ", " +
                                              lead.customer_address_state}
                                        </span>
                                      </div>

                                      <div className="lead_eq_inner">
                                        <label>Current Status</label>
                                        {lead.current_status_name && (
                                          <>
                                            {lead.current_status === "16" ? (
                                              <div className="pending">
                                                {lead.current_status_name}
                                              </div>
                                            ) : lead.current_status === "33" ? (
                                              <div className="failed">
                                                {lead.current_status_name}
                                              </div>
                                            ) : lead.current_status === "32" ? (
                                              <div className="pending">
                                                {/* INPROGRESS */}
                                                {lead.current_status_name}
                                              </div>
                                            ) : (
                                              <div className="success">
                                                {lead.current_status_name}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>

                                      <div className="lead_eq_inner ">
                                        <label>Action</label>
                                        <div className="lead_label_inner">
                                          <div className="lead_label">
                                            <div className="actionsec">
                                              <div>
                                                <Link
                                                  to={{
                                                    pathname: `/edit-lead/${lead.hash_id}`,
                                                    state: {
                                                      section_name:
                                                        "update_lead",
                                                      lead_types:
                                                        LeadTypes.MY_LEAD,
                                                    },
                                                  }}
                                                  className="action_inner update"
                                                  data-tip
                                                  data-for="edit_lead"
                                                >
                                                  <img src={Lead_Update_Img} />
                                                </Link>
                                                <ReactTooltip
                                                  id="edit_lead"
                                                  place="top"
                                                  effect="solid"
                                                >
                                                  Update Details
                                                </ReactTooltip>
                                              </div>

                                              <div>
                                                <a
                                                  href="#"
                                                  className="action_inner chat"
                                                  data-tip
                                                  data-for="registerTip3"
                                                >
                                                  <img src={Lead_chat_Img} />
                                                </a>
                                                <ReactTooltip
                                                  id="registerTip3"
                                                  place="top"
                                                  effect="solid"
                                                >
                                                  Chat/Massage
                                                </ReactTooltip>
                                              </div>

                                              <div
                                                className="action_inner delete"
                                                onClick={() => {
                                                  this.setState({
                                                    isDelete: true,
                                                    delete_id: lead.hash_id,
                                                  });
                                                }}
                                              >
                                                <img
                                                  src={Delete_Icon_Img}
                                                  data-tip
                                                  data-for="delete_tool_tip"
                                                />

                                                <ReactTooltip
                                                  id="delete_tool_tip"
                                                  place="top"
                                                  effect="solid"
                                                >
                                                  Delete Lead
                                                </ReactTooltip>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="lead_arrow">
                                            <img
                                              src={Lead_Arrow_Img}
                                              onClick={() => {
                                                collapsedArray[index] =
                                                  !collapsedArray[index];
                                                this.setState({
                                                  collapsedArray,
                                                });
                                              }}
                                              className={
                                                collapsedArray[index]
                                                  ? "t_iconhide"
                                                  : "t_icon"
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        collapsedArray[index]
                                          ? "collapse lead_equipment show"
                                          : "collapse lead_equipment "
                                      }
                                    >
                                      <div className="lead_eq_inner">
                                        <label>Date</label>
                                        <span>{create_date}</span>
                                      </div>

                                      <div className="lead_eq_inner">
                                        <label>Last Mod. Date</label>
                                        <span>{updated_date}</span>
                                      </div>
                                      <div className="lead_eq_inner">
                                        <label>Lead Type</label>
                                        <span>
                                          {lead.euipment_category === "1"
                                            ? "New"
                                            : "Old"}
                                        </span>
                                      </div>
                                      <div className="lead_eq_inner">
                                        <label>Equip. Name</label>

                                        <span
                                          data-tip
                                          data-for={`euipment_name${index}`}
                                        >
                                          {lead.euipment_name}
                                          <ReactTooltip
                                            id={`euipment_name${index}`}
                                            place="top"
                                            effect="solid"
                                          >
                                            {lead.euipment_name}
                                          </ReactTooltip>
                                        </span>
                                      </div>

                                      <div className="lead_eq_inner">
                                        <label>Tags</label>
                                        {tags &&
                                          tags.map((tag, key) => {
                                            tag = tag.split("-");
                                            let title = tag[0];
                                            let tag_title_color = tag[1];
                                            let tag_back_color = tag[2];
                                            return (
                                              <div
                                                className="tags"
                                                key={key}
                                                style={{
                                                  background: tag_back_color,
                                                  color: tag_title_color,
                                                }}
                                              >
                                                {title}
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          {/* <div className="my_lead_inner_page">
                            <div className="mylead_inner_inner_page">
                              
                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">Pending</div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={No_User_Img}
                                    data-tip
                                    data-for="registerTip"
                                  />
                                  <ReactTooltip
                                    id="registerTip"
                                    place="top"
                                    effect="solid"
                                  >
                                    No User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={not_onboard_Img}
                                    data-tip
                                    data-for="registerTip6"
                                  />
                                  <ReactTooltip
                                    id="registerTip6"
                                    place="top"
                                    effect="solid"
                                  >
                                    not onboard
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={hmc_user_onboard}
                                    data-tip
                                    data-for="registerTip7"
                                  />
                                  <ReactTooltip
                                    id="registerTip7"
                                    place="top"
                                    effect="solid"
                                  >
                                    HMC User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={No_User_Img}
                                    data-tip
                                    data-for="registerTip"
                                  />
                                  <ReactTooltip
                                    id="registerTip"
                                    place="top"
                                    effect="solid"
                                  >
                                    No User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="lead_equipment">
                                <div
                                  className="lead_eq_inner"
                                  style={{ width: "2%" }}
                                >
                                  <img
                                    src={No_User_Img}
                                    data-tip
                                    data-for="registerTip"
                                  />
                                  <ReactTooltip
                                    id="registerTip"
                                    place="top"
                                    effect="solid"
                                  >
                                    No User
                                  </ReactTooltip>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead unique ID</label>
                                  <span>423487MRE</span>
                                </div>

                                <div className="lead_eq_inner">
                                  <label>Customer name</label>
                                  <span>Kamal Das</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Location</label>
                                  <span>Ultodanga, Kankurgachhi</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Equip. name</label>
                                  <span>Excavator</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Lead type</label>
                                  <span>New</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Current status</label>
                                  <div className="status_curr">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Tags</label>
                                  <div className="status_curr stat_1">
                                    Pending{" "}
                                    <a href="#" className="status_cross">
                                      <img src={Lead_cross_Img} />
                                    </a>
                                  </div>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Date</label>
                                  <span>15/02/2022</span>
                                </div>
                                <div className="lead_eq_inner">
                                  <label>Action</label>
                                  <div className="actionsec">
                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner update"
                                        data-tip
                                        data-for="registerTip2"
                                      >
                                        <img src={Lead_Update_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip2"
                                        place="top"
                                        effect="solid"
                                      >
                                        Update details
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner chat"
                                        data-tip
                                        data-for="registerTip3"
                                      >
                                        <img src={Lead_chat_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip3"
                                        place="top"
                                        effect="solid"
                                      >
                                        Chat/Massage
                                      </ReactTooltip>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        className="action_inner feedback"
                                        data-tip
                                        data-for="registerTip4"
                                      >
                                        <img src={Lead_Feedback_Img} />
                                      </a>
                                      <ReactTooltip
                                        id="registerTip4"
                                        place="top"
                                        effect="solid"
                                      >
                                        Feedback history
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>  */}

                          {/* PAGINATION */}
                          <div className="lead_showing_result">
                            <label>
                              Showing results{" "}
                              {Math.min(
                                this.state.totalItems,
                                this.state.currentPage * this.state.per_page
                              )}{" "}
                              out of {this.state.totalItems}
                            </label>
                            <div className="result_pagination">
                              <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={this.onPageChange}
                                pageRangeDisplayed={3}
                                pageCount={this.state.totalPage}
                                previousLabel="<"
                                activeClassName="active"
                                disabledClassName="disable"
                                renderOnZeroPageCount={null}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      {/* FILTER SECTION */}

                      {this.state.toggleEvent && (
                        <AdvanceFilter
                          resetFilter={this.resetFilter}
                          myState={this.state}
                          handleOnChange={this.handleOnChange}
                          setKeyWithValue={this.setKeyWithValue}
                          removeLuid={this.removeLuid}
                          removeEquipment={this.removeEquipment}
                          tagChanges={this.tagChanges}
                          onAdvanceFilterClick={this.onAdvanceFilterClick}
                          activeKey="my_lead"
                        />
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
