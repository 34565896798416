import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { prof_icon_menu } from "../../assets/images";
import { MEDIA_BASE_URL } from "../../utils/Helper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default class EquipmentItems extends Component {
  render() {
    let { machine_activity } = this.props;
    return (
      machine_activity &&
      machine_activity.map((machine, key) => {
        return (
          <Col lg="3" md="4" sm="6" className="rented_col" key={key}>
            <div className="rented_machine_inner rentedpartts">
              <div className="rented_machine_inner_inner">
                <div className="rented_machine_inner_top">
                  <Link
                    to={`/equipment-details/${machine.machine_activity_hash_id}/${machine.machine_hash_id}/${machine.profile_hash_id}`}
                  >
                    <LazyLoadImage
                      alt={machine.model_name}
                      src={MEDIA_BASE_URL + machine.machine_cover_image}
                      effect="blur"
                    />
                  </Link>
                </div>
                <div className="rented_machine_inner_bottom">
                  <div className="prod_id text-uppercase">
                    {machine.machine_activity_hash_id.substring(8)}
                  </div>
                  <h2 title={machine.model_name}>{machine.model_name}</h2>
                  <div className="year_brand">
                    <span>
                      <label>Year</label> {machine.purchase_date}
                    </span>
                    <span>
                      <label>Brand </label> {machine.brand_name}
                    </span>
                  </div>

                  <div className="business_name_partts">
                    <div className="prf_iconn_partts">
                      <LazyLoadImage
                        alt="HMC"
                        src={
                          machine.img_url
                            ? MEDIA_BASE_URL + machine.img_url
                            : prof_icon_menu
                        }
                        effect="blur"
                      />
                    </div>
                    <div className="prf_iconnn_nammme">
                      <span title={machine.business_name}>
                        {machine.business_name}
                      </span>
                      <div className="rented_lcation">
                        <span>
                          {machine.city && machine.city + ", "} {machine.state}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        );
      })
    );
  }
}
