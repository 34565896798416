import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import { showNotification } from "../utils/Notification";

const VAPID_KEY =
  "BIolwHWAhDgwu9WHGtb-S2JjU4RhlqhLNlUF8dkUfWQozk2LpBIJAP1zBb_bVmBbc99Ed4Qq3Ze52x--5rbatpQ";

const firebaseConfig = {
  apiKey: "AIzaSyDcKbq7t4_koBPVN2MMCNL80OnmeUz9knM",
  authDomain: "heavy-machinary-care-da0fe.firebaseapp.com",
  databaseURL: "https://heavy-machinary-care-da0fe-default-rtdb.firebaseio.com",
  projectId: "heavy-machinary-care-da0fe",
  storageBucket: "heavy-machinary-care-da0fe.appspot.com",
  messagingSenderId: "204271408182",
  appId: "1:204271408182:web:e666b18f3ca0451ba57497",
  measurementId: "G-4XCED0H9NB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export const getFMCToken = async () => {
  return await getToken(messaging, { vapidKey: VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        return;
      }
    })
    .catch((err) => {
      console.error("An error occurred while retrieving token. ", err);
    });
};

onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  const { title, body } = payload.data;
  showNotification(title, body, payload.data);
});

// export const onMessageListener = async () => {
//   new Promise(async (resolve) => {
//     await onMessage(messaging, (payload) => {
//       console.error("Message received. ", payload);
//       resolve(payload);
//     });
//   });
// };

// export const onMessageListener = async () => {
//   return await onMessage(messaging, (payload) => {
//     console.log("Message received. ", payload);
//     return payload;
//   });
// };
