import { TimePicker } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Select from "react-select";
import { connect } from "react-redux";
dayjs.extend(customParseFormat);
const format = "HH:mm";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class SetRemainder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meeting_time: "",
      meeting_date: "",
      meeting_label: "",
      meeting_description: "",
      to_contact_id: [],
      error: {},
    };
  }

  handleClose = () => {
    this.setState({
      lead_hash_id: "",
      meeting_time: "",
      meeting_date: "",
      meeting_label: "",
      meeting_description: "",
      to_contact_id: [],

      error: {},
    });
    this.props.onClose(false);
  };

  handleOnChange = async (e) => {
    e.persist();
    let { name, value } = e.target;

    let { error } = this.state;
    if (!value) {
      error[name] = "This filed required";
    } else {
      error[name] = "";
    }
    this.setState({ [name]: value, error });
  };

  render() {
    let { error } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.reminder_schedular;
    } else {
      language = {};
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        backdrop="static"
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title className="popup-title">
            {language.set_reminder_h}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="set_reminder_box">
            <div className="calendar_partts">
              <div className="calendar_box">
                <Calendar
                  minDate={new Date()}
                  onChange={(date) => {
                    error.meeting_date = "";
                    this.setState({
                      meeting_date: moment(date).format("YYYY-MM-DD"),
                      error,
                    });
                  }}
                />
                <p className="invalid_input">
                  {error.meeting_date && language.choose_meeting_date_errormsg}
                </p>
              </div>
              <div className="date_cal">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Row>
                    <Col lg="3" md="12">
                      <label>{language.time_label}</label>
                    </Col>
                    <Col lg="9" md="12">
                      <TimePicker
                        onChange={(time, timeString) => {
                          error.meeting_time = "";
                          this.setState({ meeting_time: timeString, error });
                        }}
                        use12Hours={true}
                        defaultValue={dayjs("00:00", format)}
                        format={format}
                      />
                    </Col>
                    <p className="invalid_input ml-3">
                      {error.meeting_time &&
                        language.choose_meeting_time_errormsg}
                    </p>
                  </Row>
                </Form.Group>

                {/* <p>Only On Time</p> */}
              </div>
            </div>
            <div className="reminder_field">
              {/* <div className="reminder_text">
                <div className="form_group1 text-center">
                  <input type="checkbox" id="html1" />
                  <label for="html1">Reminder to me</label>
                </div>
                <p>
                  {" "}
                  <span>or</span>
                </p>
              </div> */}
              <Form.Group className="mb-3" controlId="to_contact_id">
                <Form.Label>{language.search_contact_label}</Form.Label>
                <Select
                  options={this.props.contactList}
                  isMulti
                  onChange={(value) => {
                    let to_contact_id = [];
                    value.forEach((item) => {
                      to_contact_id.push(item.contact.hash_id);
                    });
                    this.setState({ to_contact_id });
                  }}
                  placeholder={language.search_contact_plh}
                  name="to_contact_id"
                />
              </Form.Group>
              <div className="remider_content">
                <Form.Group className="mb-3" controlId="meeting_label">
                  <Form.Label>
                    {language.heading_label}
                    <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={language.meeting_label_plh}
                    className="reminder_text_boxx"
                    onChange={this.handleOnChange}
                    name="meeting_label"
                  />
                  <p className="invalid_input">
                    {error.meeting_label &&
                      language.this_field_required_errormsg}
                  </p>
                </Form.Group>
                <Form.Group className="mb-3" controlId="meeting_description">
                  <Form.Label>{language.description_label}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    className="reminder_textarea"
                    name="meeting_description"
                    onChange={this.handleOnChange}
                  />
                </Form.Group>
                <div className="status_footer">
                  <Button
                    type="submit"
                    className="status_update"
                    onClick={() => {
                      let { meeting_label, meeting_date, meeting_time, error } =
                        this.state;

                      if (!meeting_date) {
                        error.meeting_date = "Choose Meeting Date";
                        this.setState({ error });
                        return;
                      } else {
                        error.meeting_date = "";
                        this.setState({ error });
                      }
                      if (!meeting_time) {
                        error.meeting_time = "Choose Meeting Time";
                        this.setState({ error });
                        return;
                      } else {
                        error.meeting_time = "";
                        this.setState({ error });
                      }
                      if (!meeting_label) {
                        error.meeting_label = "This Field Required";
                        this.setState({ error });
                        return;
                      } else {
                        error.meeting_label = "";
                        this.setState({ error });
                      }

                      this.props.setRemainder(this.state);
                      this.handleClose();
                    }}
                  >
                    {language.submit_btn}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
export default connect(mapStateToProps)(SetRemainder);
