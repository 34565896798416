import React, { Component } from "react";
import "./requestmanagement.css";
import LoadingOverlay from "react-loading-overlay";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import LeftPanelPage from "../../components/leftpanel/LeftPanelPage";
import axios from "../../config/axios";
import message from "../../utils/messages";
import config from "../../config/apiHeader";
import {
  Upload_img_icon_img,
  cross_preview,
  req_equipment_icon,
  req_hire_resource_icon,
  req_hmc_service_icon,
  req_parts_icon,
  req_service_icon,
} from "../../assets/images";
import Select from "react-select";
import _ from "lodash";
import moment from "moment";
import DeletePopup from "../../utils/DeletePopup";
import AutoCompleteLocation from "../../utils/AutoCompleteLocation";
import { getFileExtension } from "../../utils/Helper";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class RequestManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      user_hash_id: "",
      tabKey: "my_request",

      my_request: [],
      allMachineModels: [],
      allEquipments: [],
      allBrands: [],
      allServiceSkills: [],
      allExpertise: [],
      all_service_issue: [],

      formData: {
        request_for: "", // 1->Equipment, 2->Service, 3->Parts, 4->Job, 5->Finance, 6->HMC Service

        // EQUIPMENTS
        equipment_for: "",
        machine_hash_id: "",
        machine_model_name: "",
        equipment_type_hash_id: "",
        brand_hash_id: "",
        comment: "",

        // SERVICE
        service_for: "",
        service_name: [],
        service_name_other: "",

        // PARTS
        parts_name: "",
        equipment_qty: "1",
        delievery_location: "",
        request_image: [],

        // RESOUCRE
        skill: [],
        skill_other: "",
        people_required: "",
        job_location: "",
        salary_range_min: "6000",
        salary_range_max: "28000",
        is_urgent: "0",

        // HMC SERVICE
        hmc_service_issue: "",
      },

      error: {},
      isMachineOther: false,
      isServiceNameOther: false,
      isSkillOther: false,

      // DELETE EQUIPMENT
      isDelete: false,
      request_hash_id: "",
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    let userdata = JSON.parse(localStorage.getItem("userdata"));

    if (userdata) {
      await this.setState({
        user_hash_id: userdata.hash_id,
      });
    }

    this.getAllMyRequest();
    this.getAllMachineModels();
    this.getAllEquipments();
    this.getAllBrands();
    this.getAllServiceSkills();
    this.getAllExpertise();
    this.getContactCategoey();
  };

  getAllMyRequest = () => {
    let { user_hash_id } = this.state;
    this.setState({ isLoading: true });

    axios
      .post("/request-management-list", { user_hash_id }, config)
      .then((res) => {
        this.setState({ isLoading: false });

        if (res.data.status === "1") {
          let { data } = res.data;

          let allRequestList = [];

          if (data.length > 0) {
            let { loading, language } = this.props.language;

            if (!loading && language) {
              language = language.request_management;
            } else {
              language = {};
            }
            data.forEach((request) => {
              let result = {};

              result.id = request.id;
              result.hash_id = request.hash_id;
              result.created_at = moment(request.created_at).format(
                "DD MMMM,YYYY"
              );
              result.brand_name = request.brand_name || request.brand_other;
              result.status = request.status;

              if (request.status === "0") {
                result.status_title = language.request_list_status_not_attended;
                result.status_cls = "not-attend";
              } else if (request.status === "1") {
                result.status_title = language.request_list_status_in_progress;
                result.status_cls = "in-progress";
              } else if (request.status === "2") {
                result.status_title = language.request_list_status_closed;
                result.status_cls = "closed";
              } else if (request.status === "3") {
                result.status_title = language.request_list_status_completed;
                result.status_cls = "complete";
              } else if (request.status === "4") {
                result.status_title = language.request_list_status_pending;
                result.status_cls = "pending";
              }

              if (request.request_for === "1") {
                result.requestFor = language.request_list_request_for_equipment;
                // result.requestIcon = req_equipment_icon;
                result.eq_type_title = language.request_list_equipment_type;
                result.eq_type = this.getEquipment(request.equipment_type_name);
                if (request.equipment_for === "1") {
                  result.requestType = language.request_list_request_type_new;
                } else if (request.equipment_for === "2") {
                  result.requestType = language.request_list_request_type_used;
                } else if (request.equipment_for === "4") {
                  result.requestType = language.request_list_request_type_rent;
                }
              } else if (request.request_for === "2") {
                result.requestFor = language.request_list_request_for_service;
                // result.requestIcon = req_service_icon;
                result.eq_type_title = language.request_list_service_name;
                result.eq_type = this.getService(request.service_name);
                if (request.service_for === "1") {
                  result.requestType =
                    language.request_list_request_type_individual;
                } else if (request.service_for === "2") {
                  result.requestType =
                    language.request_list_request_type_workshop;
                }
              } else if (request.request_for === "3") {
                result.requestFor = language.request_list_request_for_parts;
                // result.requestIcon = req_parts_icon;
                result.eq_type_title = language.request_list_parts_name;
                result.eq_type =
                  request.parts_name || request.equipment_type_name;
                if (request.service_for === "1") {
                  result.requestType = language.request_list_request_type_used;
                } else if (request.service_for === "2") {
                  result.requestType = language.request_list_request_type_new;
                } else {
                  result.requestType =
                    language.request_list_request_type_new_and_used;
                }
              } else if (request.request_for === "4") {
                result.requestFor =
                  language.request_list_request_for_hire_resources;
                // result.requestIcon = req_hire_resource_icon;
                result.requestType = language.request_list_request_type_job;
                result.eq_type_title = language.request_list_skill_type;
                result.eq_type = this.getSkill(request.skill);
              } else if (request.request_for === "5") {
                result.requestFor = language.request_list_request_for_finance;
                // result.requestIcon = req_finance_icon;
                result.requestType = language.request_list_request_type_finance;
                result.eq_type_title = "";
                result.eq_type = "";
              } else if (request.request_for === "6") {
                result.requestFor =
                  language.request_list_request_for_hmc_service;
                // result.requestIcon = req_finance_icon;
                result.requestType =
                  language.request_list_request_type_hmc_service;
                result.eq_type_title = language.request_list_model_name_label;
                result.eq_type = request.machine_model_name;
              }

              allRequestList.push(result);
            });

            this.setState({ my_request: allRequestList, tabKey: "my_request" });
          } else {
            message.warning(res.data.message);
            this.setState({ tabKey: "create_request" });
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getAllMachineModels = () => {
    axios
      .post("/machine-model-list", { medium: "web" }, config)
      .then((res) => {
        if (res.data.status === "1") {
          let allMachineModels = res.data.data.machines.map((machine) => {
            return {
              value: machine.machine_hash_id,
              label: machine.model_name,
            };
          });
          this.setState({
            allMachineModels,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllEquipments = () => {
    axios
      .get("/all-machine-type-list")
      .then((res) => {
        if (res.data.status === "1") {
          let allEquipments = res.data.data.machine_types.map((equipment) => {
            return {
              value: equipment.machine_type_hash_id,
              label: equipment.machine_type_name,
            };
          });
          this.setState({
            allEquipments,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllBrands = () => {
    axios
      .get("/brand-list")
      .then((res) => {
        if (res.data.status === "1") {
          let allBrands = res.data.data.brand.map((brand) => {
            return {
              value: brand.brand_hash_id,
              label: brand.brand_name,
            };
          });
          this.setState({
            allBrands,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAllServiceSkills = () => {
    axios
      .get("/skills")
      .then((res) => {
        if (res.data.status === "1") {
          let allServiceSkills = res.data.data.skills.map((skill) => {
            return {
              value: skill.id,
              label: skill.name,
            };
          });
          this.setState({
            allServiceSkills,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllExpertise = () => {
    axios
      .get("/category")
      .then((res) => {
        if (res.data.status === "1") {
          let allExpertise = res.data.data.category;
          this.setState({
            allExpertise,
          });
        }
      })
      .catch((err) => {
        console.log("Something went wrong!", err);
      });
  };

  getContactCategoey = () => {
    axios
      .get("/contactus-category?type=2")
      .then((res) => {
        if (res.data.status === "1") {
          let resuserData = res.data.data;
          this.setState({
            all_service_issue: resuserData,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  tabChange = (tabKey) => {
    this.setState({ tabKey });

    if (tabKey === "my_request") {
      this.getAllMyRequest();
    }
  };

  handleOnchange = (e, skillValue = "") => {
    let { name, value } = e.target;

    let { formData, error } = this.state;

    if (name === "skill") {
      if (skillValue === "Others") {
        this.setState({ isSkillOther: !this.state.isSkillOther });
      }
      let { skill } = formData;

      if (
        _.find(skill, (val) => {
          return val === value;
        })
      ) {
        skill = _.filter(skill, (val) => {
          return val !== value;
        });
      } else {
        skill = _.concat(skill, value);
      }
      if (skill.length === 0) {
        error[name] = "Please provide the skill type";
      } else {
        error[name] = "";
      }
      formData[name] = skill;
      this.setState({ formData, error });

      return;
    }

    if (name === "people_required") {
      formData[name] = value.replace(/\D/g, "");
      this.setState({ formData });
      return;
    }

    if (name === "request_for") {
      this.clearField(value);
      return;
    }

    formData[name] = value;
    this.setState({ formData });
  };

  handleSelect = (name, item) => {
    let { error, formData } = this.state;
    error[name] = "";

    if (name === "service_name") {
      if (
        _.find(item, (val) => {
          return val.label === "Other";
        })
      ) {
        this.setState({ isServiceNameOther: true });
      } else {
        error.service_name_other = "";
        formData.service_name_other = "";
        this.setState({ isServiceNameOther: false });
      }

      let service_name = item.map((val) => {
        return val.value;
      });
      formData[name] = service_name;

      this.setState({ formData, error });
      return;
    }

    formData[name] = item.value;

    this.setState({ formData, error });

    // FOR MACHINE MODEL NAME
    if (name === "machine_hash_id") {
      if (item.label === "Other") {
        formData.machine_model_name = "";
        formData.machine_hash_id = "";
        this.setState({
          isMachineOther: true,
          formData,
        });
      } else {
        formData.machine_model_name = item.label;

        this.setState({
          isMachineOther: false,
          formData,
        });
      }
    }
    // FOR BRAND NAME
    if (name === "brand_hash_id") {
      if (item.label === "Other") {
        this.setState({ isBrandOther: true });
      } else {
        error.brand_other = "";
        this.setState({ isBrandOther: false, error });
      }
    }
  };

  submitData = (formData, isImage = false) => {
    this.setState({ isLoading: true });

    axios
      .post("/request-management-save", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          if (isImage) {
            let imageData = new FormData();

            imageData.append("request_id", res.data.data.id);
            imageData.append("request_user_id", res.data.data.request_user_id);

            formData.request_image.forEach((file, i) => {
              imageData.append(`request_image[${i}]`, file);
            });
            this.uploadImage(imageData);
          }
          message.success(res.data.message);
          this.getAllMyRequest();
          this.clearField();
        } else {
          this.setState({ isLoading: false });
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });

        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  clearField = (request_for = "") => {
    this.setState({
      formData: {
        request_for: request_for, // 1->Equipment, 2->Service, 3->Parts, 4->Job, 5->Finance, 6->HMC Service

        // EQUIPMENTS
        equipment_for: "",
        machine_hash_id: "",
        machine_model_name: "",
        equipment_type_hash_id: "",
        brand_hash_id: "",
        comment: "",

        // SERVICE
        service_for: "",
        service_name: [],
        service_name_other: "",

        // PARTS
        parts_name: "",
        equipment_qty: "1",
        delievery_location: "",
        request_image: [],

        // RESOUCRE
        skill: [],
        skill_other: "",
        people_required: "",
        job_location: "",
        salary_range_min: "6000",
        salary_range_max: "28000",
        is_urgent: "0",

        // HMC SERVICE
        hmc_service_issue: "",
      },
    });
  };

  equipmentRequest = (e) => {
    e.preventDefault();

    let { formData, error, user_hash_id } = this.state;

    if (!formData.machine_model_name) {
      error.machine_hash_id = "This field required";
      this.setState({ error });
      return;
    }

    let {
      brand_hash_id,
      comment,
      equipment_for,
      equipment_type_hash_id,
      machine_hash_id,
      machine_model_name,
      request_for,
    } = formData;

    const data = {
      user_hash_id,

      brand_hash_id,
      comment,
      equipment_for,
      equipment_type_hash_id,
      machine_hash_id,
      machine_model_name,
      request_for,
    };
    this.submitData(data);
  };

  serviceRequest = (e) => {
    e.preventDefault();

    let { formData, error, user_hash_id } = this.state;

    let {
      service_name,
      service_name_other,
      service_for,
      request_for,
      equipment_type_hash_id,
      comment,
    } = formData;

    if (service_name.length === 0) {
      error.service_name = "Select Service name";
      this.setState({ error });
      return;
    } else {
      error.service_name = "";
      this.setState({ error });
    }

    _.remove(service_name, (val) => {
      return val === "Other";
    });
    if (service_name_other)
      service_name = _.concat(service_name, service_name_other);
    service_name = service_name.toString();

    this.submitData({
      user_hash_id,

      request_for,
      service_for,
      service_name,
      service_name_other,
      equipment_type_hash_id,
      comment,
    });
  };

  partsRequest = (e) => {
    e.preventDefault();

    let { formData, user_hash_id } = this.state;

    let {
      request_for,
      parts_name,
      machine_hash_id,
      equipment_qty,
      delievery_location,
      request_image,
      comment,
    } = formData;

    this.submitData(
      {
        request_for,
        parts_name,
        machine_hash_id,
        equipment_qty,
        delievery_location,
        request_image,
        comment,
        user_hash_id,
      },
      request_image.length > 0
    );
  };

  resourceRequest = (e) => {
    e.preventDefault();

    let { formData, user_hash_id, error } = this.state;

    let {
      request_for,
      skill,
      skill_other,
      people_required,
      job_location,
      salary_range_min,
      salary_range_max,
      is_urgent,

      comment,
    } = formData;

    if (skill.length) {
      error.skill = "";
      this.setState({ error });
    } else {
      error.skill = "Please provide the skill type";
      this.setState({ error });
      return;
    }

    if (!job_location) {
      error.job_location = "Please provide the job";
      this.setState({ error });
      return;
    } else {
      error.job_location = "";
      this.setState({ error });
    }

    skill = skill.toString();

    this.submitData({
      request_for,
      skill,
      skill_other,
      people_required,
      job_location,
      salary_range_min,
      salary_range_max,
      is_urgent,

      comment,
      user_hash_id,
    });
  };

  hmcServiceRequest = (e) => {
    e.preventDefault();

    let { formData, user_hash_id, error } = this.state;

    let {
      brand_hash_id,
      machine_hash_id,
      machine_model_name,
      hmc_service_issue,
      job_location,

      comment,
    } = formData;

    if (machine_model_name) {
      error.machine_model_name = "";
      this.setState({ error });
    } else {
      error.machine_model_name = "This field required";
      this.setState({ error });
      return;
    }

    if (!job_location) {
      error.job_location = "This Field required";
      this.setState({ error });
      return;
    } else {
      error.job_location = "";
      this.setState({ error });
    }

    let data = {
      brand_hash_id,
      machine_hash_id,
      machine_model_name,
      hmc_service_issue,
      job_location,

      comment,
      user_hash_id,
    };

    this.setState({ isLoading: true });
    axios
      .post("/request-management-hmc-service-save", data, config)
      .then((res) => {
        if (res.data.status === "1") {
          message.success(res.data.message);
          this.getAllMyRequest();
          this.clearField();
        } else {
          this.setState({ isLoading: false });
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });

        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  uploadImage = (formData) => {
    axios
      .post("/request-image-save", formData, config)
      .then((res) => {})
      .catch((err) => {
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  deleteEquipment = (request_hash_id) => {
    this.setState({ isLoading: true });
    axios
      .post("/request-management-delete", { request_hash_id }, config)
      .then((res) => {
        if (res.data.status === "1") {
          this.setState({ request_hash_id: "" });

          message.success(res.data.message);
          this.getAllMyRequest();
        } else {
          this.setState({ isLoading: false });
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });

        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  handleGetLocation = (value) => {
    if (value) {
      let { formData } = this.state;
      let { label } = value;

      formData.delievery_location = label;

      this.setState({
        formData,
      });
    }
  };

  handleJobLocation = (value) => {
    let { formData, error } = this.state;
    if (value) {
      error.job_location = "";

      let { label } = value;

      formData.job_location = label;
    } else {
      formData.job_location = "";
      error.job_location = "This field required";
    }

    this.setState({
      error,
      formData,
    });
  };

  handleImages = (e) => {
    e.persist();

    let { formData } = this.state;
    let { request_image } = formData;

    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];
      let validExtension = ["png", "jpg", "jpeg"];
      if (file !== undefined) {
        let extension = getFileExtension(file);
        if (
          extension !== undefined &&
          _.findIndex(validExtension, (exe) => {
            return exe === extension;
          }) !== -1
        ) {
          request_image.push(file);
        } else {
          message.error("The file format is not supported");
        }
      }
    }

    formData.request_image = request_image;

    this.setState({ formData });
  };

  deleteImage = (pos) => {
    let { formData } = this.state;
    let { request_image } = formData;

    request_image.splice(pos, 1);

    formData.request_image = request_image;

    this.setState({ formData });
  };

  getSkill = (skill) => {
    return skill.split(",")[0];
  };

  getService = (service) => {
    return service.split(",")[0];
  };

  getEquipment = (service) => {
    return service.split(",")[0];
  };

  render() {
    let {
      formData,
      allMachineModels,
      error,
      allEquipments,
      allBrands,
      my_request,
      allServiceSkills,
      allExpertise,
      all_service_issue,
    } = this.state;

    let tempArray = [];
    for (let i = 1; i <= 10; i++) tempArray.push(i);
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.request_management;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ... ">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />

                <DeletePopup
                  show={this.state.isDelete}
                  message="⚠️ Are you sure you want to permanently deletethis equipment request?"
                  no={() => {
                    this.setState({
                      isDelete: false,
                      request_hash_id: "",
                    });
                  }}
                  yes={() => {
                    this.deleteEquipment(this.state.request_hash_id);
                    this.setState({
                      isDelete: false,
                    });
                  }}
                />

                <Col lg="9" md="8" sm="12" className="ds_right">
                  <div className="dsc_content">
                    <Row>
                      <Col lg="12" md="8" sm="12">
                        <div className="ds_header">
                          <h2>{language.request_management_h}</h2>
                        </div>
                        <div className="ds_tab_pill">
                          <Tabs
                            defaultActiveKey="my_request"
                            activeKey={this.state.tabKey}
                            transition={false}
                            id="noanim-tab-example"
                            onSelect={(key) => this.tabChange(key)}
                          >
                            <Tab
                              eventKey="my_request"
                              title={language.my_request_list}
                            >
                              {my_request.map((request, key) => {
                                return (
                                  <Card className="request_item mb-3" key={key}>
                                    <Card.Header className="d-flex flex-wrap">
                                      <h4 className="card-title mb-0">
                                        {language.request_for +
                                          " " +
                                          request.requestFor}
                                      </h4>
                                      <div className="request_date ml-auto">
                                        {request.created_at}
                                      </div>
                                    </Card.Header>
                                    <Card.Body>
                                      <Row>
                                        <Col md={3}>
                                          <dl>
                                            <dd>
                                              {
                                                language.request_list_request_type_label
                                              }
                                            </dd>
                                            <dt>{request.requestType}</dt>
                                          </dl>
                                        </Col>
                                        <Col md={4}>
                                          <dl>
                                            <dd>{request.eq_type_title}</dd>
                                            <dt>{request.eq_type}</dt>
                                          </dl>
                                        </Col>

                                        <Col md={3}>
                                          <dl>
                                            <dd>
                                              {
                                                language.request_list_status_label
                                              }
                                            </dd>
                                            <dt>
                                              <span className="available color1">
                                                {request.status_title}
                                              </span>
                                            </dt>
                                          </dl>
                                        </Col>
                                        <Col md={2} className="text-right">
                                          <Button
                                            variant="default"
                                            className="delete_request"
                                            onClick={() => {
                                              this.setState({
                                                isDelete: true,
                                                request_hash_id:
                                                  request.hash_id,
                                              });
                                            }}
                                          >
                                            <i className="fa fa-trash-o" />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card>
                                );
                              })}
                            </Tab>

                            <Tab
                              eventKey="create_request"
                              title={language.create_request}
                            >
                              <Card className="hmc_card">
                                <Card.Header>
                                  <h4 className="card-title mb-0">
                                    {language.choosel_your_request_type}
                                  </h4>
                                </Card.Header>

                                <Card.Body>
                                  <div className="req_form">
                                    <div className="reqf_item">
                                      <h5>{language.request_for}</h5>

                                      <Row>
                                        <Col md={3}>
                                          <label className="rf_item">
                                            <img
                                              alt="HMC"
                                              width={30}
                                              src={req_equipment_icon}
                                            />
                                            <input
                                              type="radio"
                                              name="request_for"
                                              value="1"
                                              onChange={this.handleOnchange}
                                            />
                                            <span className="rfi_label">
                                              {language.equipment}
                                            </span>
                                          </label>
                                        </Col>

                                        <Col md={3}>
                                          <label className="rf_item">
                                            <img
                                              alt="HMC"
                                              width={30}
                                              src={req_service_icon}
                                            />
                                            <input
                                              type="radio"
                                              name="request_for"
                                              value="2"
                                              onChange={this.handleOnchange}
                                            />
                                            <span className="rfi_label">
                                              {
                                                language.service_engineer_workdhop
                                              }
                                            </span>
                                          </label>
                                        </Col>

                                        <Col md={3}>
                                          <label className="rf_item">
                                            <img
                                              alt="HMC"
                                              width={30}
                                              src={req_parts_icon}
                                            />
                                            <input
                                              type="radio"
                                              name="request_for"
                                              value="3"
                                              onChange={this.handleOnchange}
                                            />
                                            <span className="rfi_label">
                                              {language.equipment_parts}
                                            </span>
                                          </label>
                                        </Col>

                                        <Col md={3}>
                                          <label className="rf_item">
                                            <img
                                              alt="HMC"
                                              width={30}
                                              src={req_hire_resource_icon}
                                            />
                                            <input
                                              type="radio"
                                              name="request_for"
                                              value="4"
                                              onChange={this.handleOnchange}
                                            />
                                            <span className="rfi_label">
                                              {language.find_resources}
                                            </span>
                                          </label>
                                        </Col>

                                        <Col md={3}>
                                          <label className="rf_item">
                                            <img
                                              alt="HMC"
                                              width={30}
                                              src={req_hmc_service_icon}
                                            />
                                            <input
                                              type="radio"
                                              name="request_for"
                                              value="6"
                                              onChange={this.handleOnchange}
                                            />
                                            <span className="rfi_label">
                                              {language.hmc_service}
                                            </span>
                                          </label>
                                        </Col>
                                      </Row>
                                    </div>

                                    {/* EQUIPMENT REQUEST */}
                                    {formData.request_for === "1" && (
                                      <Form onSubmit={this.equipmentRequest}>
                                        <div className="reqf_item">
                                          <Form.Label>
                                            {
                                              language.equipment_specify_your_request
                                            }{" "}
                                            *
                                          </Form.Label>

                                          <div className="specify_req">
                                            <Row>
                                              <Col md={4}>
                                                <div className="hmc_radio">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      value="2"
                                                      name="equipment_for"
                                                      onChange={
                                                        this.handleOnchange
                                                      }
                                                      required
                                                    />
                                                    <span>
                                                      {
                                                        language.equipment_used_equipment
                                                      }
                                                    </span>
                                                  </label>
                                                </div>
                                              </Col>
                                              <Col md={4}>
                                                <div className="hmc_radio">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      value="4"
                                                      name="equipment_for"
                                                      onChange={
                                                        this.handleOnchange
                                                      }
                                                      required
                                                    />
                                                    <span>
                                                      {
                                                        language.equipment_rent_an_equipment
                                                      }
                                                    </span>
                                                  </label>
                                                </div>
                                              </Col>
                                              <Col md={4}>
                                                <div className="hmc_radio">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      value="1"
                                                      name="equipment_for"
                                                      onChange={
                                                        this.handleOnchange
                                                      }
                                                      required
                                                    />
                                                    <span>
                                                      {
                                                        language.equipment_brand_new_equipment
                                                      }
                                                    </span>
                                                  </label>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>

                                          <div className="select_services">
                                            <Form.Label>
                                              {
                                                language.equipment_model_name_label
                                              }{" "}
                                              *
                                            </Form.Label>
                                            <Select
                                              options={allMachineModels}
                                              onChange={(item) => {
                                                this.handleSelect(
                                                  "machine_hash_id",
                                                  item
                                                );
                                              }}
                                              placeholder={
                                                language.equipment_select_model_name_plh
                                              }
                                              name="machine_hash_id"
                                            />
                                            <p className="invalid_input">
                                              {error.machine_hash_id}
                                            </p>
                                          </div>
                                          {this.state.isMachineOther && (
                                            <Form.Group>
                                              <Form.Label>
                                                Type other equipment model name
                                              </Form.Label>
                                              <Form.Control
                                                placeholder="Type your model name"
                                                value={
                                                  formData.machine_model_name
                                                }
                                                onChange={this.handleOnchange}
                                                name="machine_model_name"
                                                type="text"
                                                required={
                                                  this.state.isMachineOther
                                                }
                                              />
                                              <p className="invalid_input">
                                                {error.machine_model_name}
                                              </p>
                                            </Form.Group>
                                          )}

                                          <div className="select_services">
                                            <Form.Label>
                                              {
                                                language.equipment_type_of_equipment_label
                                              }
                                            </Form.Label>
                                            <Select
                                              options={allEquipments}
                                              onChange={(item) => {
                                                this.handleSelect(
                                                  "equipment_type_hash_id",
                                                  item
                                                );
                                              }}
                                              placeholder={
                                                language.equipment_select_type_of_equipment_plh
                                              }
                                              name="equipment_type_hash_id"
                                            />
                                            <p className="invalid_input">
                                              {error.equipment_type_hash_id}
                                            </p>
                                          </div>

                                          <div className="select_services">
                                            <Form.Label>
                                              {
                                                language.equipment_equipment_brand_label
                                              }
                                            </Form.Label>
                                            <Select
                                              options={allBrands}
                                              onChange={(item) => {
                                                this.handleSelect(
                                                  "brand_hash_id",
                                                  item
                                                );
                                              }}
                                              placeholder={
                                                language.equipment_select_equipment_brand_plh
                                              }
                                              name="brand_hash_id"
                                            />
                                            <p className="invalid_input">
                                              {error.brand_hash_id}
                                            </p>
                                          </div>

                                          <div className="specify_req">
                                            <Form.Group
                                              className="mb-3"
                                              controlId="exampleForm.ControlTextarea1"
                                            >
                                              <Form.Label>
                                                {
                                                  language.equipment_your_comments_label
                                                }
                                              </Form.Label>
                                              <Form.Control
                                                placeholder={
                                                  language.equipment_your_comments_plh
                                                }
                                                as="textarea"
                                                rows={3}
                                                value={formData.comment}
                                                name="comment"
                                                onChange={this.handleOnchange}
                                              />
                                            </Form.Group>
                                          </div>
                                        </div>

                                        <Button
                                          variant="primary"
                                          type="submit"
                                          className="btn_primary"
                                        >
                                          {language.equipment_submit_btn}
                                        </Button>
                                      </Form>
                                    )}

                                    {/* SERVICE REQUEST */}
                                    {formData.request_for === "2" && (
                                      <Form onSubmit={this.serviceRequest}>
                                        <div className="reqf_item">
                                          <div className="specify_req">
                                            <Form.Label>
                                              {
                                                language.service_please_choose_service_type_label
                                              }{" "}
                                              *
                                            </Form.Label>
                                            <div>
                                              <div className="hmc_radio radio-inline">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="1"
                                                    name="service_for"
                                                    onChange={
                                                      this.handleOnchange
                                                    }
                                                    required
                                                  />
                                                  <span>
                                                    {
                                                      language.service_individual_service_person
                                                    }
                                                  </span>
                                                </label>
                                              </div>

                                              <div className="hmc_radio radio-inline">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="2"
                                                    name="service_for"
                                                    onChange={
                                                      this.handleOnchange
                                                    }
                                                    required
                                                  />
                                                  <span>
                                                    {language.service_workshop}
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="specify_req">
                                            <Form.Label>
                                              {
                                                language.service_select_service_name_label
                                              }{" "}
                                              *
                                            </Form.Label>
                                            <Select
                                              options={allServiceSkills}
                                              isMulti
                                              onChange={(item) => {
                                                this.handleSelect(
                                                  "service_name",
                                                  item
                                                );
                                              }}
                                              placeholder={
                                                language.service_select_service_name_plh
                                              }
                                              name="service_name"
                                              closeMenuOnSelect={false}
                                            />
                                            <p className="invalid_input">
                                              {error.service_name}
                                            </p>
                                          </div>
                                          {this.state.isServiceNameOther && (
                                            <Form.Group>
                                              <Form.Control
                                                placeholder="Type your skill name"
                                                value={
                                                  formData.service_name_other
                                                }
                                                onChange={this.handleOnchange}
                                                name="service_name_other"
                                                type="text"
                                                validaterule={["required"]}
                                                validatemsg={[
                                                  "This field is required",
                                                ]}
                                                required={
                                                  this.state.isServiceNameOther
                                                }
                                              />
                                              <p className="invalid_input">
                                                {error.service_name_other}
                                              </p>
                                            </Form.Group>
                                          )}

                                          <div className="specify_req">
                                            <Form.Label>
                                              {
                                                language.service_select_type_of_equipment_label
                                              }
                                            </Form.Label>
                                            <Select
                                              options={allEquipments}
                                              onChange={(item) => {
                                                this.handleSelect(
                                                  "equipment_type_hash_id",
                                                  item
                                                );
                                              }}
                                              placeholder={
                                                language.service_select_type_of_equipment_plh
                                              }
                                              name="equipment_type_hash_id"
                                            />
                                          </div>

                                          <div className="specify_req">
                                            <Form.Group
                                              className="mb-3"
                                              controlId="exampleForm.ControlTextarea1"
                                            >
                                              <Form.Label>
                                                {
                                                  language.service_specific_requirement_type_label
                                                }
                                              </Form.Label>
                                              <Form.Control
                                                placeholder={
                                                  language.service_your_comments_plh
                                                }
                                                as="textarea"
                                                rows={3}
                                                value={formData.comment}
                                                name="comment"
                                                onChange={this.handleOnchange}
                                              />
                                            </Form.Group>
                                          </div>
                                        </div>

                                        <Button
                                          variant="primary"
                                          type="submit"
                                          className="btn_primary"
                                        >
                                          {language.service_submit_btn}
                                        </Button>
                                      </Form>
                                    )}

                                    {/* PARTS REQUEST */}
                                    {formData.request_for === "3" && (
                                      <Form onSubmit={this.partsRequest}>
                                        <div className="specify_req">
                                          <Form.Group>
                                            <Form.Label>
                                              {language.parts_parts_name_label}{" "}
                                              *
                                            </Form.Label>
                                            <Form.Control
                                              placeholder={
                                                language.parts_enter_parts_name_label
                                              }
                                              value={formData.parts_name}
                                              onChange={this.handleOnchange}
                                              name="parts_name"
                                              type="text"
                                              validaterule={["required"]}
                                              validatemsg={["Enter parts name"]}
                                              required
                                            />
                                            <p className="invalid_input">
                                              {error.parts_name}
                                            </p>
                                          </Form.Group>
                                        </div>

                                        <div className="specify_req">
                                          <Form.Label>
                                            {
                                              language.parts_select_equipment_model_name_label
                                            }
                                          </Form.Label>
                                          <Select
                                            options={allMachineModels}
                                            onChange={(item) => {
                                              this.handleSelect(
                                                "machine_hash_id",
                                                item
                                              );
                                            }}
                                            placeholder={
                                              language.parts_select_equipment_model_name_plh
                                            }
                                            name="machine_hash_id"
                                          />
                                        </div>

                                        <div className="specify_req">
                                          <Form.Group>
                                            <Form.Label>
                                              {language.parts_quantity_label}
                                            </Form.Label>
                                            <Form.Control
                                              aria-label="Default select example"
                                              onChange={this.handleOnchange}
                                              as="select"
                                              name="equipment_qty"
                                              value={formData.equipment_qty}
                                            >
                                              {tempArray.map((val, key) => {
                                                return (
                                                  <option key={key} value={val}>
                                                    {val}
                                                  </option>
                                                );
                                              })}
                                            </Form.Control>
                                          </Form.Group>
                                        </div>

                                        <div className="specify_req">
                                          <Form.Group>
                                            <Form.Label>
                                              {
                                                language.parts_delivery_location_label
                                              }
                                            </Form.Label>

                                            <AutoCompleteLocation
                                              handleGetLocation={
                                                this.handleGetLocation
                                              }
                                              value={
                                                formData.delievery_location
                                              }
                                            />
                                          </Form.Group>
                                        </div>

                                        <div className="specify_req">
                                          <Form.Group>
                                            <Form.Label>
                                              {
                                                language.Iparts_if_you_have_old_parts_upload_picture_label
                                              }
                                            </Form.Label>
                                            <div className="add_eqp_partts_img">
                                              <div className="add_images_partttts">
                                                {formData.request_image.map(
                                                  (preview, index) => {
                                                    return (
                                                      <div
                                                        className="after_upload_image"
                                                        key={index}
                                                      >
                                                        <img
                                                          src={URL.createObjectURL(
                                                            preview
                                                          )}
                                                          alt="HMC"
                                                        />
                                                        <span
                                                          className="close_preview"
                                                          onClick={() => {
                                                            this.deleteImage(
                                                              index
                                                            );
                                                          }}
                                                        >
                                                          <img
                                                            src={cross_preview}
                                                            alt="HMC"
                                                          />
                                                        </span>
                                                      </div>
                                                    );
                                                  }
                                                )}

                                                <div className="uploadwrapper">
                                                  <button className="uploadwrapperbtn">
                                                    <img
                                                      src={Upload_img_icon_img}
                                                      alt="HMC"
                                                    />
                                                  </button>
                                                  <Form.Control
                                                    type="file"
                                                    className="file_upload_boxxx"
                                                    accept="image/png, image/jpg, image/jpeg"
                                                    value=""
                                                    name="images"
                                                    multiple
                                                    onChange={this.handleImages}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </Form.Group>
                                        </div>

                                        <div className="specify_req">
                                          <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlTextarea1"
                                          >
                                            <Form.Label>
                                              {
                                                language.parts_specific_requirement_type_label
                                              }
                                            </Form.Label>
                                            <Form.Control
                                              placeholder={
                                                language.parts_your_comments_plh
                                              }
                                              as="textarea"
                                              rows={3}
                                              value={formData.comment}
                                              name="comment"
                                              onChange={this.handleOnchange}
                                            />
                                          </Form.Group>
                                        </div>

                                        <Button
                                          variant="primary"
                                          type="submit"
                                          className="btn_primary"
                                        >
                                          {language.parts_submit_btn}
                                        </Button>
                                      </Form>
                                    )}

                                    {/* RESOURCE REQUEST */}
                                    {formData.request_for === "4" && (
                                      <Form onSubmit={this.resourceRequest}>
                                        <div className="reqf_item">
                                          <div className="specify_req">
                                            <Form.Group>
                                              <Form.Label>
                                                {
                                                  language.find_resources_experties_you_looking_for
                                                }{" "}
                                                *
                                              </Form.Label>
                                              {allExpertise.map(
                                                (expertise, index) => {
                                                  return (
                                                    <div
                                                      key={index}
                                                      className="mb-2"
                                                    >
                                                      {expertise.sub_category
                                                        .length > 0 ? (
                                                        <Form.Group>
                                                          <Form.Label className="sub_category_label">
                                                            {
                                                              expertise.category_name
                                                            }
                                                          </Form.Label>
                                                          <div className="check_container">
                                                            {expertise.sub_category.map(
                                                              (
                                                                sub_cat,
                                                                ind
                                                              ) => {
                                                                return (
                                                                  <Form.Check
                                                                    type="checkbox"
                                                                    label={
                                                                      sub_cat.category_name
                                                                    }
                                                                    onChange={
                                                                      this
                                                                        .handleOnchange
                                                                    }
                                                                    value={
                                                                      sub_cat.id
                                                                    }
                                                                    name="skill"
                                                                    className="sub_category_check"
                                                                    key={ind}
                                                                  />
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                          <hr />
                                                        </Form.Group>
                                                      ) : (
                                                        <>
                                                          <Form.Check
                                                            type="checkbox"
                                                            label={
                                                              expertise.category_name
                                                            }
                                                            onChange={(e) => {
                                                              this.handleOnchange(
                                                                e,
                                                                expertise.category_name
                                                              );
                                                            }}
                                                            value={expertise.id}
                                                            name="skill"
                                                          />
                                                          {expertise.category_name ===
                                                            "Others" &&
                                                            this.state
                                                              .isSkillOther && (
                                                              <Form.Group>
                                                                <Form.Control
                                                                  placeholder="Type your expertise name"
                                                                  value={
                                                                    formData.skill_other
                                                                  }
                                                                  onChange={
                                                                    this
                                                                      .handleOnchange
                                                                  }
                                                                  name="skill_other"
                                                                  type="text"
                                                                  validaterule={[
                                                                    "required",
                                                                  ]}
                                                                  validatemsg={[
                                                                    "This field is required",
                                                                  ]}
                                                                  required={
                                                                    this.state
                                                                      .isSkillOther
                                                                  }
                                                                />
                                                                <p className="invalid_input">
                                                                  {
                                                                    error.skill_other
                                                                  }
                                                                </p>
                                                              </Form.Group>
                                                            )}
                                                        </>
                                                      )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </Form.Group>
                                            <p className="invalid_input">
                                              {error.skill}
                                            </p>
                                          </div>

                                          <div className="specify_req">
                                            <Form.Group
                                              className="mb-3"
                                              controlId="exampleForm.ControlTextarea1"
                                            >
                                              <Form.Label>
                                                {
                                                  language.find_resources_how_many_people_label
                                                }
                                              </Form.Label>
                                              <Form.Control
                                                placeholder={
                                                  language.find_resources_type_here_plh
                                                }
                                                rows={3}
                                                value={formData.people_required}
                                                name="people_required"
                                                onChange={this.handleOnchange}
                                              />
                                            </Form.Group>
                                          </div>

                                          <div className="specify_req">
                                            <Form.Group>
                                              <Form.Label>
                                                {
                                                  language.find_resources_location_label
                                                }{" "}
                                                *
                                              </Form.Label>

                                              <AutoCompleteLocation
                                                handleGetLocation={
                                                  this.handleJobLocation
                                                }
                                                value={formData.job_location}
                                              />
                                            </Form.Group>
                                            <p className="invalid_input">
                                              {error.job_location}
                                            </p>
                                          </div>

                                          <div className="specify_req">
                                            <Form.Group
                                              className="mb-3"
                                              controlId="exampleForm.ControlTextarea1"
                                            >
                                              <Form.Label>
                                                {
                                                  language.find_resources_salary_range_label
                                                }
                                              </Form.Label>
                                              <div className="hmr_value">
                                                {formData.salary_range_min}-
                                                {formData.salary_range_max}
                                              </div>
                                              <Range
                                                allowCross={false}
                                                defaultValue={[
                                                  formData.salary_range_min,
                                                  formData.salary_range_max,
                                                ]}
                                                min={6000}
                                                max={150000}
                                                onChange={(value) => {
                                                  formData.salary_range_min =
                                                    value[0];
                                                  formData.salary_range_max =
                                                    value[1];

                                                  this.setState({ formData });
                                                }}
                                                tipFormatter={20}
                                              />
                                            </Form.Group>
                                          </div>

                                          <div className="specify_req">
                                            <Form.Group
                                              className="mb-3"
                                              controlId="exampleForm.ControlTextarea1"
                                            >
                                              <Form.Check
                                                type="checkbox"
                                                label={
                                                  language.find_resources_tick_box_if_you_immediate_label
                                                }
                                                onChange={this.handleOnchange}
                                                value={
                                                  formData.is_urgent === "0"
                                                    ? 1
                                                    : 0
                                                }
                                                name="is_urgent"
                                              />
                                            </Form.Group>
                                          </div>

                                          <div className="specify_req">
                                            <Form.Group
                                              className="mb-3"
                                              controlId="exampleForm.ControlTextarea1"
                                            >
                                              <Form.Label>
                                                {
                                                  language.find_resources_specific_requirement_type_label
                                                }
                                              </Form.Label>
                                              <Form.Control
                                                placeholder={
                                                  language.find_resources_your_comments_plh
                                                }
                                                as="textarea"
                                                rows={3}
                                                value={formData.comment}
                                                name="comment"
                                                onChange={this.handleOnchange}
                                              />
                                            </Form.Group>
                                          </div>

                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="btn_primary"
                                          >
                                            {language.equipment_submit_btn}
                                          </Button>
                                        </div>
                                      </Form>
                                    )}

                                    {/* HMC SERVICE REQUEST */}

                                    {formData.request_for === "6" && (
                                      <Form onSubmit={this.hmcServiceRequest}>
                                        <div className="reqf_item">
                                          <div className="select_services">
                                            <Form.Label>
                                              {
                                                language.hmc_service_equipment_model_name_label
                                              }{" "}
                                              *
                                            </Form.Label>
                                            <Select
                                              options={allMachineModels}
                                              onChange={(item) => {
                                                this.handleSelect(
                                                  "machine_hash_id",
                                                  item
                                                );
                                                error.machine_model_name = "";
                                                this.setState({ error });
                                              }}
                                              placeholder={
                                                language.hmc_service_select_equipment_model_name_plh
                                              }
                                              name="machine_hash_id"
                                            />
                                            <p className="invalid_input">
                                              {error.machine_model_name}
                                            </p>
                                          </div>

                                          <div className="select_services">
                                            <Form.Label>
                                              {
                                                language.hmc_service_equipment_brand_label
                                              }
                                            </Form.Label>
                                            <Select
                                              options={allBrands}
                                              onChange={(item) => {
                                                this.handleSelect(
                                                  "brand_hash_id",
                                                  item
                                                );
                                              }}
                                              placeholder={
                                                language.hmc_service_select_equipment_brand_plh
                                              }
                                              name="brand_hash_id"
                                            />
                                          </div>

                                          <div className="specify_req">
                                            <Form.Group>
                                              <Form.Label>
                                                {
                                                  language.hmc_service_location_label
                                                }{" "}
                                                *
                                              </Form.Label>

                                              <AutoCompleteLocation
                                                handleGetLocation={
                                                  this.handleJobLocation
                                                }
                                                value={formData.job_location}
                                              />
                                            </Form.Group>
                                            <p className="invalid_input">
                                              {error.job_location}
                                            </p>
                                          </div>

                                          <div className="select_services">
                                            <Form.Label>
                                              {language.hmc_service_issue_label}{" "}
                                              *
                                            </Form.Label>

                                            <Form.Control
                                              as="select"
                                              className="home_select"
                                              name="hmc_service_issue"
                                              value={formData.hmc_service_issue}
                                              onChange={this.handleOnchange}
                                              required={true}
                                            >
                                              <option value="">
                                                {
                                                  language.hmc_service_select_service_issue_plh
                                                }
                                              </option>
                                              {all_service_issue.map(
                                                (items, index) => {
                                                  return (
                                                    <option
                                                      value={items.id}
                                                      key={index}
                                                    >
                                                      {items.title}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </Form.Control>
                                          </div>

                                          <div className="specify_req">
                                            <Form.Group
                                              className="mb-3"
                                              controlId="exampleForm.ControlTextarea1"
                                            >
                                              <Form.Label>
                                                {
                                                  language.hmc_service_specific_requirement_type_label
                                                }
                                              </Form.Label>
                                              <Form.Control
                                                placeholder={
                                                  language.hmc_service_your_comments_plh
                                                }
                                                as="textarea"
                                                rows={3}
                                                value={formData.comment}
                                                name="comment"
                                                onChange={this.handleOnchange}
                                              />
                                            </Form.Group>
                                          </div>

                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="btn_primary"
                                          >
                                            {language.hmc_service_submit}
                                          </Button>
                                        </div>
                                      </Form>
                                    )}
                                  </div>
                                </Card.Body>
                              </Card>
                            </Tab>
                          </Tabs>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(RequestManagement);
