import React, { Component } from "react";
import { Button, Form, Dropdown } from "react-bootstrap";
import {
  hmc_user_onboard,
  Lead_Arrow_Img,
  Three_Dots,
  hmc_user_not_onboard,
  not_hmc_user,
  Upload_img_icon_img,
  pdf_preview,
  word_preview,
  cross_preview,
  Up_doc_icon_img,
  excel_preview,
} from "../../../assets/images";
import ReactTooltip from "react-tooltip";
import "./allLead.css";
import moment from "moment";
import _ from "lodash";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import DeletePopup from "../../../utils/DeletePopup";
import CustomPopup from "../../../utils/CustomPopup";
import CreatableSelect from "react-select/creatable";
import axios from "../../../config/axios";
import message from "../../../utils/messages";
import Select from "react-select";
import { LeadTypes } from "../../../utils/LeadTypes";
import ReactDatePicker from "react-datepicker";
import config from "../../../config/apiHeader";
import { PlaceHolder } from "../../../utils/PlaceHolder";
import StatusChanges from "../../../utils/StatusChanges";
import SetRemainder from "../../../utils/SetRemainder";
import LoadingOverlay from "react-loading-overlay";
import { MEDIA_BASE_URL, getFileExtension } from "../../../utils/Helper";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class PreeAssignedLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hash_id: "",
      clickedPosition: -1,

      // UPDATE STATUS
      leadHeader: [],
      get_sub_header: [],
      show: false,
      isAssignShowing: false,
      contact_list: [],

      selected_status_tags: [],
      subject: "",
      subject_id: "",
      sub_header_id: "",
      sub_header_title: "",
      lead_id: "",

      tags: [],
      docs_label: "",
      lead_assignee_activity_id: null,
      assigne_contact_id: "",
      date: "",
      financer_name: "",
      financer_contact_id: "",
      contact_person: "",
      contact_person_id: "",
      disbrusment_sub_status: "",
      asking_amount: "",
      disbrust_amount: "",
      approved_amount: "",
      down_payment: "",
      brokerage_rate: "",
      loan_interest: "",

      heading_type: 0, // 0 -> default, 1 -> loged in, 2 -> disbrusment, 3 -> closed, 4 -> dead

      // UPLOAD DOCUMENT
      error: {},
      isDelete: false,
      delete_id: "",

      // ASSIGNE SOMEONE
      assignee_name: "",
      assignee_phone: "",
      assign_to: "",
      notify_by: [],
      comments: "",
      isAssigne: false,

      // CLOSING DATE
      isClosingDate: false,
      cloasing_date: "",

      // COMMISION PAID
      isCommitionPaid: false,

      // SET REMAINDER
      isSetRemainder: false,
      lead_hash_id: "",

      // ADD DOCUMENT AND IMAGE
      isAddedDocument: false,
      equipment_images: [],
      old_images: [],
      old_attachment: [],
      equipment_docs: [],
      equipment_docs_preview: [],
      attachment_title: [],
      old_docs: [],
      isLoading: false,
      selected_financer: {},
      selected_header_id: -1,
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }

    let headers = this.props.leadHeader;
    let leadHeader = [];
    if (headers) {
      headers.forEach((header) => {
        // VERYFIED LEAD NOT OPEN IN MARKETING PERSON
        if (localStorage.getItem("lead_user_role") !== "6") {
          leadHeader.push(header);
        } else if (header.value !== 4) {
          leadHeader.push(header);
        }
        this.setState({ leadHeader });
      });
    }
  };

  handleSelectData = async (value, key) => {
    if (value) {
      await this.setState({ [key]: value.label, date: "" });
      let { error } = this.state;
      if (key === "subject") {
        error.subject = "";
        if (value.__isNew__) {
          await this.setState({
            subject_id: "",
            error,
            get_sub_header: [],
            heading_type: 0,
          });
        } else {
          let get_sub_header = value.header.get_sub_header;
          await this.setState({
            subject_id: value.value,
            error,
            get_sub_header,
            heading_type: 0,
          });

          // FOR LOGGED IN
          if (value.value === 6) {
            await this.setState({ heading_type: 1 });
            return;
          }
          //    FOR Disbrusment
          if (value.value === 7) {
            await this.setState({ heading_type: 2 });
            return;
          }

          //    FOR CLOSED
          if (value.value === 8) {
            await this.setState({ heading_type: 3 });
            return;
          }

          //    FOR DEAD
          if (value.value === 9) {
            await this.setState({ heading_type: 4 });
            return;
          }
        }
      }
      if (key === "assignee_name") {
        let { contact } = value;
        await this.setState({
          assign_to: contact.user_id,
          assigne_contact_id: contact.id,
        });
      }
    } else {
      await this.setState({
        subject_id: "",
        get_sub_header: [],
        heading_type: 0,
        financer_name: "",
        financer_contact_id: "",
        contact_person: "",
        contact_person_id: "",
        disbrusment_sub_status: "",
        date: "",
      });
    }
  };

  handleOnChange = async (e) => {
    e.persist();
    let { name, value } = e.target;

    if (name === "notify_by") {
      let { notify_by } = this.state;

      if (
        _.find(notify_by, (val) => {
          return val === value;
        })
      ) {
        notify_by = _.filter(notify_by, (val) => {
          return val !== value;
        });
      } else {
        notify_by = _.concat(notify_by, value);
      }
      await this.setState({ notify_by });
      return;
    }

    if (name === "attachment_type") {
      let files = e.target.files;
      let lead_assignee_attachment = [];
      let attachment_title;
      let attachment_type = "2";

      Object.keys(files).forEach(async (key) => {
        let file = files[key];
        let validExtension = ["png", "jpg", "jpeg", "doc", "docx", "pdf"];
        if (file !== undefined) {
          let extension = this.getExtension(file).toLowerCase();
          if (
            extension !== undefined &&
            _.findIndex(validExtension, (exe) => {
              return exe === extension;
            }) !== -1
          ) {
            let imageExe = ["png", "jpg", "jpeg"];
            if (
              _.findIndex(imageExe, (exe) => {
                return exe === extension;
              }) !== -1
            ) {
              attachment_type = "1";
            }
            lead_assignee_attachment.push(file);
            attachment_title = file.name;
          } else {
            message.error(file.name + " is not a valid document");
          }
        }
        await this.setState({
          lead_assignee_attachment,
          attachment_title,
          attachment_type,
        });
      });
      return;
    }

    if (name === "sub_header_id") {
      let { error } = this.state;
      if (value) {
        error.sub_header_id = "";
        let sub_header = this.state.get_sub_header[value];
        await this.setState({
          sub_header_id: sub_header.id,
          sub_header_title: sub_header.sub_header_label,
          error,
        });
      } else {
        await this.setState({ sub_header_id: "", sub_header_title: "" });
      }
      return;
    }

    if (name === "financer_contact_id") {
      let { financer_name, financer_contact_id } = this.state;

      const financer = this.props.financerList[value];

      financer_name = financer.company_name;
      financer_contact_id = financer.id;
      await this.setState({ financer_name, financer_contact_id });
      return;
    }

    if (name === "contact_person_id") {
      let { contact_person, contact_person_id } = this.state;

      const person = this.props.contactPersonList[value];
      contact_person = person.first_name + " " + person.last_name;
      contact_person_id = person.id;

      await this.setState({ contact_person, contact_person_id });
      return;
    }

    this.setState({ [name]: value });
  };

  getExtension = (file) => {
    let regex = new RegExp("[^.]+$");
    return file.name.match(regex)[0];
  };

  updateStatus = (data) => {
    let {
      subject,

      assignee_name,
      assignee_phone,
      assign_to,
      notify_by,
      comments,
      docs_label,
      tags,
      lead_assignee_activity_id,
      subject_id,
      sub_header_id,
      sub_header_title,
      assigne_contact_id,
      financer_name,
      financer_contact_id,
      contact_person,
      contact_person_id,
      disbrusment_sub_status,
      date,
      asking_amount,
      disbrust_amount,
      approved_amount,
      down_payment,
      brokerage_rate,
      loan_interest,
      cloasing_date,
      appac_no,
      hmc_percentage,
      asset_value,
      only_admin_view,
    } = data;

    // if (!subject) {
    //   error.subject = "Please enter header";
    //   this.setState({ error });
    //   return;
    // } else {
    //   error.subject = "";
    //   this.setState({ error });
    // }

    // if (this.state.heading_type === 4 && !comments) {
    //   error.comments = "Please enter comments";
    //   this.setState({ error });
    //   return;
    // } else {
    //   error.comments = "";
    //   this.setState({ error });
    // }

    // if (get_sub_header && get_sub_header.length > 0) {
    //   if (!sub_header_id) {
    //     error.sub_header_id = "Please select sub header";
    //     this.setState({ error });
    //     return;
    //   } else {
    //     error.sub_header_id = "";
    //     this.setState({ error });
    //   }
    // } else {
    //   error.sub_header_id = "";
    //   this.setState({ error });
    // }

    const formData = {
      subject,
      subject_id,
      user_hash_id: this.state.hash_id,
      lead_id: this.state.lead_id,
      assignee_name,
      assignee_phone,
      assign_to,
      notify_by: notify_by.toString(),
      comments,
      docs_label,
      tags,
      dashboard_id: this.props.dashboard_id,
      lead_assignee_activity_id,

      medium: "web",
      section_name: "lm_preassign_update_status",
      call_from: "pree_assigned_list",
      sub_header_id,
      sub_header_title,
      assigne_contact_id,
      financer_name,
      financer_contact_id,
      contact_person,
      contact_person_id,
      disbrusment_sub_status,
      date,
      asking_amount,
      disbrust_amount,
      approved_amount,
      down_payment,
      brokerage_rate,
      loan_interest,
      cloasing_date,
      appac_no,
      hmc_percentage,
      asset_value,
      only_admin_view,
    };

    this.setState({ show: false });
    this.props.setLoading(true);

    this.apiCall(formData, config);
  };

  closePopup = async (show) => {
    await this.setState({
      show,
      assign_to: "",
      assignee_name: "",
      assignee_phone: "",
      comments: "",
      docs_label: "",
      lead_assignee_activity_id: null,
      lead_id: "",
      notify_by: [],
      subject: "",
      subject_id: "",
      tags: [],
      selected_status_tags: [],
      attachment_type: "",
      attachment_title: "",
      lead_assignee_attachment: [],
      relative_type: "",
      error: {},

      get_sub_header: [],
      isAssignShowing: false,
      sub_header_id: "",
      sub_header_title: "",
      assigne_contact_id: "",

      heading_type: 0,

      date: "",
      financer_name: "",
      financer_contact_id: "",
      contact_person: "",
      contact_person_id: "",
      disbrusment_sub_status: "",

      isAssigne: false,

      isClosingDate: false,
      closing_date: "",
      lead_hash_id: "",
      selected_financer: {},
      selected_header_id: -1,
    });
  };

  assigneToSomeOne = () => {
    let {
      hash_id,
      lead_id,
      assignee_name,
      assignee_phone,
      assign_to,
      notify_by,
      comments,

      lead_assignee_activity_id,

      assigne_contact_id,
      error,
      subject,
      get_sub_header,
      sub_header_id,
      sub_header_title,
    } = this.state;

    if (!subject) {
      error.subject = "Please enter header";
      this.setState({ error });
      return;
    } else {
      error.subject = "";
      this.setState({ error });
    }

    if (get_sub_header && get_sub_header.length > 0) {
      if (!sub_header_id) {
        error.sub_header_id = "Please select sub header";
        this.setState({ error });
        return;
      } else {
        error.sub_header_id = "";
        this.setState({ error });
      }
    } else {
      error.sub_header_id = "";
      this.setState({ error });
    }

    if (!assigne_contact_id) {
      error.assigne_contact_id = "Please Select Assigneer Name";
      this.setState({ error });
      return;
    } else {
      error.assigne_contact_id = "";
      this.setState({ error });
    }

    if (!comments) {
      error.comments = "Please enter comments";
      this.setState({ error });
      return;
    } else {
      error.comments = "";
      this.setState({ error });
    }

    const formData = {
      user_hash_id: hash_id,
      lead_id,
      assignee_name,
      assignee_phone,
      assign_to,
      notify_by: notify_by.toString(),
      comments,

      dashboard_id: this.props.dashboard_id,
      lead_assignee_activity_id,

      medium: "web",
      section_name: "lm_preassign_update_status",
      call_from: "pree_assigned_list",

      assigne_contact_id,
      subject,

      sub_header_id,
      sub_header_title,
    };

    this.setState({ isAssigne: false });
    this.props.setLoading(true);

    this.apiCall(formData, config);
  };

  expectedClosingDate = () => {
    let {
      hash_id,
      lead_id,
      assignee_name,
      assignee_phone,
      assign_to,
      comments,

      lead_assignee_activity_id,

      assigne_contact_id,

      cloasing_date,
    } = this.state;

    const formData = {
      user_hash_id: hash_id,
      lead_id,
      assignee_name,
      assignee_phone,
      assign_to,
      notify_by: "",
      comments,

      dashboard_id: this.props.dashboard_id,
      lead_assignee_activity_id,

      medium: "web",
      section_name: "lm_preassign_update_status",
      call_from: "pree_assigned_list",

      assigne_contact_id,
      cloasing_date,
      subject: null,
    };

    this.setState({ isClosingDate: false });
    this.props.setLoading(true);

    this.apiCall(formData, config);
  };

  commisionPaid = () => {
    let {
      hash_id,
      lead_id,
      assignee_name,
      assignee_phone,
      assign_to,
      comments,

      lead_assignee_activity_id,

      assigne_contact_id,
    } = this.state;

    const formData = {
      user_hash_id: hash_id,
      lead_id,
      assignee_name,
      assignee_phone,
      assign_to,
      notify_by: "",
      comments,

      dashboard_id: this.props.dashboard_id,
      lead_assignee_activity_id,

      medium: "web",
      section_name: "lm_preassign_update_status",
      call_from: "pree_assigned_list",

      assigne_contact_id,
      subject: "Commision Paid",
    };

    this.setState({ lead_id: "" });
    this.props.setLoading(true);

    this.apiCall(formData, config);
  };

  apiCall = (formData, config) => {
    axios
      .post("/update-change-status", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          this.closePopup(false);
          this.props.getLead();

          message.success(res.data.message);
        } else {
          this.props.setLoading(false);
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.props.setLoading(false);
        this.closePopup(false);
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  setRemainder = (data) => {
    let { hash_id, lead_hash_id } = this.state;
    let {
      meeting_time,
      meeting_date,
      meeting_label,
      meeting_description,
      to_contact_id,
    } = data;

    const formData = {
      lead_hash_id,
      meeting_time,
      meeting_date,
      meeting_label,
      meeting_description,
      to_contact_id,
      from_contact_id: hash_id,
      medium: "web",
      section_name: "lm_pre_assign_set_remainder",
    };

    this.props.setLoading(true);

    this.setState({ isSetRemainder: false, lead_hash_id: "" });

    axios
      .post("/add-lead-reminder", formData, config)
      .then((res) => {
        this.props.setLoading(false);

        if (res.data.status === "1") {
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.props.setLoading(false);
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  closeAddDocumentPopup = (isAddedDocument) => {
    this.setState({
      isAddedDocument,
      lead_id: "",
      equipment_images: [],
      old_images: [],
      old_attachment: [],
      equipment_docs: [],
      equipment_docs_preview: [],
      attachment_title: [],
      old_docs: [],
      isLoading: false,
    });
  };

  handleDocument = async (e) => {
    e.persist();

    let { equipment_docs, equipment_docs_preview, attachment_title } =
      this.state;
    let files = e.target.files;

    // console.log(files);
    let validExtension = ["doc", "docx", "pdf", "xls", "xlsx"];

    Object.keys(files).forEach(async (key) => {
      let file = files[key];
      if (file !== undefined) {
        let extension = getFileExtension(file);

        if (
          extension !== undefined &&
          _.findIndex(validExtension, (exe) => {
            return exe === extension;
          }) !== -1
        ) {
          let preview;
          if (extension === "docx" || extension === "doc") {
            preview = word_preview;
          } else if (extension === "pdf") {
            preview = pdf_preview;
          } else {
            preview = excel_preview;
          }
          equipment_docs.push(file);

          equipment_docs_preview.push({ name: file.name, preview });
          attachment_title.push(file.name);

          this.setState({
            equipment_docs,
            equipment_docs_preview,
          });
        } else {
          message.error(file.name + " is not a valid document");
        }
      }
    });
  };

  handleImages = async (e) => {
    e.persist();

    let { equipment_images } = this.state;

    let files = e.target.files;
    let validExtension = ["png", "jpg", "jpeg"];
    Object.keys(files).forEach(async (key) => {
      let file = files[key];
      if (file !== undefined) {
        let extension = this.getExtension(file).toLowerCase();
        if (
          extension !== undefined &&
          _.findIndex(validExtension, (exe) => {
            return exe === extension;
          }) !== -1
        ) {
          equipment_images.push(file);
        } else {
          message.error(file.name + " is not a valid image");
        }
      }
      await this.setState({ equipment_images });
    });
  };

  leadDocumentUpload = () => {
    this.setState({ isAddedDocument: false });
    let { lead_id, equipment_docs, attachment_title, old_attachment, hash_id } =
      this.state;

    let formData = new FormData();
    formData.append("lead_id", lead_id);
    old_attachment.forEach((id, key) => {
      formData.append(`old_attachment[${key}]`, id);
    });
    attachment_title.forEach((title, key) => {
      formData.append(`attachment_title[${key}]`, title);
    });
    formData.append("attachment_type", 2);
    formData.append("medium", "web");
    formData.append("section_name", "lm_pre_assign_doc_upload");
    formData.append("lead_assignee_act_id", "");
    formData.append("hash_id", hash_id);

    equipment_docs.forEach((file, index) => {
      formData.append(`lead_attachment[${index}]`, file);
    });

    this.props.setLoading(true);

    axios
      .post("/lead-document-upload", formData, config)
      .then((res) => {
        this.leadImageUpload();

        if (res.data.status === "1") {
          message.success("Document Upload Successfully");

          this.setState({
            equipment_docs: [],
            equipment_docs_preview: [],
            attachment_title: [],
            old_docs: [],
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.props.setLoading(false);
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  leadImageUpload = () => {
    let { equipment_images, lead_id, old_attachment, hash_id } = this.state;

    let formData = new FormData();
    formData.append("lead_id", lead_id);
    formData.append("attachment_title", []);
    formData.append("attachment_type", 1);
    formData.append("medium", "web");
    formData.append("section_name", "lm_pre_assign_doc_upload");
    formData.append("lead_assignee_act_id", "");
    formData.append("hash_id", hash_id);

    old_attachment.forEach((id, key) => {
      formData.append(`old_attachment[${key}]`, id);
    });

    equipment_images.forEach((file, index) => {
      formData.append(`lead_attachment[${index}]`, file);
    });

    this.props.setLoading(true);

    axios
      .post("/lead-document-upload", formData, config)
      .then((res) => {
        this.props.setLoading(false);

        if (res.data.status === "1") {
          message.success("Image Upload Successfully");
          this.closeAddDocumentPopup(false);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.props.setLoading(false);
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getAllDocumentsLeadWise = (lead_hash_id) => {
    this.setState({ isLoading: true });
    const formData = {
      lead_hash_id,
      medium: "web",
      section_name: "lm_pre_assign_get_all_doc",
      call_from: "pree_assigned_list",
    };
    axios
      .post("/get-lead-all-document", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let old_attachment = [];
          let old_images = [];
          let old_docs = [];
          res.data.data.forEach((doc) => {
            old_attachment.push(doc.id);
            if (doc.attachment_type === "1") {
              old_images.push({
                url: doc.attachment_url,
                id: doc.id,
              });
            } else {
              let url = doc.attachment_url;
              let preview;

              if (url.endsWith(".doc") || url.endsWith(".docx")) {
                preview = word_preview;
              } else if (url.endsWith(".pdf")) {
                preview = pdf_preview;
              } else {
                preview = excel_preview;
              }

              old_docs.push({
                url,
                id: doc.id,
                name: doc.attachment_title,
                preview,
              });
            }
          });

          this.setState({ old_attachment, old_images, old_docs });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });

        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  deleteOldImage = async (id, pos) => {
    let { old_attachment, old_images } = this.state;
    old_images.splice(pos, 1);

    let index = old_attachment.indexOf(id);
    old_attachment.splice(index, 1);

    await this.setState({ old_images, old_attachment });
  };

  deleteImage = async (pos) => {
    let { equipment_images } = this.state;
    equipment_images.splice(pos, 1);
    await this.setState({ equipment_images });
  };

  getSelectedFinancer = () => {
    let { lead_hash_id } = this.state;
    const formData = {
      lead_hash_id,
      call_from: "pree_assigned_list",
    };
    this.props.setLoading(true);
    axios
      .post("/get-selected-financer-name", formData, config)
      .then((res) => {
        this.props.setLoading(false);
        if (res.data.status === "1") {
          let { financer_details } = res.data.data;

          let selected_financer = _.findIndex(this.props.financerList, (o) => {
            return o.id === financer_details.financer_contact_id;
          });

          this.setState({
            selected_financer: {
              position: selected_financer,
              financer_contact_id: financer_details.financer_contact_id,
              financer_name: financer_details.financer_name,
            },
          });
        }
      })
      .catch((err) => {
        this.props.setLoading(false);
        console.error(err);
      });
  };

  render() {
    let { collapsedArray, pre_assigned_lead } = this.props;
    let {
      get_sub_header,
      equipment_images,
      equipment_docs_preview,
      old_images,
      old_docs,
    } = this.state;

    const popperConfig = {
      strategy: "fixed",
    };

    let { loading, language } = this.props.language;
    let lead_action = {};
    let add_documents_images = {};
    let expected_closing_date = {};
    let assigne_to_someone = {};
    let commision_paid = {};

    if (!loading && language) {
      lead_action = language.lead_action;
      expected_closing_date = language.expected_closing_date;
      add_documents_images = language.add_documents_images;
      assigne_to_someone = language.assigne_to_someone;
      commision_paid = language.commision_paid;

      language = language.all_leads;
    } else {
      language = {};
    }
    return (
      <>
        {/* DELETE LEAD */}
        <DeletePopup
          show={this.state.isDelete}
          message={lead_action.delete_alert_msg}
          yes_msg={lead_action.yes_btn}
          no_msg={lead_action.no_btn}
          no={() => {
            this.setState({
              isDelete: false,
            });
          }}
          yes={() => {
            this.setState({
              isDelete: false,
            });
            this.props.deleteLead(this.state.delete_id);
          }}
        />

        {/* ADD STATUS */}
        <StatusChanges
          show={this.state.show}
          leadHeader={this.state.leadHeader}
          financerList={this.props.financerList}
          contactPersonList={this.props.contactPersonList}
          allTags={this.props.allTags}
          updateStatus={this.updateStatus}
          getSelectedFinancer={this.getSelectedFinancer}
          selected_financer={this.state.selected_financer}
          selected_header_id={this.state.selected_header_id}
          onClose={this.closePopup}
        />

        {/* ASSIGN TO SOME ONE */}
        <CustomPopup
          title={assigne_to_someone.assigne_to_someone_h}
          show={this.state.isAssigne}
          onClose={this.closePopup}
          isCenter={true}
        >
          <div className="custom_popuped heading_popuped">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  {assigne_to_someone.status_label}
                  <span className="required">*</span>
                </Form.Label>
                <div className="heading_tag">
                  <CreatableSelect
                    isClearable
                    onChange={(value) => {
                      this.handleSelectData(value, "subject");
                    }}
                    options={this.props.leadHeader}
                    placeholder={assigne_to_someone.select_status_abel_plh}
                    name="subject"
                  />
                </div>
                <p className="invalid_input">
                  {this.state.error.subject &&
                    assigne_to_someone.please_enter_header_errormsg}
                </p>
              </Form.Group>

              {get_sub_header.length > 0 && (
                <Form.Group className="mb-3" controlId="sub_status">
                  <Form.Label>
                    {assigne_to_someone.sub_status_label}
                    <span className="required">*</span>
                  </Form.Label>
                  <div className="heading_tag">
                    <Form.Control
                      as="select"
                      name="sub_header_id"
                      onChange={this.handleOnChange}
                      value={this.state.sub_status}
                    >
                      <option value="">
                        {assigne_to_someone.select_status_plh}
                      </option>
                      {get_sub_header.map((header, key) => {
                        return (
                          <option value={key} key={key}>
                            {header.sub_header_label}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </div>
                  <p className="invalid_input">
                    {this.state.error.sub_header_id &&
                      assigne_to_someone.please_select_sub_header_errormsg}
                  </p>
                </Form.Group>
              )}

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  {assigne_to_someone.assigne_to_someone_label}
                  <span className="required">*</span>
                </Form.Label>
                <div className="heading_tag">
                  <Select
                    options={this.props.contactList}
                    onChange={(value) => {
                      this.handleSelectData(value, "assignee_name");
                    }}
                    placeholder={assigne_to_someone.select_assigned_user_plh}
                    name="assignee_name"
                  />
                </div>
                <p className="invalid_input">
                  {this.state.error.assigne_contact_id &&
                    assigne_to_someone.please_select_assigneer_name_errormsg}
                </p>
              </Form.Group>

              <div className="notify_section">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <input
                    type="checkbox"
                    id="html"
                    name="notify_by"
                    onChange={this.handleOnChange}
                    value="1"
                  />
                  <label htmlFor="html">
                    {assigne_to_someone.notify_via_email}
                  </label>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <input
                    type="checkbox"
                    id="html1"
                    name="notify_by"
                    onChange={this.handleOnChange}
                    value="2"
                  />
                  <label htmlFor="html1">
                    {assigne_to_someone.notify_via_sms}
                  </label>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <input
                    type="checkbox"
                    id="html2"
                    name="notify_by"
                    onChange={this.handleOnChange}
                    value="3"
                  />
                  <label htmlFor="html2">
                    {assigne_to_someone.notify_via_whatsapp}
                  </label>
                </Form.Group>
              </div>

              <Form.Group className="mb-3" controlId="comment">
                <Form.Label>
                  {assigne_to_someone.comments_label}
                  <span className="required">*</span>
                </Form.Label>

                <Form.Control
                  as="textarea"
                  rows="3"
                  name="comments"
                  value={this.state.comments}
                  placeholder={assigne_to_someone.your_comment_plh}
                  onChange={this.handleOnChange}
                />
                <p className="invalid_input">
                  {this.state.error.comments &&
                    assigne_to_someone.please_enter_comments_errormsg}
                </p>
              </Form.Group>

              {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Add Attachment</Form.Label>
                        <div className="heading_tag">
                          <div className="upload-btn-wrapper2">
                            <Button className="btn2">
                              {this.state.attachment_title || "Document name"}
                            </Button>
                            <Form.Control
                              type="file"
                              onChange={this.handleOnChange}
                              name="attachment_type"
                              accept="image/png, image/jpg, image/jpeg,.doc, .docx, .pdf"
                            />
                          </div>
                          <div className="heading_tag_text2">
                            <span>
                              <img alt="HMC" src={Attachment_Icon_Img} />
                            </span>
                          </div>
                        </div>
                      </Form.Group> */}
            </Form>
          </div>

          <div className="status_footer">
            <Button
              type="submit"
              className="status_update"
              onClick={this.assigneToSomeOne}
            >
              {assigne_to_someone.assigne_btn}
            </Button>
          </div>
        </CustomPopup>

        {/* EXPECTED CLOSING DATE */}
        <CustomPopup
          show={this.state.isClosingDate}
          title={expected_closing_date.add_expected_closing_date_h}
          onClose={() => {
            this.setState({
              isClosingDate: false,
              cloasing_date: "",
              lead_id: "",
            });
          }}
          isCenter={true}
        >
          <Form.Group className="mb-3" controlId="cloasing_date">
            <Form.Label>
              {expected_closing_date.expected_closing_date_h}
            </Form.Label>
            <ReactDatePicker
              selected={
                this.state.cloasing_date && new Date(this.state.cloasing_date)
              }
              className="react_date"
              dateFormat="dd-MM-yyyy"
              onChange={(cloasing_date) => {
                this.setState({
                  cloasing_date: moment(cloasing_date).format("YYYY-MM-DD"),
                });
              }}
              placeholderText={PlaceHolder.DATE}
              minDate={new Date()}
            />
          </Form.Group>

          <Form.Check type="checkbox" id="setNull">
            <Form.Check.Input
              type="checkbox"
              checked={!this.state.cloasing_date}
              onChange={() => {
                this.setState({ cloasing_date: "" });
              }}
            />
            <Form.Check.Label>
              {expected_closing_date.choose_closing_date_or_set_null_value}
            </Form.Check.Label>
          </Form.Check>

          <div className="status_footer">
            <Button
              type="submit"
              className="status_update"
              onClick={this.expectedClosingDate}
            >
              {expected_closing_date.update_btn}
            </Button>
          </div>
        </CustomPopup>

        {/* SET REMINDER */}

        <SetRemainder
          show={this.state.isSetRemainder}
          contactList={this.props.contactList}
          onClose={() => {
            this.setState({ isSetRemainder: false });
          }}
          setRemainder={this.setRemainder}
        />

        {/* COMMISION PAID */}
        <DeletePopup
          show={this.state.isCommitionPaid}
          message={commision_paid.commision_paid_alert_msg}
          yes_msg={commision_paid.yes_btn}
          no_msg={commision_paid.no_btn}
          no={() => {
            this.setState({
              isCommitionPaid: false,
              lead_id: "",
            });
          }}
          yes={() => {
            this.setState({
              isCommitionPaid: false,
            });
            this.commisionPaid();
          }}
        />

        {/* ADD DOCUMENT */}
        <CustomPopup
          show={this.state.isAddedDocument}
          title={add_documents_images.add_documents_images_h}
          onClose={this.closeAddDocumentPopup}
          isCenter={true}
        >
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text="Loading ..."
          >
            {/* IMAGE */}
            <Form.Group>
              <Form.Label>
                {add_documents_images.add_equipment_images}
              </Form.Label>

              <div className="upload_image_box">
                {old_images &&
                  old_images.map((image, key) => {
                    return (
                      <div className="image_box" key={key}>
                        <img alt="HMC" src={MEDIA_BASE_URL + image.url} />
                        <span
                          className="close_preview"
                          onClick={() => {
                            this.deleteOldImage(image.id, key);
                          }}
                        >
                          <img alt="HMC" src={cross_preview} />
                        </span>
                      </div>
                    );
                  })}

                {equipment_images &&
                  equipment_images.map((preview, index) => {
                    return (
                      <div className="image_box" key={index}>
                        <img alt="HMC" src={URL.createObjectURL(preview)} />
                        <span
                          className="close_preview"
                          onClick={() => {
                            this.deleteImage(index);
                          }}
                        >
                          <img alt="HMC" src={cross_preview} />
                        </span>
                      </div>
                    );
                  })}

                <div className="upload_box">
                  <div className="upload_btn_wrappererer">
                    <button className="btnner">
                      <img alt="HMC" src={Upload_img_icon_img} />
                    </button>

                    <Form.Control
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      name="images"
                      multiple
                      onChange={this.handleImages}
                    />
                  </div>
                </div>
              </div>
            </Form.Group>

            {/* DOCUMENT */}
            <Form.Group>
              <Form.Label>{add_documents_images.add_document}</Form.Label>

              <div className="cert_box">
                {old_docs &&
                  old_docs.map((doc, index) => {
                    return (
                      <div className="cert_box_inner" key={index}>
                        <div className="cret_file">
                          <img alt="HMC" src={doc.preview} />
                        </div>
                        <Form.Label>{doc.name}</Form.Label>
                      </div>
                    );
                  })}
                {equipment_docs_preview &&
                  equipment_docs_preview.map((doc, index) => {
                    return (
                      <div className="cert_box_inner" key={index}>
                        <div className="cret_file">
                          <img alt="HMC" src={doc.preview} />
                        </div>
                        <Form.Label>{doc.name}</Form.Label>
                        <div className="click_drop"></div>
                      </div>
                    );
                  })}

                <div className="cert_box_upload">
                  <div className="upload_btn_wrappererer">
                    <button className="btnner">
                      <img alt="HMC" src={Up_doc_icon_img} />
                    </button>
                    <Form.Control
                      type="file"
                      accept=".doc, .docx, .pdf, .xls,.xlsx"
                      onChange={this.handleDocument}
                      multiple
                    />
                  </div>
                </div>
              </div>
            </Form.Group>
            <div className="status_footer">
              <Button
                type="submit"
                className="status_update"
                onClick={this.leadDocumentUpload}
              >
                {add_documents_images.submit_btn}
              </Button>
            </div>
          </LoadingOverlay>
        </CustomPopup>

        <div className="my_lead_inner_page my_lead_inner_box">
          <div className="mylead_inner_inner_page my_lead_inner_content preassigned">
            {pre_assigned_lead.map((lead, index) => {
              let create_date = moment(lead.created_at).format("DD/MM/YYYY");
              let closing_date = lead.expected_closing_date
                ? moment(lead.expected_closing_date).format("DD/MM/YYYY")
                : "";
              let updated_at = lead.updated_at
                ? moment(lead.updated_at).format("DD/MM/YYYY")
                : "";
              let tags = lead.tags && lead.tags.split(",");
              let header_status =
                lead.header_status && lead.header_status.split("-");

              let hmc_user_type, hmc_user_msg;
              if (lead.is_login === "1") {
                hmc_user_msg = language.hmc_user_onboard;
                hmc_user_type = hmc_user_onboard;
              } else if (lead.is_login === "0") {
                hmc_user_msg = language.hmc_user_not_onboard;
                hmc_user_type = hmc_user_not_onboard;
              } else if (lead.is_login === null) {
                hmc_user_msg = language.not_hmc_user;
                hmc_user_type = not_hmc_user;
              }

              return (
                <div
                  key={index}
                  className={`lead_equipment eqp_lead ${
                    this.state.clickedPosition === index ? "blue_border" : ""
                  }`}
                  onClick={() => {
                    this.setState({ clickedPosition: index });
                  }}
                >
                  <div className="eqp_lead_part">
                    <div className="lead_eq_inner">
                      <label>{language.creator_name}</label>
                      <span data-tip data-for={`creator_name${index}`}>
                        {lead.creator_name}
                        <ReactTooltip
                          id={`creator_name${index}`}
                          place="top"
                          effect="solid"
                        >
                          {lead.creator_name}
                        </ReactTooltip>
                      </span>
                    </div>

                    <div className="lead_eq_inner">
                      <label>{language.customer_name}</label>
                      <div className="d-flex align-items-center">
                        <label className="lead_tooltip">
                          <img
                            alt="HMC"
                            src={hmc_user_type}
                            data-tip
                            data-for={`user_type${index}`}
                          />
                          <ReactTooltip
                            id={`user_type${index}`}
                            place="top"
                            effect="solid"
                          >
                            {hmc_user_msg}
                          </ReactTooltip>
                        </label>
                        <span>
                          <label data-tip data-for={`customer_name${index}`}>
                            {lead.customer_name}
                            <ReactTooltip
                              id={`customer_name${index}`}
                              place="top"
                              effect="solid"
                            >
                              {lead.customer_name + ", " + lead.customer_phone}
                              ,(
                              {lead.lead_type === "1"
                                ? language.customer
                                : lead.lead_type === "2"
                                ? language.dealer
                                : language.financer}
                              )
                            </ReactTooltip>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="lead_eq_inner">
                      <label>{language.assignee_name}</label>
                      <span data-tip data-for={`assignee_name${index}`}>
                        {lead.assignee_name}
                        <ReactTooltip
                          id={`assignee_name${index}`}
                          place="top"
                          effect="solid"
                        >
                          {lead.assignee_name}
                        </ReactTooltip>
                      </span>
                    </div>

                    <div className="lead_eq_inner">
                      <label>{language.current_status}</label>
                      {header_status &&
                        header_status.length > 0 &&
                        header_status[0] !== "" && (
                          <div className="pending">{header_status[0]}</div>
                        )}
                    </div>

                    <div className="lead_eq_inner">
                      <label>{language.sub_status}</label>
                      {header_status && header_status.length > 0 && (
                        <>
                          <span data-tip data-for={`header_status${index}`}>
                            {header_status[1]}
                            <ReactTooltip
                              id={`header_status${index}`}
                              place="top"
                              effect="solid"
                            >
                              {header_status[1]}
                            </ReactTooltip>
                          </span>
                        </>
                      )}
                    </div>

                    <div className="lead_eq_inner">
                      <label>{language.action}</label>
                      <div className="lead_label_inner">
                        <Dropdown className="select_box_preassigneds">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <img alt="HMC" src={Three_Dots} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu popperConfig={popperConfig}>
                            <div
                              className="dropdown-item action-cursor"
                              onClick={() => {
                                this.setState({
                                  show: true,
                                  lead_id: lead.id,
                                  lead_hash_id: lead.hash_id,
                                  selected_header_id:
                                    header_status && header_status.length > 0
                                      ? header_status[2]
                                      : -1,
                                });
                              }}
                            >
                              {lead_action.update_status}
                            </div>

                            <Link
                              to={{
                                pathname: `/edit-lead/${lead.hash_id}`,
                                state: {
                                  section_name: "pre_assigned",
                                  lead_types: LeadTypes.PRE_ASSIGN_LEAD,
                                },
                              }}
                              className="dropdown-item"
                              role="button"
                            >
                              {lead_action.edit_lead}
                            </Link>

                            <Link
                              to={{
                                pathname: `/feedback-history/${lead.id}/${lead.hash_id}/pree_assigned_list/${this.props.dashboard_id}`,
                                state: {
                                  currentPage: this.props.currentPage,
                                  lead_types: LeadTypes.PRE_ASSIGN_LEAD,
                                  formData: this.props.formData,
                                },
                              }}
                              className="dropdown-item"
                              role="button"
                            >
                              {lead_action.status_history}
                            </Link>

                            {lead.lead_type === "1" && (
                              <>
                                <div
                                  className="dropdown-item action-cursor"
                                  onClick={() => {
                                    this.setState({
                                      isAssigne: true,
                                      lead_id: lead.id,
                                    });
                                  }}
                                >
                                  {lead_action.assign_to_someone}
                                </div>

                                {/* ONLY MARKETING PERSON, CALL SENTER HEAD, TEAM LEAD */}
                                {(localStorage.getItem("lead_user_role") ===
                                  "6" ||
                                  localStorage.getItem("lead_user_role") ===
                                    "5" ||
                                  localStorage.getItem("lead_user_role") ===
                                    "8") && (
                                  <div
                                    className="dropdown-item action-cursor"
                                    onClick={() => {
                                      this.setState({
                                        isClosingDate: true,
                                        lead_id: lead.id,
                                        cloasing_date:
                                          lead.expected_closing_date,
                                      });
                                    }}
                                  >
                                    {lead_action.expected_closing_date}
                                  </div>
                                )}

                                {/* ONLY ADMIN */}
                                {localStorage.getItem("lead_user_role") ===
                                  "1" && (
                                  <>
                                    <div
                                      className="dropdown-item action-cursor"
                                      onClick={() => {
                                        this.setState({
                                          lead_id: lead.id,
                                          isCommitionPaid: true,
                                        });
                                      }}
                                    >
                                      {lead_action.commition_paid}
                                    </div>

                                    <Link
                                      to={{
                                        pathname: `/payment-invoice/${lead.hash_id}`,
                                        state: {
                                          section_name: "pre_assigned",
                                          lead_types: LeadTypes.PRE_ASSIGN_LEAD,
                                        },
                                      }}
                                      className="dropdown-item"
                                      role="button"
                                    >
                                      {lead_action.payment_invoice}
                                    </Link>
                                  </>
                                )}

                                <div
                                  className="dropdown-item action-cursor"
                                  onClick={() => {
                                    this.setState({
                                      isSetRemainder: true,
                                      lead_hash_id: lead.hash_id,
                                    });
                                  }}
                                >
                                  {lead_action.reminder_schedular}
                                </div>

                                <div
                                  className="dropdown-item action-cursor"
                                  onClick={() => {
                                    this.setState({
                                      lead_id: lead.id,
                                      isAddedDocument: true,
                                    });
                                    this.getAllDocumentsLeadWise(lead.hash_id);
                                  }}
                                >
                                  {lead_action.add_documents_Images}
                                </div>

                                <div
                                  className="dropdown-item action-cursor"
                                  onClick={() => {
                                    this.setState({
                                      isDelete: true,
                                      delete_id: lead.hash_id,
                                    });
                                  }}
                                >
                                  {lead_action.delete}
                                </div>
                              </>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>

                        <div className="lead_arrow">
                          <img
                            alt="HMC"
                            src={Lead_Arrow_Img}
                            onClick={() => {
                              this.props.changeCollapsed(index);
                            }}
                            className={
                              collapsedArray[index] ? "t_iconhide" : "t_icon"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      collapsedArray[index]
                        ? "collapse lead_equipment show"
                        : "collapse lead_equipment "
                    }
                  >
                    <div
                      className="lead_eq_inner"
                      style={{ width: "3%" }}
                    ></div>
                    <div className="lead_eq_inner">
                      <label>{language.created_on}</label>
                      <span>{create_date}</span>
                    </div>
                    <div className="lead_eq_inner">
                      <label>{language.closing_date}</label>
                      <span>{closing_date}</span>
                    </div>

                    <div className="lead_eq_inner">
                      <label>{language.last_mod_date}</label>
                      <span>{updated_at}</span>
                    </div>
                    <div className="lead_eq_inner">
                      <label>{language.catagory}</label>
                      <span className="refinance">
                        {lead.finance_type === "1"
                          ? language.re_finance
                          : language.normal_finance}
                      </span>
                    </div>
                    <div className="lead_eq_inner">
                      <label>{language.equip_name}</label>
                      <span data-tip data-for={`euipment_name${index}`}>
                        {lead.euipment_name}
                        <ReactTooltip
                          id={`euipment_name${index}`}
                          place="top"
                          effect="solid"
                        >
                          {lead.euipment_name}
                        </ReactTooltip>
                      </span>
                    </div>

                    <div className="lead_eq_inner">
                      <label>{language.tags}</label>

                      {tags &&
                        tags.map((tag, key) => {
                          tag = tag.split("-");
                          let title = tag[0];
                          let tag_title_color = tag[1];
                          let tag_back_color = tag[2];
                          return (
                            <div
                              className="tags"
                              key={key}
                              style={{
                                background: tag_back_color,
                                color: tag_title_color,
                              }}
                            >
                              {title}
                            </div>
                          );
                        })}
                    </div>
                    <div className="lead_eq_inner">
                      <label>{language.lui}</label>
                      <span>{lead.luid}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="lead_showing_result">
          <label>
            {language.showing_results}{" "}
            {Math.min(
              this.props.totalItems,
              this.props.currentPage * this.props.per_page
            )}{" "}
            {language.out_of} {this.props.totalItems}
          </label>
          <div className="result_pagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={(val) => {
                this.setState({ clickedPosition: -1 });
                this.props.onPageChange(val);
              }}
              pageRangeDisplayed={3}
              pageCount={this.props.totalPage}
              previousLabel="<"
              activeClassName="active"
              disabledClassName="disable"
              renderOnZeroPageCount={null}
              forcePage={parseInt(this.props.currentPage) - 1}
            />
          </div>
        </div>
      </>
    );
  }
}
export default connect(mapStateToProps)(PreeAssignedLead);
