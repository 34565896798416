import React, { Component } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import "react-phone-number-input/style.css";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import axios from "../../../config/axios";
import message from "../../../utils/messages";

const mapStateToProps = (state) => {
  let { language, country_code } = state;
  return { language, country_code };
};

class RemoveAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country_code: "",
      mobile: "",
      isLoading: false,
      phoneValue: "",
      error: "",
      isOtp: false,
      onComplete: false,
      timer: "00",
      otp: "",
      otpError: false,
      country_code_list: [],
      countryName: "",

      footer_faq_data: [],
      seo_data: {},
    };
  }

  handlePhoneChange = (value) => {
    let { error } = this.state;
    if (value !== undefined) {
      this.setState({ phoneValue: value });

      let number = parsePhoneNumber(value);
      if (number) {
        let country_code = "+" + number.countryCallingCode;
        let mobile = number.nationalNumber;
        this.setState({ country_code, mobile });
      }
      if (isValidPhoneNumber(value)) {
        error = "";
      } else {
        error = "Enter Valid Mobile Number";
      }
      this.setState({ error });
    } else {
      error = "Enter Valid Mobile Number";
      this.setState({ error, mobile: "" });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.error) {
      return;
    }
    this.getOtp();
  };

  handleOtpChange = (otp) => {
    this.setState({ otp });
  };

  getOtp = () => {
    this.setState({ isLoading: true });
    let country_code = this.state.country_code.substring(1);
    axios
      .post("/remove-account-send-otp", {
        country_code,
        mobile: this.state.phoneValue,
      })
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          message.success(res.data.message);
          this.setState({ onComplete: false, isOtp: true });
          this.startTimer(30);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  startTimer = (seconds) => {
    var myTimer = setInterval(() => {
      if (seconds === 0) {
        clearInterval(myTimer);
        this.setState({ onComplete: true });
      }
      this.setState({ timer: seconds > 9 ? seconds : "0" + seconds });
      seconds--;
    }, 1000);
  };

  verifyOtp = (otp) => {
    let formData = {
      mobile: this.state.phoneValue,
      otp,
      platform: "web",
    };

    this.setState({ isLoading: true });

    axios
      .post("/otp-check-remove-account", formData)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          message.success(res.data.message);

          this.setState({
            country_code: "",
            mobile: "",
            isLoading: false,
            phoneValue: "",
            error: "",
            isOtp: true,
            onComplete: false,
            timer: "00",
            otp: "",
            otpError: false,
            country_code_list: [],
            countryName: "",
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  resetField = () => {
    this.setState({
      isLoading: false,
      error: "",
      isOtp: false,
      onComplete: false,
      timer: "00",
      otp: "",
      otpError: false,
    });
  };

  render() {
    let { country_code_list, isOtp } = this.state;

    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.mobile_login;
    } else {
      language = {};
    }

    let country_loading = this.props.country_code.loading;
    let country_code = this.props.country_code.country_code_list;

    if (!country_loading && country_code) {
      country_code_list = country_code.country_code_list.map((val) => {
        return val.code;
      });
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <section className="inner_prdtsss">
          <Container>
            <Row className="justify-content-center">
              <Col md={5}>
                <Card className="card_primary">
                  <Card.Body>
                    <div className="section_head text-center">
                      <h1 className="section_title_md">Remove Account</h1>
                    </div>
                    {!isOtp ? (
                      <Form onSubmit={this.handleSubmit}>
                        <h4 className="section_sub_title text-center">
                          Remove your account using phone number
                        </h4>
                        <Form.Group
                          className="login_box_partts mt-4"
                          controlId="formBasicEmail"
                        >
                          {country_code_list.length !== 0 && (
                            <PhoneInput
                              className="phone_field"
                              defaultCountry={
                                localStorage.getItem("country") || "IN"
                              }
                              placeholder="Enter Mobile Number"
                              countries={country_code_list}
                              value={this.state.phoneValue}
                              onChange={this.handlePhoneChange}
                              required
                              addInternationalOption={false}
                            />
                          )}
                          <p className="invalid_input">{this.state.error}</p>
                        </Form.Group>

                        <Button
                          type="submit"
                          className="login_submit_but commn_button mb-4"
                        >
                          Submit
                        </Button>
                      </Form>
                    ) : (
                      <div className="otp_verification text-center">
                        <h4 className="section_sub_title">
                          Mobile Phone Verification
                        </h4>
                        <div className="otp_field">
                          <p className="verify_no">
                            Enter the code we have just sent on your phone
                            <b>
                              {this.state.country_code +
                                " " +
                                this.state.mobile}
                            </b>{" "}
                            <span onClick={this.resetField}>
                              Change Number?
                            </span>
                          </p>
                          <OtpInput
                            onChange={this.handleOtpChange}
                            numInputs={4}
                            inputStyle="inputStyle"
                            isInputNum={true}
                            placeholder="****"
                            hasErrored={this.state.otpError}
                            errorStyle="error"
                            value={this.state.otp}
                          />
                          <div className="countdown">
                            Time left : {this.state.timer}
                          </div>
                          {!this.state.onComplete && (
                            <div className="resend">
                              Didn't receive the code?{" "}
                              <span onClick={this.getOtp}>resend</span>
                            </div>
                          )}
                          <div className="otp_submit mt-2">
                            <Button
                              className="btn btn-dark"
                              onClick={() => {
                                this.resetField();
                                this.setState({
                                  country_code: "",
                                  mobile: "",
                                  phoneValue: "",
                                });
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="ml-3 btn btn-warning"
                              onClick={() => {
                                if (this.state.otp.length !== 4) {
                                  this.setState({
                                    otpError: true,
                                  });
                                } else {
                                  this.setState({
                                    otpError: false,
                                  });
                                  this.verifyOtp(this.state.otp);
                                }
                              }}
                            >
                              Verify
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </LoadingOverlay>
    );
  }
}

export default connect(mapStateToProps)(RemoveAccount);
