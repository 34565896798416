import React, { Component } from "react";
import { Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import LeftPanelPage from "../../components/leftpanel/LeftPanelPage";
import { prof_icon_menu, star_img } from "../../assets/images";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import axios from "../../config/axios";
import message from "../../utils/messages";
import config from "../../config/apiHeader";
import { MEDIA_BASE_URL } from "../../utils/Helper";

export default class PartsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hash_id: "",
      profile_hash_id: "",
      isLoading: false,

      basic_info: {},
      docs: [],
      images: [],
      specification: [],
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }

    let { params } = this.props.match;
    if (params) {
      let { profile_hash_id } = params;

      this.setState({ profile_hash_id }, () => {
        this.getPartsProfileDetails();
      });
    }
  };

  getPartsProfileDetails = () => {
    this.setState({ isLoading: true });

    let { profile_hash_id, hash_id } = this.state;

    let formData = {
      profile_hash_id,
      login_hash_id: hash_id,

      medium: "web",
    };

    axios
      .post("/parts-profile-details", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });

        if (res.data.status === "1") {
          let { basic_info, docs, images, specification } = res.data.data;

          this.setState({ basic_info, docs, images, specification });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 200 },
        items: 1,
      },
    };
    let { basic_info, images, specification } = this.state;
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                <Col className="ds_right" lg="9" md="8" sm="12">
                  <div className="dashboard_right_panel">
                    <div className="ds_header">
                      <h2>Parts Delear Details</h2>

                      <div className="pd_hero">
                        <Card className="hmc_card mb-4">
                          <Card.Body>
                            <Row>
                              <Col md={4} className="pd_image">
                                <Carousel
                                  responsive={responsive}
                                  centerMode={false}
                                  className="eq_slider"
                                  arrows={false}
                                  showDots={true}
                                  autoPlay={false}
                                  items={1}
                                  draggable={true}
                                  swipeable={true}
                                >
                                  {images.map((image, key) => {
                                    return (
                                      <div className="cs_item" key={key}>
                                        <img
                                          src={MEDIA_BASE_URL + image.image}
                                          className="img-fluid w-100"
                                          alt="HMC"
                                        />
                                      </div>
                                    );
                                  })}
                                </Carousel>
                              </Col>
                              <Col md={8} className="pd_info">
                                <div className="pd_head">
                                  <div className="result_star">
                                    <img src={star_img} alt="HMC" />

                                    {basic_info.service_rating}
                                  </div>
                                  <div className="pd_company">
                                    {basic_info.parts_type === "2"
                                      ? "Used"
                                      : basic_info.parts_type === "1"
                                      ? "New"
                                      : "New & Used"}{" "}
                                    Parts
                                  </div>
                                  {/* <h2 className="pd_model">
                                    Mahindra Silent Genset Sales & Service
                                  </h2> */}
                                </div>

                                <Row className="pd_feature_list align-items-center">
                                  <Col xs={12}>
                                    <div className="pd_features">
                                      <div className="pdf__text">
                                        <dl>
                                          <dd>Location</dd>
                                          <dt>
                                            {basic_info.city &&
                                              basic_info.city +
                                                ", " +
                                                basic_info.state}
                                          </dt>
                                        </dl>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="auto">
                                    <div className="pd_features d-flex">
                                      <div className="pdf_icon">
                                        <img
                                          width={40}
                                          height={40}
                                          src={
                                            basic_info.img_url
                                              ? MEDIA_BASE_URL +
                                                basic_info.img_url
                                              : prof_icon_menu
                                          }
                                          className="rounded-circle"
                                          alt={basic_info.business_name}
                                        />
                                      </div>
                                      <div className="pdf__text">
                                        <dl>
                                          <dd>Owner</dd>
                                          <dt>{basic_info.business_name}</dt>
                                        </dl>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="auto">
                                    <Link
                                      to="/#"
                                      className="btn btn-primary btn_primary"
                                    >
                                      Chat
                                    </Link>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <div className="spc_tab mt-4">
                              <Tabs defaultActiveKey="basic_information">
                                <Tab
                                  eventKey="basic_information"
                                  title="Basic Information "
                                >
                                  <div className="tab_content px-0">
                                    <div className="dl_list">
                                      <dl className="row">
                                        <dd className="col-md-3">
                                          Nature of Business
                                        </dd>
                                        <dt className="col">Dealer</dt>
                                      </dl>
                                      <dl className="row">
                                        <dd className="col-md-3">
                                          Business owner
                                        </dd>
                                        <dt className="col">
                                          Rajnikant Padariya
                                        </dt>
                                      </dl>
                                      <dl className="row">
                                        <dd className="col-md-3">
                                          Registered Address
                                        </dd>
                                        <dt className="col">
                                          2, Parmeshwar Society, Parmeshwar Main
                                          Road, Nehru Nagar Near Prawaria
                                          Colony, Rajkot-360002, Gujarat, India
                                        </dt>
                                      </dl>
                                      <dl className="row">
                                        <dd className="col-md-3">
                                          Year of Establishment
                                        </dd>
                                        <dt className="col">2009</dt>
                                      </dl>
                                      <dl className="row">
                                        <dd className="col-md-3">
                                          Legal Status of Firm
                                        </dd>
                                        <dt className="col">Individual</dt>
                                      </dl>
                                    </div>
                                  </div>
                                </Tab>

                                <Tab
                                  eventKey="specification"
                                  title="Specification"
                                >
                                  <div className="tab_content px-0">
                                    <div className="dl_list">
                                      {specification.map((item, key) => {
                                        return (
                                          <dl className="row" key={key}>
                                            <dt className="col-md-3">
                                              {item.field_name}
                                            </dt>
                                            {item.field_value
                                              .split(",")
                                              .map((value, key) => {
                                                return (
                                                  <dd className="col" key={key}>
                                                    {value}
                                                  </dd>
                                                );
                                              })}
                                          </dl>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </Tab>
                              </Tabs>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
