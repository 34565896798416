import React, { Component } from "react";
import { Button, Col, Container, Row, Toast } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import LeftPanelPage from "../../components/leftpanel/LeftPanelPage";
import "./MyNotifications.css";
import config from "../../config/apiHeader";
import axios from "../../config/axios";
import message from "../../utils/messages";
import moment from "moment";
import DeletePopup from "../../utils/DeletePopup";
import { prof_icon_menu } from "../../assets/images";
import { Link } from "react-router-dom";
import { MEDIA_BASE_URL } from "../../utils/Helper";
import { connect } from "react-redux";

const DATE_FORMAT = "DD-MM-YYYY";
const TIME_FORMAT = "h:m:s A";
const DATE_TIME_FORMAT = "Do MMM, YYYY - h:m:s A";
const OLDER_FORMAT = "ddd, Do MMM YYYY - h:m:s A";
const DAY_FORMAT = "dddd";
let REFERENCE = moment();
let TODAY = REFERENCE.clone().startOf("day");
let YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
let A_WEEK_OLD = REFERENCE.clone().subtract(7, "days").startOf("day");

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class MyNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hash_id: "",

      page: 1,
      last_page: 0,

      all_notification: [],
      notifications: [],

      notification_hash_id: "",
      isNotificationDelete: false,
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }
    this.getAllNotification();
  };

  getAllNotification = () => {
    this.setState({ isLoading: true });
    let { hash_id, page } = this.state;

    const formData = {
      user_hash_id: hash_id,
      page,
      per_page: 10,
    };

    axios
      .post("/web-notification-list", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });

        if (res.data.status === "1") {
          let { all_notification, last_page } = res.data.data;

          all_notification =
            this.state.all_notification.concat(all_notification);

          all_notification.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });

          const map = new Map();

          let array = [];
          let isFirst = false;
          let oldKey = "";

          all_notification.forEach((value) => {
            let date = moment(value.created_at);
            let key = date.format(DATE_FORMAT);

            if (!this.isWithinAWeek(date)) {
              if (isFirst) {
                key = oldKey;
              } else {
                isFirst = true;
                oldKey = key;
              }
            }
            // PRESENT
            if (map.has(key)) {
              array.push(value);
              map.set(key, array);
            } else {
              array = [];
              array.push(value);
              map.set(key, array);
            }

            // console.log("DATE", value.created_at, key);
            // console.log("TODAY", this.isToday(date));
            // console.log("YEST", this.isYesterday(date));
            // console.log("DAY", date.format(DAY_FORMAT));
          });

          // map.forEach((val, key) => {
          //   console.log(key, " : ", val);
          // });
          // console.log(Array.from(map));
          this.setState({
            notifications: Array.from(map),
            all_notification,
            last_page,
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  isToday = (momentDate) => momentDate.isSame(TODAY, "d");
  isYesterday = (momentDate) => momentDate.isSame(YESTERDAY, "d");
  isWithinAWeek = (momentDate) => momentDate.isAfter(A_WEEK_OLD);
  getDay = (momentDate) => momentDate.format(DAY_FORMAT);

  deleteNotification = () => {
    let { hash_id, notification_hash_id } = this.state;

    const formData = {
      user_hash_id: hash_id,
      notification_hash_id,
    };

    this.setState({ isLoading: true, isNotificationDelete: false });

    axios
      .post("/web-remove-notification", formData, config)
      .then((res) => {
        if (res.data.status === "1") {
          message.success(res.data.message);
          this.setState(
            { all_notification: [], notifications: [], page: 1 },
            () => {
              this.getAllNotification();
            }
          );
        } else {
          this.setState({ isLoading: false });
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong");
      });
  };

  render() {
    let { notifications } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.my_notification;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <DeletePopup
          show={this.state.isNotificationDelete}
          message="⚠️ Are you sure you want to permanently delete your notification?"
          yes_msg={language.yes_btn}
          no_msg={language.no_btn}
          no={() => {
            this.setState({
              isNotificationDelete: false,
            });
          }}
          yes={() => {
            this.deleteNotification();
          }}
        />

        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                <Col lg="9" md="8" sm="12" className="ds_right">
                  <div className="dashboard_right_panel">
                    <div className="ds_header has_tool">
                      <h2>{language.my_notification_h}</h2>
                      {/* <div className="dsh_tool">
                        <div className="search_box">
                          <input
                            placeholder="Search for.."
                            name="name"
                            type="text"
                            className="form-control"
                          />
                          <span className="sb_icon">
                            <i className="fa fa-search" aria-hidden="true" />
                          </span>
                        </div>
                      </div> */}
                    </div>
                    <div className="notification_list">
                      {notifications.map((value, key) => {
                        let date = moment(value[0], DATE_FORMAT);
                        let title;
                        let today = this.isToday(date);
                        let yesterday = this.isYesterday(date);
                        let week = this.isWithinAWeek(date);

                        if (today) {
                          title = language.today;
                        } else if (yesterday) {
                          title = language.yesterday;
                        } else if (!week) {
                          title = language.older;
                        } else {
                          title = this.getDay(date);
                        }

                        return (
                          <div key={key}>
                            <div className="notification_date">{title}</div>
                            {value[1]?.map((data, index) => {
                              let timeStamp = "";
                              if (today) {
                                timeStamp = moment(data.created_at).fromNow();
                              } else if (yesterday) {
                                timeStamp = moment(data.created_at).format(
                                  TIME_FORMAT
                                );
                              } else if (!week) {
                                timeStamp = moment(data.created_at).format(
                                  OLDER_FORMAT
                                );
                              } else {
                                timeStamp = moment(data.created_at).format(
                                  DATE_TIME_FORMAT
                                );
                              }
                              let {
                                lead_id,
                                lead_assignee_activity_id,
                                dashboard_id,
                                feedback_history_data,
                              } = data?.parameter?.data;
                              feedback_history_data = feedback_history_data
                                ? JSON.parse(feedback_history_data)
                                : null;
                              return (
                                <Toast className="hmc_toast unread" key={index}>
                                  <Link
                                    to={`/feedback-history/${
                                      lead_id || data.notification_activity_id
                                    }/${
                                      lead_assignee_activity_id ||
                                      data.notification_activity_id
                                    }/pree_assigned_list/${dashboard_id}`}
                                  >
                                    <Toast.Body>
                                      <div className="notification_wrap d-flex">
                                        <div className="not_icon">
                                          <img
                                            width={40}
                                            height={40}
                                            src={
                                              data.profile_img_url
                                                ? MEDIA_BASE_URL +
                                                  data.profile_img_url
                                                : prof_icon_menu
                                            }
                                            className="rounded-circle"
                                          />
                                        </div>
                                        <div className="not_body col">
                                          <div className="not_heading">
                                            <h4>
                                              <strong>{data.title}</strong>{" "}
                                              {
                                                feedback_history_data?.sub_header_title
                                              }
                                            </h4>
                                          </div>
                                          <p>{data.description}</p>
                                          <small>{timeStamp}</small>
                                        </div>
                                      </div>
                                    </Toast.Body>
                                  </Link>
                                  <Button
                                    variant="default"
                                    size="sm"
                                    className="clear_noti"
                                    onClick={() => {
                                      this.setState({
                                        notification_hash_id:
                                          data.notification_hash_id,
                                        isNotificationDelete: true,
                                      });
                                    }}
                                  >
                                    <i className="fa fa-trash" />
                                  </Button>
                                </Toast>
                              );
                            })}
                          </div>
                        );
                      })}

                      <div className="notification_pagination">
                        <Button
                          className="btn_primary"
                          type="submit"
                          disabled={this.state.page >= this.state.last_page}
                          onClick={() => {
                            this.setState({ page: this.state.page + 1 }, () => {
                              this.getAllNotification();
                            });
                          }}
                        >
                          {language.view_more_btn}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(MyNotification);
