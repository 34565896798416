import React, { Component } from "react";
import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";

const { Handle } = Slider;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

export default class CustomSlider extends Component {
  render() {
    let { max, value, handleSlider } = this.props;
    return (
      <Slider
        min={0}
        max={max | 100}
        defaultValue={value | 0}
        handle={handle}
        onChange={handleSlider}
      />
    );
  }
}
