import React, { Component } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import "rc-slider/assets/index.css";
import Slider from "rc-slider";
import {
  Eqp_model_img,
  Upload_img_icon_img,
  Up_doc_icon_img,
  cross_preview,
  modal_attach,
  word_preview,
  pdf_preview,
  image_preview,
} from "../../assets/images/";
import "./addequipment.css";
import LeftPanelPage from "../../components/leftpanel/LeftPanelPage";
import axios from "../../config/axios";
import message from "../../utils/messages";
import LoadingOverlay from "react-loading-overlay";
import Advertisiment from "./Advertisiment";
import _ from "lodash";
import AutoCompleteLocation from "../../utils/AutoCompleteLocation";
import CustomPopup from "../../utils/CustomPopup";
import Select from "react-select";
import moment from "moment";
import config from "../../config/apiHeader";
import { getFileExtension, MEDIA_BASE_URL } from "../../utils/Helper";
import { Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import CustomMultiSelect from "../../utils/CustomMultiSelect";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class AddEquipmentTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documet_types: [],
      allAreas: [],
      allStates: [],
      isLoading: false,
      hash_id: "",
      data: "",
      machine_details: "",
      machine_profile_type: [],
      machine_image: [],
      save_for_later: "0",
      lat: "",
      long: "",
      location: "",
      purchase_date: "",
      hmr: "1000",
      selcted_states: "",
      selcted_areas: "",
      isRent: false,

      error: {},

      // ADD DOCUMNET
      show: false,
      doc_select: {},
      doc_type: "",
      total_doc_type: [],
      temp_total_doc_type: [],
      doc_name: "",
      total_doc_name: [],
      temp_total_doc_name: [],
      total_doc: [],
      temp_total_doc: [],

      add_equipment_show: false,

      availability: [],
      utilization: [],
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }
    if (this.props.location.state) {
      let { data, machine_details } = this.props.location.state;
      await this.setState({ data, machine_details });
    } else {
      this.props.history.push({
        pathname: "/add-equipment",
      });
    }
    this.getAllState();
    this.getAllAreas();
    this.getDocumetType();
  }

  getDocumetType = () => {
    axios
      .post("/document-type-section-wise", { document_master_id: "7" }, config)
      .then((res) => {
        if (res.data.status === "1") {
          let documet_types = [];
          res.data.data.doc_type.map((doc) => {
            return documet_types.push({
              value: doc.id,
              label: doc.name,
            });
          });
          this.setState({ documet_types });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  getAllState = () => {
    axios
      .get("/area", config)
      .then((res) => {
        if (res.data.status === "1") {
          let allStates = [];
          res.data.data.area.map((state) => {
            return allStates.push({
              value: state.id,
              label: state.name,
            });
          });
          this.setState({ allStates });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  getAllAreas = () => {
    axios
      .get("/utilization-list", config)
      .then((res) => {
        if (res.data.status === "1") {
          let allAreas = [];
          res.data.data.utilization_type.map((area) => {
            return allAreas.push({
              value: area.id,
              label: area.name,
            });
          });
          this.setState({ allAreas });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  handleChange = (e) => {
    e.persist();

    let { error } = this.state;
    let { name, value } = e.target;

    if (name === "machine_profile_type") {
      let allType = this.state.machine_profile_type;
      if (
        _.find(allType, (val) => {
          return val === value;
        })
      ) {
        allType = _.filter(allType, (val) => {
          return val !== value;
        });
      } else {
        allType = _.concat(allType, value);
      }
      if (allType.length === 0) {
        error[name] =
          "Please select either rent or resale or both before submitting your equipment details";
      } else {
        error[name] = "";
      }
      this.setState({ machine_profile_type: allType, error });
      return;
    }

    this.setState({
      [name]: value,
    });
  };

  handleImages = (e) => {
    e.persist();

    let { machine_image } = this.state;
    let file = e.target.files[0];
    let validExtension = ["png", "jpg", "jpeg"];
    if (file !== undefined) {
      let extension = getFileExtension(file);
      if (
        extension !== undefined &&
        _.findIndex(validExtension, (exe) => {
          return exe === extension;
        }) !== -1
      ) {
        machine_image.push(file);
      } else {
        message.error("The file format is not supported");
      }
    }
    this.setState({ machine_image });
  };

  deleteImage = (pos) => {
    let { machine_image } = this.state;
    machine_image.splice(pos, 1);
    this.setState({ machine_image });
  };

  handleGetLocation = (value) => {
    if (value) {
      let { error } = this.state;
      let { label, lat, long } = value;
      error.location = "";
      this.setState({
        location: label,
        error,
        long,
        lat,
      });
    }
  };

  onClose = (show) => {
    let { total_doc_type, total_doc_name, total_doc } = this.state;
    this.setState({
      show: false,
      doc_select: {},
      doc_type: "",
      temp_total_doc_type: total_doc_type,
      doc_name: "",
      temp_total_doc_name: total_doc_name,
      temp_total_doc: total_doc,
    });
  };

  handleDocument = (e) => {
    e.persist();

    let {
      temp_total_doc,
      temp_total_doc_type,
      temp_total_doc_name,
      doc_type,
      doc_name,
    } = this.state;
    let file = e.target.files[0];
    let validExtension = ["png", "jpg", "jpeg", "doc", "docx", "pdf"];
    if (file !== undefined) {
      let extension = getFileExtension(file);

      if (
        extension !== undefined &&
        _.findIndex(validExtension, (exe) => {
          return exe === extension;
        }) !== -1
      ) {
        let preview;
        if (extension === "docx" || extension === "doc") {
          preview = word_preview;
        } else if (extension === "pdf") {
          preview = pdf_preview;
        } else {
          preview = image_preview;
        }
        temp_total_doc.push(file);
        temp_total_doc_type.push(doc_type);
        doc_name += `.${extension}`;
        temp_total_doc_name.push({ doc_name, preview });

        this.setState({
          temp_total_doc,
          temp_total_doc_type,
          temp_total_doc_name,
          doc_select: {},
          doc_type: "",
          doc_name: "",
        });
      } else {
        message.error("Please Select Valid Document");
      }
    }
  };

  deleteTempDoc = (pos) => {
    let { temp_total_doc, temp_total_doc_name, temp_total_doc_type } =
      this.state;
    temp_total_doc.splice(pos, 1);
    temp_total_doc_name.splice(pos, 1);
    temp_total_doc_type.splice(pos, 1);
    this.setState({ temp_total_doc, temp_total_doc_name, temp_total_doc_type });
  };

  deleteDoc = (pos) => {
    let { total_doc, total_doc_name, total_doc_type } = this.state;
    total_doc.splice(pos, 1);
    total_doc_name.splice(pos, 1);
    total_doc_type.splice(pos, 1);

    this.setState({
      temp_total_doc: [...total_doc],
      temp_total_doc_name: [...total_doc_name],
      temp_total_doc_type: [...total_doc_type],
      total_doc,
      total_doc_name,
      total_doc_type,
    });
  };

  submitDocument = () => {
    let { temp_total_doc, temp_total_doc_name, temp_total_doc_type } =
      this.state;
    this.setState({
      total_doc: [...temp_total_doc],
      total_doc_name: [...temp_total_doc_name],
      total_doc_type: [...temp_total_doc_type],
      show: false,
    });
  };

  stateSelected = (value) => {
    let selcted_states = value.map((state) => {
      return state.value;
    });
    this.setState({ selcted_states, availability: value });
  };

  areaSelected = (value) => {
    let selcted_areas = value.map((area) => {
      return area.value;
    });
    this.setState({ selcted_areas, utilization: value });
  };

  requiredField = () => {
    const arr = ["machine_profile_type", "location"];
    let { error } = this.state;

    arr.forEach((key) => {
      if (!this.state[key].toString()) {
        error[key] = "This filed is required";
      } else {
        error[key] = "";
      }
      this.setState({ error });
    });
  };

  isValidForm = (errors) => {
    let isValid = true;
    for (const [, value] of Object.entries(errors)) {
      if (value.length > 0) {
        isValid = false;
      }
    }
    return isValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let {
      save_for_later,
      hash_id,
      machine_profile_type,
      location,
      lat,
      long,
      machine_details,
      purchase_date,
      hmr,
      selcted_states,
      selcted_areas,
      machine_image,
      total_doc,
      error,
    } = this.state;

    this.requiredField();
    if (this.isValidForm(error)) {
      machine_profile_type = machine_profile_type
        .sort()
        .toString()
        .replace(",", "");

      let formData = {
        save_for_later,
        profile_hash_id: hash_id,
        machine_profile_type,
        purchase_date,
        hmr,
        location,
        lat,
        long,
        availability: selcted_states.toString(),
        utilization: selcted_areas.toString(),
        medium: "web",
        section_name: "add_equip_2",
      };

      if (machine_details) {
        formData.brand_hash_id = machine_details.brand_hash_id;
        formData.industry_hash_id = machine_details.industry_hash_id;
        formData.machine_type_hash_id = machine_details.machine_type_hash_id;
        formData.machine_hash_id = machine_details.machine_hash_id;
      }

      this.setState({ isLoading: true });

      axios
        .post("/machine-add-v1", formData, config)
        .then((res) => {
          if (res.data.status === "1") {
            let id = res.data.data.machine_activity_hash_id;
            if (machine_image.length > 0) {
              this.uploadImages(id);
            } else if (total_doc.length > 0) {
              this.uploadDocs(id);
            } else {
              this.setState({ isLoading: false, add_equipment_show: true });
            }
          } else {
            this.setState({ isLoading: false });
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          message.error("Something went wrong!!");
          console.error(err);
        });
    }
  };

  uploadImages = (id) => {
    let { machine_image, total_doc } = this.state;

    let formData = new FormData();
    formData.append("machine_activity_hash_id", id);
    formData.append("medium", "web");
    formData.append("section_name", "add_equip_image_2");

    machine_image.forEach((file, index) => {
      formData.append(`machine_image[${index}]`, file);
    });

    axios
      .post("/machine-image-v1", formData, config)
      .then((res) => {
        if (total_doc.length > 0) {
          this.uploadDocs(id);
        } else {
          this.setState({ isLoading: false, add_equipment_show: true });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  uploadDocs = (id) => {
    let { total_doc_type, total_doc_name, total_doc } = this.state;

    let formData = new FormData();
    formData.append("machine_activity_hash_id", id);
    formData.append("medium", "web");
    formData.append("section_name", "add_equip_doc_2");

    total_doc.forEach((file, index) => {
      formData.append(`machine_doc[${index}]`, file);
    });

    total_doc_type.forEach((file, index) => {
      formData.append(`type[${index}]`, file);
    });

    total_doc_name.forEach((file, index) => {
      formData.append(`name[${index}]`, file);
    });

    axios
      .post("/machine-document-v1", formData, config)
      .then((res) => {
        this.setState({ isLoading: false, add_equipment_show: true });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
        console.error(err);
      });
  };

  render() {
    let {
      data,
      machine_details,
      machine_image,
      error,
      temp_total_doc,
      temp_total_doc_name,
      total_doc,
      total_doc_name,
      availability,
      utilization,
    } = this.state;

    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.add_equipment_two;
    } else {
      language = {};
    }

    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <CustomPopup
            title={language.equipment_added_successfully}
            show={this.state.add_equipment_show}
            onClose={() => {
              this.setState({ add_equipment_show: false });
              this.props.history.replace("", null);
              this.props.history.push({ pathname: "/list-equipments" });
            }}
            isCenter={true}
          >
            {machine_details && (
              <img
                src={MEDIA_BASE_URL + machine_details.cover_image}
                alt="HMC"
                height={200}
                width={300}
              />
            )}
            <h3>{data.machineModelName || machine_details.model_name}</h3>

            <p>
              {language.post_date} : {moment(new Date()).format("LL")}
            </p>

            <div className="delete_style">
              <Button
                type="button"
                className="add_another"
                onClick={() => {
                  this.props.history.replace("", null);
                  this.props.history.push({ pathname: "/add-equipment" });
                }}
              >
                {language.add_another_btn}
              </Button>
              <Button
                type="button"
                className="back_to_home"
                onClick={() => {
                  this.props.history.replace("", null);
                  this.props.history.push({ pathname: "/my-equipments" });
                }}
              >
                {language.go_to_listing_age_btn}
              </Button>
            </div>
          </CustomPopup>

          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />
                <Col className="ds_right" lg="9" md="8" sm="12">
                  <div className="dashboard_right_panel">
                    <Row>
                      <Col lg="8" md="8" sm="12">
                        <div className="ds_header">
                          <h2>{language.add_equipment_h}</h2>
                          <h4>{language.add_equipment_short_desc}</h4>
                          <p>{language.add_equipment_long_desc}</p>
                        </div>
                        <div className="eqp_ind">
                          {machine_details && (
                            <div className="eqp_modelpart">
                              <div className="eqpmodel_left">
                                <img
                                  src={
                                    machine_details.cover_image
                                      ? MEDIA_BASE_URL +
                                        machine_details.cover_image
                                      : Eqp_model_img
                                  }
                                  alt="HMC"
                                />
                              </div>
                              <div className="eqpmodel_right">
                                <p>{language.equipment_model_name_label}</p>
                                <h3>
                                  {data.machineModelName ||
                                    machine_details.model_name}
                                </h3>
                              </div>
                            </div>
                          )}
                          <Form onSubmit={this.handleSubmit}>
                            <div className="ind_select">
                              <h3>{language.select_industry_label}</h3>
                              <p>{language.rent_resale_option_desc}</p>
                              <Form.Group
                                controlId="machine_profile_type"
                                className="check_container"
                              >
                                <div className="hmc_check check-inline">
                                  <label>
                                    <input
                                      type="checkbox"
                                      onChange={this.handleChange}
                                      value="2"
                                      name="machine_profile_type"
                                    />
                                    <span>{language.resale_option}</span>
                                  </label>
                                </div>
                                <div className="hmc_check check-inline">
                                  <label>
                                    <input
                                      type="checkbox"
                                      onChange={this.handleChange}
                                      value="4"
                                      className="sub_category_check"
                                      name="machine_profile_type"
                                      onClick={() => {
                                        this.setState({
                                          isRent: !this.state.isRent,
                                        });
                                      }}
                                    />
                                    <span>{language.rent_option}</span>
                                  </label>
                                </div>
                              </Form.Group>
                              <p className="invalid_input">
                                {error.machine_profile_type &&
                                  language.rent_resale_option_errormsg}
                              </p>

                              <div className="add_eqp_partts_img">
                                <h3>{language.add_equipment_images_label}</h3>
                                <p className="my-2">
                                  {language.add_equipment_images_desc}
                                </p>
                                <div className="add_images_partttts">
                                  {machine_image &&
                                    machine_image.map((preview, index) => {
                                      return (
                                        <div
                                          className="after_upload_image"
                                          key={index}
                                        >
                                          <img
                                            src={URL.createObjectURL(preview)}
                                            alt="HMC"
                                          />
                                          <span
                                            className="close_preview"
                                            onClick={() => {
                                              this.deleteImage(index);
                                            }}
                                          >
                                            <img
                                              src={cross_preview}
                                              alt="HMC"
                                            />
                                          </span>
                                        </div>
                                      );
                                    })}
                                  {machine_image.length < 5 && (
                                    <div className="uploadwrapper">
                                      <button className="uploadwrapperbtn">
                                        <img
                                          src={Upload_img_icon_img}
                                          alt="HMC"
                                        />
                                      </button>
                                      <Form.Control
                                        type="file"
                                        className="file_upload_boxxx"
                                        accept="image/png, image/jpg, image/jpeg"
                                        value=""
                                        name="images"
                                        onChange={this.handleImages}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="add_eqp_partts_img">
                                <h3>{language.upload_document_label}</h3>
                                <p className="my-2">
                                  {language.upload_document_desc}
                                </p>
                                <div className="add_images_partttts">
                                  {total_doc &&
                                    total_doc.map((doc, index) => {
                                      let { preview, doc_name } =
                                        total_doc_name[index];
                                      return (
                                        <div
                                          className="after_upload_image upload_preview"
                                          key={index}
                                        >
                                          <img src={preview} alt="HMC" />
                                          <span
                                            className="close_preview"
                                            onClick={() => {
                                              this.deleteDoc(index);
                                            }}
                                          >
                                            <img
                                              src={cross_preview}
                                              alt="HMC"
                                            />
                                          </span>
                                          <span className="preview_name">
                                            {doc_name}
                                          </span>
                                        </div>
                                      );
                                    })}

                                  <div className="uploadwrapper">
                                    <button
                                      className="uploadwrapperbtn"
                                      type="button"
                                      onClick={() => {
                                        this.setState({ show: true });
                                      }}
                                    >
                                      <img src={Up_doc_icon_img} alt="HMC" />
                                    </button>
                                    <CustomPopup
                                      title={language.upload_document_label}
                                      show={this.state.show}
                                      onClose={this.onClose}
                                      isCenter={true}
                                    >
                                      <Form>
                                        <div className="main_doc">
                                          <Form.Group className="doc_field">
                                            <Form.Label>
                                              {language.document_type_label}
                                            </Form.Label>
                                            <Select
                                              options={this.state.documet_types}
                                              onChange={(e) => {
                                                this.setState({
                                                  doc_select: e,
                                                  doc_type: e.value,
                                                });
                                              }}
                                              value={this.state.doc_select}
                                              placeholder="Select Document Type"
                                            />
                                          </Form.Group>
                                          <div className="doc_attach">
                                            <img src={modal_attach} alt="HMC" />
                                            <Form.Control
                                              disabled={
                                                this.state.doc_type === "" ||
                                                this.state.doc_name === ""
                                              }
                                              type="file"
                                              className="custom-file-input"
                                              accept="image/png, image/jpg, image/jpeg, .doc, .docx, .pdf"
                                              onChange={this.handleDocument}
                                              required
                                            />
                                          </div>
                                        </div>
                                        <Form.Group>
                                          <Form.Label>
                                            {language.document_name_label}
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            value={this.state.doc_name}
                                            name="doc_name"
                                            placeholder={
                                              language.enter_document_name_plh
                                            }
                                            onChange={this.handleChange}
                                            required
                                          />
                                        </Form.Group>
                                        <div className="add_images_partttts">
                                          {temp_total_doc &&
                                            temp_total_doc.map((doc, index) => {
                                              let { preview, doc_name } =
                                                temp_total_doc_name[index];
                                              return (
                                                <div
                                                  className="after_upload_image upload_preview"
                                                  key={index}
                                                >
                                                  <img
                                                    src={preview}
                                                    alt="HMC"
                                                  />
                                                  <span
                                                    className="close_preview"
                                                    onClick={() => {
                                                      this.deleteTempDoc(index);
                                                    }}
                                                  >
                                                    <img
                                                      src={cross_preview}
                                                      alt="HMC"
                                                    />
                                                  </span>
                                                  <span className="preview_name">
                                                    {doc_name}
                                                  </span>
                                                </div>
                                              );
                                            })}
                                        </div>
                                        <Button
                                          type="button"
                                          className="submit_button commn_button"
                                          onClick={this.submitDocument}
                                          disabled={temp_total_doc.length === 0}
                                        >
                                          {language.submit_btn}
                                        </Button>
                                      </Form>
                                    </CustomPopup>
                                  </div>
                                </div>
                              </div>

                              <Form.Group>
                                <label>
                                  {
                                    language.current_location_of_the_equipment_label
                                  }
                                </label>
                                <div className="ser_loc">
                                  <AutoCompleteLocation
                                    handleGetLocation={this.handleGetLocation}
                                    value={this.state.location}
                                  />
                                  <p className="invalid_input">
                                    {error.location &&
                                      language.current_location_errormsg}
                                  </p>
                                </div>
                              </Form.Group>

                              <Form.Group className="set_details">
                                <h3>{language.add_equipment_short_desc}</h3>
                                <div className="purchase_year">
                                  {this.state.isRent && (
                                    <>
                                      <p className="rent_desc">
                                        {
                                          language.a_few_more_information_related
                                        }
                                      </p>
                                      <Form.Group className="resale_price">
                                        <Form.Label>
                                          {
                                            language.choose_your_preferred_states_label
                                          }
                                        </Form.Label>
                                        <CustomMultiSelect
                                          options={this.state.allStates}
                                          value={availability}
                                          handleChange={this.stateSelected}
                                        />
                                      </Form.Group>

                                      <Form.Group className="resale_price">
                                        <Form.Label>
                                          {
                                            language.select_your_prefered_working_areas_label
                                          }
                                        </Form.Label>

                                        <CustomMultiSelect
                                          options={this.state.allAreas}
                                          value={utilization}
                                          handleChange={this.areaSelected}
                                        />
                                      </Form.Group>
                                    </>
                                  )}
                                  <Row>
                                    {/* <Col lg="6" md="6" sm="12">
                                      <Form.Group className="resale_price">
                                        <Form.Label>
                                          Approx Resale Price ( in Rupees)
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Enter amount"
                                          className="ser_tetx_field"
                                        />
                                      </Form.Group>
                                    </Col> */}
                                    <Col lg="6" md="6" sm="12">
                                      <div className="datetime_p">
                                        <label>
                                          {language.purchase_year_label}
                                        </label>
                                        <div className="innerdatetime">
                                          <ReactDatePicker
                                            placeholderText={
                                              language.select_purchase_year_plh
                                            }
                                            showYearPicker
                                            selected={
                                              this.state.purchase_date !== "" &&
                                              new Date(this.state.purchase_date)
                                            }
                                            dateFormat="yyyy"
                                            maxDate={new Date()}
                                            minDate={new Date("1990")}
                                            onChange={(date) => {
                                              this.setState({
                                                purchase_date:
                                                  moment(date).format("YYYY"),
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col lg="6" md="6" sm="12">
                                      <div className="range_rent_slider hmr_slider">
                                        <label>{language.current_hmr}</label>
                                        <div className="hmr_value">
                                          {this.state.hmr}
                                        </div>
                                        <Slider
                                          onChange={(value) => {
                                            this.setState({ hmr: value });
                                          }}
                                          defaultValue={1000}
                                          min={0}
                                          max={8000}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <div className="byclicking">
                                    <small className="invalid_input">
                                      {language.terms_condition_label}{" "}
                                      <Link to="/privacy-policy">
                                        {language.privacy_policy}
                                      </Link>{" "}
                                      {language.and}{" "}
                                      <Link to="/terms-conditions">
                                        {language.terms_conditions}
                                      </Link>
                                      .
                                    </small>
                                  </div>
                                </div>
                              </Form.Group>
                            </div>

                            <div className="save_for_later_submit">
                              <Button
                                type="submit"
                                className="btn_warning mr-2 btn-sm"
                                onClick={() => {
                                  this.setState({ save_for_later: "1" });
                                }}
                              >
                                {language.save_for_later_btn}
                              </Button>
                              <Button
                                type="submit"
                                className="btn_primary btn-sm"
                                onClick={() => {
                                  this.setState({ save_for_later: "0" });
                                }}
                              >
                                {language.submit_btn}
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </Col>
                      <Advertisiment language={language} />
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(AddEquipmentTwo);
