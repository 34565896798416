import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Seo from "../../components/seo/Seo";
import axios from "../../config/axios";
import message from "../../utils/messages";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MEDIA_BASE_URL } from "../../utils/Helper";
import "react-lazy-load-image-component/src/effects/blur.css";
import { prof_icon_menu } from "../../assets/images";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};

class TotalPartDealers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,

      footer_faq_data: [],
      seo_data: {},

      profiles: [],
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.getAllPartsDealers();
    this.getMetaData();
  };

  getAllPartsDealers = () => {
    this.setState({ isLoading: true });

    axios
      .post("/parts-list-web")
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { profiles } = res.data.data;
          this.setState({ profiles });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  getMetaData = () => {
    axios
      .get(
        `/web-seo-content?page_key=parts_dealers&lang_type=${localStorage.getItem(
          "language"
        )}`
      )
      .then((res) => {
        if (res.data.status === "1") {
          let { seo_data, footer_faq_data } = res.data.data;
          this.setState({ seo_data, footer_faq_data });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    let { seo_data, profiles } = this.state;
    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.equipments;
    } else {
      language = {};
    }
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          {seo_data && (
            <Helmet>
              <title>{seo_data.meta_title}</title>
              <meta name="description" content={seo_data.meta_description} />
              <meta name="keywords" content={seo_data.meta_key} />
              <link rel="canonical" href={seo_data.canonical_url} />
            </Helmet>
          )}
          <section className="inner_prdtsss">
            <Container>
              <div className="find_machine rent">
                <div className="find_machine_heading">
                  <h2>Parts Dealers</h2>
                </div>

                <div className="find_machine_inner">
                  <div className="select_brandds">
                    <div className="avalaible_matching">
                      <Row>
                        {profiles.map((profile, key) => {
                          return (
                            <Col
                              lg="3"
                              md="4"
                              sm="6"
                              className="rented_col"
                              key={key}
                            >
                              <div className="rented_machine_inner rentedpartts">
                                <div className="rented_machine_inner_inner">
                                  <div className="rented_machine_inner_top">
                                    <LazyLoadImage
                                      alt={profile.business_name}
                                      src={
                                        profile.img_url
                                          ? MEDIA_BASE_URL + profile.img_url
                                          : prof_icon_menu
                                      }
                                      effect="blur"
                                    />
                                  </div>
                                  <div className="rented_machine_inner_bottom">
                                    <h2>{profile.business_name}</h2>

                                    <div className="rented_lcation">
                                      <span>
                                        {profile.city && profile.city + ", "}{" "}
                                        {profile.state}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>

                      <div className="showing_result text-center d-block">
                        <Link
                          to="/parts"
                          className="submit_button commn_button"
                        >
                          {language.view_more_btn}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section>
          <Seo {...this.state} />
        </div>
      </LoadingOverlay>
    );
  }
}

export default connect(mapStateToProps)(TotalPartDealers);
