import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ThnkRight_img, LeftTick_img } from "../../assets/images";
import "./ThankYou.css";

export default class ThankYouPage extends Component {
  render() {
    return (
      <div className="home_new">
        <ThankYouPart />
      </div>
    );
  }
}

class ThankYouPart extends Component {
  render() {
    return (
      <section className="thank_you">
        <Container>
          <div className="thank_you_partts">
            <div className="thank_right">
              <img alt="HMC" src={ThnkRight_img} />
              <h3>Thank You!</h3>
              <p>Your submission is received and we will contact you soon.</p>
              <div className="back_to_home">
                <Link to="/">
                  <img alt="HMC" src={LeftTick_img} />
                  Back to home
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
    );
  }
}
