import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import {
  equipment_search,
  list_view,
  grid_view,
  star_img,
  prof_icon_menu,
} from "../../assets/images";
import axios from "../../config/axios";
import message from "../../utils/messages";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Range } from "rc-slider";
import { StickyContainer, Sticky } from "react-sticky";
import "rc-slider/assets/index.css";
import LeftPanelPage from "../../components/leftpanel/LeftPanelPage";
import config from "../../config/apiHeader";
import "./AllEquipments.css";
import SelectWIthIcon from "../../utils/SelectWIthIcon";
import Select from "react-select";
import { MEDIA_BASE_URL } from "../../utils/Helper";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import _ from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let { language } = state;
  return { language };
};
class AllEquipments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hash_id: "",
      isLoading: false,
      isGridView: false,

      total: 0,
      currentPage: 1,
      per_page: 10,
      last_page: 0,

      machine_activity: [],
      allBrands: [],
      all_machine_types: [],
      allStates: [],

      // FILTER
      days: "",
      machine_profile_type: "",
      machine_type: [],
      brand: [],
      availibility: [],
      purchase_year_start: 1990,
      purchase_year_end: new Date().getFullYear(),
      hmr_start: 0,
      hmr_end: 8000,
      name: "",

      filter: {
        isApplyFilter: false,
        days: "",
        machine_profile_type: "",
        machine_type: [],
        brand: [],
        selected_brand: null,
        availibility: [],
        selected_availibility: null,
        purchase_year_start: 1990,
        purchase_year_end: new Date().getFullYear(),
        hmr_start: 0,
        hmr_end: 8000,
        name: "",
      },
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      await this.setState({
        hash_id: userdata.hash_id,
      });
    }

    this.getAllEquipments();
    this.getAllBrands();
    this.getAllMachineTypes();
    this.getAllState();
  };

  getAllEquipments = () => {
    let {
      hash_id,
      currentPage,
      machine_profile_type,
      days,
      brand,
      purchase_year_start,
      purchase_year_end,
      hmr_end,
      hmr_start,
      name,
      machine_type,
      availibility,
    } = this.state;

    const formData = {
      hash_id,
      page: currentPage,

      // FILTER
      machine_profile_type,
      days,
      brand: brand.toString(),
      machine_type: machine_type.toString(),
      availibility: availibility.toString(),
      purchase_year_start,
      purchase_year_end,
      hmr_start,
      hmr_end,
      name,

      medium: "web",
      section_name: "service_provider_get_all_equipmets",
    };

    this.setState({ isLoading: true });

    axios
      .post("/latest-equipment-list", formData, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === "1") {
          let { machine_activity, total, last_page } = res.data.data;
          this.setState({
            machine_activity:
              this.state.machine_activity.concat(machine_activity),
            total,
            last_page,
            currentPage: currentPage + 1,
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong!!");
      });
  };

  getAllBrands = () => {
    axios
      .get("/brand-list", config)
      .then((res) => {
        if (res.data.status === "1") {
          let allBrands = res.data.data.brand.map((brand) => {
            return {
              value: brand.id,
              label: brand.brand_name,
              icon: brand.brand_img_url,
            };
          });
          // remove I don't know and Other fields
          allBrands.pop();
          allBrands.pop();
          this.setState({
            allBrands,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAllMachineTypes = () => {
    axios
      .get("/all-machine-type-list", config)
      .then((res) => {
        if (res.data.status === "1") {
          let all_machine_types = res.data.data.machine_types.map((machine) => {
            return {
              value: machine.id,
              label: machine.machine_type_name,
              image: machine.image,
            };
          });

          this.setState({
            all_machine_types,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAllState = () => {
    axios
      .get("/area", config)
      .then((res) => {
        if (res.data.status === "1") {
          let allStates = res.data.data.area.map((state) => {
            return {
              value: state.id,
              label: state.name,
            };
          });
          this.setState({ allStates });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleOnChange = (e) => {
    let { name, value } = e.target;
    let { filter } = this.state;

    if (name === "machine_type") {
      let { machine_type } = filter;
      let machine_count = 0;
      if (
        _.find(machine_type, (val) => {
          return val === value;
        })
      ) {
        machine_type = _.filter(machine_type, (val) => {
          return val !== value;
        });
        machine_count--;
      } else {
        machine_type = _.concat(machine_type, value);
        machine_count++;
      }

      value = [...machine_type];
    }

    filter[name] = value;
    filter.isApplyFilter = false;

    this.setState({ filter });
  };

  handleSelect = (name, value) => {
    let { filter } = this.state;
    let brand_name = value.map((item) => {
      return item.value;
    });

    filter[name] = brand_name;
    filter.isApplyFilter = false;
    this.setState({ filter });
  };

  setViewType = () => {
    this.setState({ isGridView: !this.state.isGridView });
  };

  handleRating = (rating) => {
    this.setState({ rating });
  };

  clearFilter = () => {
    this.setState(
      {
        days: "",
        machine_profile_type: "",
        machine_type: [],
        brand: [],
        availibility: [],
        purchase_year_start: 1990,
        purchase_year_end: new Date().getFullYear(),
        hmr_start: 0,
        hmr_end: 8000,
        name: "",

        filter: {
          isApplyFilter: false,
          days: "",
          machine_profile_type: "",
          machine_type: [],
          brand: [],
          selected_brand: null,
          availibility: [],
          selected_availibility: null,
          purchase_year_start: 1990,
          purchase_year_end: new Date().getFullYear(),
          hmr_start: 0,
          hmr_end: 8000,
          name: "",
        },
        currentPage: 1,
        machine_activity: [],
      },
      () => {
        this.getAllEquipments();
      }
    );
  };

  applyFilter = () => {
    let { filter } = this.state;

    if (filter.isApplyFilter) {
      // CLEAR FILTER
      this.clearFilter();
      return;
    }
    // APPLY FILTER
    filter.isApplyFilter = true;
    let {
      days,
      machine_profile_type,
      machine_type,
      brand,
      availibility,
      purchase_year_start,
      purchase_year_end,
      hmr_start,
      hmr_end,
      name,
    } = filter;
    this.setState(
      {
        days,
        machine_profile_type,
        machine_type,
        brand,
        availibility,
        purchase_year_start,
        purchase_year_end,
        hmr_start,
        hmr_end,
        name,
        currentPage: 1,
        machine_activity: [],
      },
      () => {
        this.getAllEquipments();
      }
    );
  };

  render() {
    let {
      machine_activity,
      machine_profile_type,
      filter,
      allBrands,
      all_machine_types,
      allStates,
    } = this.state;

    let { loading, language } = this.props.language;

    if (!loading && language) {
      language = language.equipments;
    } else {
      language = {};
    }

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 7,
        slidesToSlide: 7, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 5,
        slidesToSlide: 5, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
      },
      
    };
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Loading ...">
        <div className="home_new">
          <section className="dashboard_part">
            <Container fluid>
              <Row>
                <LeftPanelPage />

                <Col className="ds_right" lg="9" md="8" sm="12">
                  <div className="buy_rent_part">
                    <StickyContainer>
                      <Row>
                        {/* RESULT */}
                        <Col lg="9" md="8" sm="12">
                          <div className="dashboard_right_panel">
                            <h2>{language.equipment_list}</h2>
                            <div className="rent_result_top">
                              <h2>
                                {language.showing_result_for}{" "}
                                {machine_profile_type === "4"
                                  ? language.label_rent
                                  : machine_profile_type === "2"
                                  ? language.label_resale
                                  : language.all_label}
                              </h2>

                              <div className="buy_search_box">
                                <div className="search_box_rent">
                                  <Form.Control
                                    type="text"
                                    className="search_textbox"
                                    placeholder={
                                      language.filter_label_search_for
                                    }
                                    onChange={this.handleOnChange}
                                    name="name"
                                    value={filter.name}
                                  />
                                  <span
                                    className="eqp_search_icon"
                                    onClick={this.getAllEquipments}
                                  >
                                    <img alt="HMC" src={equipment_search} />
                                  </span>
                                </div>
                                <div className="buy_rent_viewbox">
                                  <span
                                    onClick={this.setViewType}
                                    className={
                                      this.state.isGridView
                                        ? "active_color"
                                        : " "
                                    }
                                  >
                                    <img alt="HMC" src={list_view} />
                                  </span>
                                  <span
                                    onClick={this.setViewType}
                                    className={
                                      this.state.isGridView
                                        ? " "
                                        : "active_color"
                                    }
                                  >
                                    <img alt="HMC" src={grid_view} />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <Form.Label>
                              {language.type_of_equipment}
                            </Form.Label>
                            <Carousel
                              swipeable={false}
                              draggable={true}
                              showDots={false}
                              responsive={responsive}
                              ssr={true}
                              infinite={false}
                              autoPlay={false}
                              containerClass="sb_car"
                              arrows={true}
                              slidesToSlide={6}
                            >
                              {all_machine_types.map((machine, key) => {
                                return (
                                  <div className="machine_listing" key={key}>
                                    <label className="machine_inner_partts sbc_card text-center">
                                      <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        name="machine_type"
                                        value={machine.value}
                                        id={key}
                                        onChange={this.handleOnChange}
                                      />
                                      <img
                                        src={MEDIA_BASE_URL + machine.image}
                                        alt={machine.label}
                                        width={50}
                                        height={50}
                                        className="rounded-circle"
                                      />
                                      <div
                                        className="sbc_card_label"
                                        htmlFor={key}
                                      >
                                        {machine.label}
                                      </div>
                                      <div className="checkmark" />
                                    </label>
                                  </div>
                                );
                              })}
                            </Carousel>

                            <div
                              className={`rent_result_bottom ${
                                this.state.isGridView
                                  ? "grid_view"
                                  : "list_view"
                              }`}
                            >
                              {machine_activity.map((machine, key) => {
                                return (
                                  <div className="result_inner" key={key}>
                                    <div className="result_inner_left">
                                      <div className="machine__type">
                                        {machine.machine_profile_type === "4"
                                          ? language.label_rent
                                          : language.label_resale}
                                      </div>
                                      <Link
                                        to={`/equipment-details/${machine.machine_activity_hash_id}/${machine.machine_hash_id}/${machine.profile_hash_id}`}
                                      >
                                        <LazyLoadImage
                                          alt="HMC"
                                          src={
                                            MEDIA_BASE_URL +
                                            machine.machine_cover_image
                                          }
                                          className="img-fluid"
                                          effect="blur"
                                        />
                                      </Link>
                                    </div>
                                    <div className="result_inner_middle">
                                      <h3>
                                        <Link
                                          to={`/equipment-details/${machine.machine_activity_hash_id}/${machine.machine_hash_id}/${machine.profile_hash_id}`}
                                        >
                                          {machine.model_name}
                                        </Link>
                                      </h3>
                                      <div className="avalaibility">
                                        <h4>{language.list_availability}</h4>
                                        <p>
                                          {machine.availability ||
                                            machine.state}
                                        </p>
                                      </div>
                                      <div className="result_posted">
                                        <div className="result_postd_iner">
                                          <h4>{language.list_posted_on}</h4>
                                          <p>{machine.publish_date}</p>
                                        </div>
                                        <div className="result_postd_iner">
                                          <h4>
                                            {language.list_purchased_year}
                                          </h4>
                                          <p>{machine.purchase_date}</p>
                                        </div>
                                        <div className="result_postd_iner">
                                          <h4>{language.list_hmr}</h4>
                                          <p>{machine.hmr}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="result_inner_right">
                                      <div className="result_top_inner">
                                        <div className="result_user_image">
                                          <img
                                            alt="HMC"
                                            src={
                                              machine.img_url
                                                ? MEDIA_BASE_URL +
                                                  machine.img_url
                                                : prof_icon_menu
                                            }
                                          />
                                        </div>
                                        <div className="result_star">
                                          <img alt="HMC" src={star_img} />
                                          {machine.service_rating}
                                        </div>
                                      </div>
                                      <h5>{machine.business_name}</h5>
                                      <div className="result_inner_location">
                                        <i className="fa fa-map-marker" />
                                        <span>
                                          {machine.city || machine.state}
                                        </span>
                                      </div>
                                      {/* <div className="connect_now_butinner">
                                        <a href="#">Connect now</a>
                                      </div> */}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                            <div className="showing_result">
                              <p>
                                {language.pagination_showing_result}{" "}
                                {Math.min(
                                  this.state.total,
                                  (this.state.currentPage - 1) *
                                    this.state.per_page
                                )}{" "}
                                {language.pagination_out_of} {this.state.total}
                              </p>
                              <Button
                                className="submit_button commn_button"
                                type="submit"
                                disabled={
                                  this.state.currentPage - 1 >=
                                  this.state.last_page
                                }
                                onClick={this.getAllEquipments}
                              >
                                {language.view_more_btn}
                              </Button>
                            </div>
                          </div>
                        </Col>

                        {/* FILTER */}
                        <Col lg="3" md="4" sm="12">
                          <Sticky>
                            {({ style }) => (
                              <div style={style} className="filter_partts">
                                <div className="filter_heading">
                                  <span>{language.filter}</span>
                                </div>
                                <div className="eqp_added">
                                  <h3>
                                    {language.filter_label_equipment_added}
                                  </h3>

                                  <div className="eqp_added_radiopart">
                                    <Form.Check
                                      inline
                                      onChange={this.handleOnChange}
                                      label={language.filter_option_last_7_days}
                                      name="days"
                                      type="radio"
                                      id="7-days"
                                      value="7"
                                    />
                                    <Form.Check
                                      inline
                                      onChange={this.handleOnChange}
                                      label={
                                        language.filter_option_last_1_month
                                      }
                                      name="days"
                                      type="radio"
                                      id="1-month"
                                      value="30"
                                    />
                                    <Form.Check
                                      inline
                                      onChange={this.handleOnChange}
                                      label={
                                        language.filter_option_last_6_month
                                      }
                                      name="days"
                                      type="radio"
                                      id="6-months"
                                      value="180"
                                    />
                                  </div>

                                  <div className="eqp_search_for">
                                    <p>{language.filter_label_search_for}</p>
                                    <div className="rent_resale">
                                      <div
                                        className={
                                          filter.machine_profile_type === ""
                                            ? "active"
                                            : ""
                                        }
                                        onClick={() => {
                                          filter.machine_profile_type = "";
                                          filter.isApplyFilter = false;

                                          this.setState({
                                            filter,
                                          });
                                        }}
                                      >
                                        <span />
                                        {language.filter_option_all}
                                      </div>

                                      <div
                                        className={
                                          filter.machine_profile_type === "2"
                                            ? "active"
                                            : ""
                                        }
                                        onClick={() => {
                                          filter.machine_profile_type = "2";
                                          filter.isApplyFilter = false;

                                          this.setState({
                                            filter,
                                          });
                                        }}
                                      >
                                        <span />
                                        {language.filter_option_resale}
                                      </div>

                                      <div
                                        className={
                                          filter.machine_profile_type === "4"
                                            ? "active"
                                            : ""
                                        }
                                        onClick={() => {
                                          filter.machine_profile_type = "4";
                                          filter.isApplyFilter = false;

                                          this.setState({
                                            filter,
                                          });
                                        }}
                                      >
                                        <span />
                                        {language.filter_option_rent}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="select_box_rent">
                                    <Form.Label className="mt-2">
                                      {
                                        language.filter_label_equipment_brand_name
                                      }
                                    </Form.Label>

                                    <SelectWIthIcon
                                      className="form-control select_inner_box"
                                      options={allBrands}
                                      placeholder={
                                        language.filter_plh_select_brand
                                      }
                                      isMulti={true}
                                      value={filter.selected_brand}
                                      onChange={(value) => {
                                        filter.selected_brand = value;
                                        this.setState({ filter });
                                        this.handleSelect("brand", value);
                                      }}
                                    />

                                    <Form.Label className="mt-2">
                                      {
                                        language.filter_label_availability_location
                                      }
                                    </Form.Label>
                                    <Select
                                      options={allStates}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      value={filter.selected_availibility}
                                      placeholder={
                                        language.filter_select_availablity
                                      }
                                      onChange={(e) => {
                                        filter.selected_availibility = e;
                                        this.setState({ filter });
                                        this.handleSelect("availibility", e);
                                      }}
                                    />

                                    <Form.Label className="mt-2">
                                      {language.list_purchased_year}
                                    </Form.Label>

                                    <div className="hmr_value">
                                      {filter.purchase_year_start}-
                                      {filter.purchase_year_end}
                                    </div>
                                    <Range
                                      allowCross={false}
                                      defaultValue={[
                                        filter.purchase_year_start,
                                        filter.purchase_year_end,
                                      ]}
                                      min={1990}
                                      max={new Date().getFullYear()}
                                      onChange={(value) => {
                                        filter.purchase_year_start = value[0];
                                        filter.purchase_year_end = value[1];
                                        filter.isApplyFilter = false;

                                        this.setState({ filter });
                                      }}
                                      tipFormatter={20}
                                    />
                                  </div>

                                  <div className="range_rent_slider mt-3">
                                    <label>
                                      {language.filter_label_current_hmr}
                                    </label>
                                    <div className="hmr_value">
                                      {filter.hmr_start}-{filter.hmr_end}
                                    </div>
                                    <Range
                                      allowCross={false}
                                      defaultValue={[
                                        filter.hmr_start,
                                        filter.hmr_end,
                                      ]}
                                      min={0}
                                      max={8000}
                                      onChange={(value) => {
                                        filter.hmr_start = value[0];
                                        filter.hmr_end = value[1];
                                        filter.isApplyFilter = false;

                                        this.setState({ filter });
                                      }}
                                      tipFormatter={20}
                                    />

                                    <div className="submit_button_rent mt-3">
                                      <Button
                                        onClick={this.applyFilter}
                                        type="submit"
                                      >
                                        {filter.isApplyFilter
                                          ? language.filter_clear_btn
                                          : language.filter_apply_btn}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Sticky>
                        </Col>
                      </Row>
                    </StickyContainer>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LoadingOverlay>
    );
  }
}
export default connect(mapStateToProps)(AllEquipments);
